/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, useParams } from "react-router-dom";
import { Team, TeamState } from '../../../../../../store/ducks/spteam/types'

type Props = {
  className: string,
  teams: TeamState
}
interface ParamTypes {
  id: string
}

const ManageSelectTeamWidget: React.FC<Props> = ({className, teams}) => {

  let { id } = useParams();

  return (
    <>

    <div className={`card ${className}`}>  
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Selecione um time</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Gerenciador de times</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Time</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {teams.data.map((child: Team, index) => {
                return(
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        {/* <div className='symbol symbol-45px me-5'>
                          <img src={toAbsoluteUrl('/media/avatars/150-11.jpg')} alt='' />
                        </div> */}
                        <div className='d-flex justify-content-start flex-column'>
                          <Link to={'/subteam/' + id + '/' + child.id } style={{display:'flex' }} className='text-dark fw-bolder text-hover-primary fs-6'>
                            {child.parentDelegation?.name}
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                  )
                }
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    </>
  )
}

export {ManageSelectTeamWidget}