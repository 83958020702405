import React, {useEffect, useState} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {ApplicationState} from '../../../../../../store'
import {loadDelegationRequest} from '../../../../../../store/ducks/spdelegation/actions'

import {Sport} from '../../../../../../store/ducks/spsport/types'
import {
  createTeamRequest,
  loadTeamsRequest,
} from '../../../../../../store/ducks/spteam/actions'
import {Team} from '../../../../../../store/ducks/spteam/types'
import {Delegation} from '../../../../../../store/ducks/spdelegation/types'

interface ParamTypes {
  id: string
}

interface handleCloseProps {
  handleClose: () => void
  sportSelected: Sport
}

const SelectTeam = ({handleClose, sportSelected}: handleCloseProps) => {
  const {id} = useParams()

  useEffect(() => {
    dispatch(loadDelegationRequest(+id!)) //Puxa componentes com seus filhos primários
    dispatch(loadTeamsRequest(+sportSelected.id!)) //Puxa componentes com seus filhos primários
    // console.log("SPORT SELECTED", sportSelected.id)
  }, [id, sportSelected])

  const delegations = useSelector((state: ApplicationState) => state.delegations)
  const teams = useSelector((state: ApplicationState) => state.teams)

  const [validated, setValidated] = useState(false)
  const [selectedDelegations, setSelectedDelegations] = useState<Delegation[]>([]) //TODO: PEGUE A LISTA DO PARENTDELEGATION...

  useEffect(() => {
    setSelectedDelegations(teams.data.map((team) => team.parentDelegation!))
  }, [teams.data])

  console.log('TEAMS>DATA:',teams.data)
  console.log('selectedDelegations:',selectedDelegations)

  // const history = useNavigate();
  const dispatch = useDispatch()
  // const component = useSelector((state: ApplicationState) => state.component);

  //Reduzir a lista Selected team para ter só os Ids já selecionados.
  let selectedTeamsReduce: any = []
  {
    teams.data.map((team, index) => {
      selectedTeamsReduce.push(team.spDelegationId)
    })
  }
  //console.log('HEYY!!!', selectedTeamsReduce)

  //HandleChange that include or remove items from the list
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, delegation: Delegation) => {
    const {checked} = event.target
    console.log('event', event)
    if (checked) {
      setSelectedDelegations([...selectedDelegations, delegation])
    } else {
      setSelectedDelegations(selectedDelegations!.filter((item) => item!.id !== delegation.id))
    }
  }
  //console.log('OOOOWWW', selectedDelegations)


  const teamsToInsert = (list: Delegation[]) => {
    let teamsToInsert: Team[] = []

    list.map(async (item) => {
      const team: Team = {
        //classification: '',
        spSportId: +sportSelected.id!,
        spDelegationId: item!.id,
        status: '1',
      }
      // console.log("Vou inserir team", team)
      
      teamsToInsert.push(team)
    })
    return teamsToInsert
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)

    let teamsToInsertA = teamsToInsert(selectedDelegations)
    console.log("selectedDelegationsToInsert:", teamsToInsertA)
    dispatch(createTeamRequest(sportSelected.id!,teamsToInsertA))

    //console.log("Itens selecionados no começo", selectedTeamsReduce)
    handleClose()
  }

  if (delegations.loading || teams.loading) return <div>Loading</div>
  console.log("TeamsToInsert", teamsToInsert(selectedDelegations))
  //console.log('selectedDelegations', selectedDelegations)
  return (
    <>
      <h2>
        {sportSelected.name} - {sportSelected.parentDivision?.name}
      </h2>
      <Form validated={validated} onSubmit={handleSubmit}>
        Selecione as equipes que participarão dessa modalidade:
        <br />
        <br />
        <Form.Group className='mb-3' controlId='formBasicCheckbox'>
          {delegations.data.map((delegation, index) => {
            //Comparar com a lista dos selecionados para vir marcado por default:
            let selected = teams.data.map((team) => team.parentDelegation!).some((item: any) => item.id === delegation.id)
            //console.log('SELECTED', teams)
            return (
              <div key={index}>
                <Form.Check
                  name={'check[]'}
                  id={'' + delegation.id}
                  defaultChecked={selected}
                  type='checkbox'
                  label={delegation.name}
                  style={{paddingBottom: 5}}
                  onChange={(e) => handleOnChange(e, delegation)}
                />
              </div>
            )
          })}
        </Form.Group>
        <br />
        <br />
        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
    </>
  )
}
export default SelectTeam
