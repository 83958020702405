import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { ApplicationState } from '../../../../../store';
import { createDivisionRequest } from '../../../../../store/ducks/spdivision/actions';
import { Division } from '../../../../../store/ducks/spdivision/types';
// import { Modal } from 'react-bootstrap'
interface ParamTypes {
    id: string
}

interface handleCloseProps {
    handleClose: () => void;
}


const Create = ({handleClose}: handleCloseProps) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState('');

    const [validated, setValidated] = useState(false);
    const {id} = useParams();
    // const history = useNavigate();
    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);

    console.log('Component inside - create', component)
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            
            event.stopPropagation();
        }
        setValidated(true);
        
        if(name){
            // var data = new Date(); 
            const component:Division = { 
                name,
                componentId: +id!,
                // createdAt: (data.getTime()/1000).toString(),
                status: "1",
            };
            console.log('component to save:', component)
            dispatch(createDivisionRequest(component))
            handleClose();
            // history.goBack()
        }
    }
    
    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}> 
                <Form.Group controlId="fromName">
                    <Form.Label>Nome da divisão</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome
                    </Form.Control.Feedback>
                </Form.Group>
                <br/>
                
                <Button size="sm" variant="primary" type="submit" className="float-right">
                    Salvar
                </Button> 
                
                
            </Form>
            {/* Deixar o button fora do form.. */}
        </>
    );
}
export default Create;
