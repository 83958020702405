import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap-v5';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../../store';

import { Component } from '../../../../../../store/ducks/component/types'
import { updateAthleteRequest } from '../../../../../../store/ducks/spathlete/actions';
import { Athlete } from '../../../../../../store/ducks/spathlete/types';
import { updateAthleteTeamRequest } from '../../../../../../store/ducks/spathleteteam/actions';
import { AthleteTeam } from '../../../../../../store/ducks/spathleteteam/types';
import { updateDivisionRequest } from '../../../../../../store/ducks/spdivision/actions';
import { Division } from '../../../../../../store/ducks/spdivision/types';
import { updateSportRequest } from '../../../../../../store/ducks/spsport/actions';
import { Sport } from '../../../../../../store/ducks/spsport/types';

interface handleCloseProps {
    handleClose: () => void
    child: Sport
}

const Update = ({handleClose, child}:handleCloseProps) => {
    
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const component = useSelector((state: ApplicationState) => state.component);
    const [name, setName] = useState<string | undefined>("");
    const [type, setType] = useState<string | undefined>("");
    const [description, setDescription] = useState<string | undefined>("");
    const [order, setOrder] = useState<number | undefined>(0);
    
    useEffect(() => {
        setName(child.name)
    }, [])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        console.log("submit", component.data.id)
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if(name){
            
            // var data = new Date(); 
            const toUpdate: Athlete = { 
                id: child.id,
                name,
                // createdAt: (data.getTime()/1000).toString(), //updatedAt
                status: "1",
            };

            console.log("------------------ COMPONENT TO UPDATE", toUpdate)
            dispatch(updateAthleteRequest(toUpdate))
            handleClose()
        }
    }

    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}> 
                <Form.Group controlId="fromName">
                    <Form.Label>Nome do atleta</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                        name="name"
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome do produto
                    </Form.Control.Feedback>
                </Form.Group>

                <br/>

                <Button variant="primary" type="submit" className="float-right" >
                    Salvar
                </Button> 
            </Form>
        </>
    );
}
export default Update;
