import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { ApplicationState } from '../../../../../store';
import { Athlete } from '../../../../../store/ducks/spathlete/types';
import { loadGroupsRequest } from '../../../../../store/ducks/spgroup/actions';
import { createMatchRequest, loadMatchesFinalsBySportRequest } from '../../../../../store/ducks/spmatch/actions';
import { loadMatchesFinalsBySport } from '../../../../../store/ducks/spmatch/sagas';
import { Match } from '../../../../../store/ducks/spmatch/types';
import { loadTeamsRequest } from '../../../../../store/ducks/spteam/actions';
import _ from 'lodash';

// import { Modal } from 'react-bootstrap'
interface ParamTypes {
    id: string
}

interface handleCloseProps {
    handleClose: () => void;
    selectedOption: string
}


const CreateIndividual = ({handleClose, selectedOption}: handleCloseProps) => {
    // const [name, setName] = useState('');
    // const [description, setDescription] = useState('');
    // const [order, setOrder] = useState('');

    const [inputListIndividual, setInputListIndividual] = useState({
        id: "",
        datetime: "",
        description: "",
        location: "",
        gamenumber: "",
        detail: "",
        name: "",
        childrenResults: [{ spAthleteTeamId: null }],
    })


    const [validated, setValidated] = useState(false);
    const {id} = useParams();
    // const history = useNavigate();
    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);
    const teams = useSelector((state: ApplicationState) => state.teams);
    // const groups = useSelector((state: ApplicationState) => state.groups);
    const matches = useSelector((state: ApplicationState) => state.matches);

    useEffect(() => {
      dispatch(loadTeamsRequest(+selectedOption)); //Puxa componentes com seus filhos primários
    //   dispatch(loadGroupsRequest(+selectedOption))
      dispatch(loadMatchesFinalsBySportRequest(+selectedOption))
    }, [id, selectedOption]);

    

    const handleInputChangeConst = (e: any, index?: any) => {
        const {name, value} = e.target;
        const list: any = {...inputListIndividual}
        if(index === undefined)
            list[name] = value;
        else {
            //Transforma value em number pra checar se é Equipe ou se é Referência (ex: 1º Grupo A)
            var toSplit = value.split("|");
            console.log("toSplit", toSplit)
            // console.log("toSplit", toSplit[0])
            // console.log("toSplit", toSplit[1])

            let num1:number = Number(toSplit[0]);
            let num2:number = Number(toSplit[1]);
            list['childrenResults'][index]['spTeamId'] = num1;
            list['childrenResults'][index]['spAthleteTeamId'] = num2;  
        }
        setInputListIndividual(list);
    }

    

    const handleAddIndividualClick = () => {
        setInputListIndividual(
            {
                ...inputListIndividual, 
                childrenResults: [...inputListIndividual.childrenResults, { spAthleteTeamId: null }],
            }
        );
    };

      
    // handle click event of the Remove button
    const handleRemoveIndividualClick = (index:any) => {
        console.log("INDEX to remove", index)
        const list = {...inputListIndividual};
        const teste = {...inputListIndividual}
        console.log("Lista atual", teste.childrenResults)

        list.childrenResults.splice(index, 1);
        console.log("Lista depois do splice", list.childrenResults)
        setInputListIndividual(list);
    };
    

    const names = (athletes: Athlete[]) => {
        const commasep = athletes.map(item => item.name).join(', ')
        return commasep
    }
    


    console.log('Component inside - create', component)
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            
            event.stopPropagation();
        }
        setValidated(true);

        
            
        // var data = new Date(); 
        var matchDate = new Date(inputListIndividual.datetime)

        let matchesToSave: any[] = [];
        let match: any = {
            // id: +item.id,
            datetime: inputListIndividual.datetime,
            description: inputListIndividual.description,
            location: inputListIndividual.location,
            gamenumber: inputListIndividual.gamenumber, 
            name: inputListIndividual.name,
            // wo: "",
            // manualorder: "",
            detail: inputListIndividual.detail,
            
            spSportId: +selectedOption,
            parentSport: +selectedOption,

            componentId: +id!,
            parentComponent: +id!,

            status: "1",
            // createdAt: (data.getTime()/1000).toString(),

            childrenResults: inputListIndividual.childrenResults
        }

        matchesToSave.push(match)
        console.log("CRIANDO PARTIDA...", match)
        dispatch(createMatchRequest(matchesToSave))
        
        handleClose();
        
    }
    console.log("dataMatchesFinalsBySport", matches.dataMatchesFinalsBySport)
    if (teams.loading || matches.loading)
        return <div>Loading</div>
        
    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}> 
                <div className='row'>
                    <div className='col-3'>
                        <Form.Group>
                            <Form.Label>Datetime</Form.Label>
                            <Form.Control 
                                placeholder='DateTime'
                                name="datetime"
                                value={inputListIndividual.datetime}
                                onChange={e => handleInputChangeConst(e)}
                            />
                        </Form.Group>
                    </div>
                    
                    <div className='col-2'>
                        {/* <Form.Group>
                            <Form.Label>Detail</Form.Label>
                            <Form.Control 
                                placeholder='detail'
                                name="detail" 
                                value={x.detail}
                                onChange={e => handleInputChange(e, i)}
                            />
                        </Form.Group> */}
                        <Form.Label>Detail</Form.Label>
                        <select className="selectpicker form-control" name="detail" onChange={(e)=>handleInputChangeConst(e)}>
                            <option value="" selected disabled hidden>--Selecione--</option>
                            <option value="Individual" selected={"Individual" === inputListIndividual.detail}>Individual</option>
                            
                        </select>
                    </div>
                    <div className='col-3'>
                        <Form.Group>
                            <Form.Label>Location</Form.Label>
                            <Form.Control 
                                placeholder='Location'
                                name="location" 
                                value={inputListIndividual.location}
                                onChange={e => handleInputChangeConst(e)}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-2'>
                        <Form.Group>
                            <Form.Label>Gamenumber</Form.Label>
                            <Form.Control 
                                placeholder='Gamenumber'
                                name="gamenumber" 
                                value={inputListIndividual.gamenumber}
                                onChange={e => handleInputChangeConst(e)}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-2'>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                placeholder='Name'
                                name="name" 
                                value={inputListIndividual.name}
                                onChange={e => handleInputChangeConst(e)}
                            />
                        </Form.Group>
                    </div>
                    <div className='col-2'>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control 
                                placeholder='Description'
                                name="description" 
                                value={inputListIndividual.description}
                                onChange={e => handleInputChangeConst(e)}
                            />
                        </Form.Group>
                    </div>
                </div>
                <br/>
                <hr/>
                <br/>
                {
                    inputListIndividual.childrenResults.map((x, i) => {
                        return(
                            <div className='row'>
                                <div className='col-10'>
                                    {/* <Form.Label>Time</Form.Label> */}
                                    <select className="selectpicker form-control" name="times" onChange={(e)=>handleInputChangeConst(e, i)}>
                                        <option value="" disabled hidden selected={null === x.spAthleteTeamId}>--Selecione--</option>
                                        
                                        {teams.data.map((team, index) => {
                                            return (
                                                team.childrenAthleteTeams?.map((athleteteam, indexB) => {
                                                    return <option key={indexB} value={team.id+"|"+athleteteam.id} selected={+athleteteam.id! === +x.spAthleteTeamId!}> {team.parentDelegation?.name} - {athleteteam.name} - ({names(athleteteam.childrenAthletes!)}) </option>    
                                                }) 
                                            )
                                        })}
                                    </select>
                                    
                                    <br/>
                                    {inputListIndividual.childrenResults.length - 1 === i && <Button size="sm" variant="primary" className="float-right" onClick={handleAddIndividualClick}>
                                        Adicionar outro time
                                    </Button>}
                                    <br/>
                                </div>
                                <div className='col-2'>
                                    {inputListIndividual.childrenResults.length !== 1 && <Button size="sm" variant="primary" className="float-right" onClick={() => handleRemoveIndividualClick(i)}>
                                        Remover
                                    </Button>}
                                </div>
                            </div>

                            
                            
                        )
                        
                    })
                }

                {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div>
                <br/><br/> */}
                {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputListIndividual)}</div> */}
                <br/>
                <hr/>
                <br/>
                <Button size="sm" variant="primary" type="submit" className="float-right">
                    Salvar
                </Button> 
                
                
            </Form>
            {/* Deixar o button fora do form.. */}
        </>
    );
}
export default CreateIndividual;
