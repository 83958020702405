import { Reducer } from 'redux';
import { TeamState, TeamsTypes } from './types';
const INITIAL_STATE: TeamState = {
    data: [],
    dataByDivision: [],
    error: false,
    loading: false,
}

const reducer: Reducer<TeamState, any> = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //Load single
        case TeamsTypes.LOAD_TEAMS_REQUEST:
            return { ...state, loading: true }
        case TeamsTypes.LOAD_TEAMS_SUCCESS:
            return { ...state, loading: false, error: false, data: action.payload.data } 
        case TeamsTypes.LOAD_TEAMS_FAILURE:
            return { ...state, loading: false, error:true, data: [] }

        //Load BY Division
        case TeamsTypes.LOAD_TEAMSBYDIVISION_REQUEST:
            return { ...state, loading: true }
        case TeamsTypes.LOAD_TEAMSBYDIVISION_SUCCESS:
            return { ...state, loading: false, error: false, dataByDivision: action.payload.data } 
        case TeamsTypes.LOAD_TEAMSBYDIVISION_FAILURE:
            return { ...state, loading: false, error:true, dataByDivision: [] }
        
        //Create
        case TeamsTypes.CREATE_TEAM_REQUEST:
            return { ...state }
        case TeamsTypes.CREATE_TEAM_SUCCESS:
            console.log("VEJA AQUI", action.payload.data)
            return { ...state, loading: false, error: false, data: action.payload.data }
        case TeamsTypes.CREATE_TEAM_FAILURE:
            return { ...state, loading: false, error:true, data: [] }

        //Update 
        case TeamsTypes.UPDATE_TEAM_REQUEST:
            return { ...state }
        case TeamsTypes.UPDATE_TEAM_SUCCESS:
            console.log("ACTION PAYLOAD VER", action.payload)
            return { 
                ...state,
                loading: false, 
                error: false, 
                data: state.data?.map(child=> child.id === action.payload.data.id ? action.payload.data : child ) 
            } //update data?
        case TeamsTypes.UPDATE_TEAM_FAILURE:
            return { ...state, loading: false, error:true }
        
        //Delete
        // case TeamsTypes.DELETE_TEAM_REQUEST:
        //     return { ...state, loading: true }
        // case TeamsTypes.DELETE_TEAM_SUCCESS:
        //     return { ...state, loading: false, error: false } 
        // case TeamsTypes.DELETE_TEAM_FAILURE:
        //     return { ...state, loading: false, error: true }
            
        default:
            return state;

    }
}

export default reducer;