import { action } from 'typesafe-actions';
import { ClassificationTypes, Classification } from './types'

//Load
export const loadAllClassificationRequest = () => action(ClassificationTypes.LOAD_ALLCLASSIFICATION_REQUEST) 
export const loadAllClassificationSuccess = (data: Classification[]) => action(ClassificationTypes.LOAD_ALLCLASSIFICATION_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadAllClassificationFailure = () => action(ClassificationTypes.LOAD_ALLCLASSIFICATION_FAILURE) 

//Load single
export const loadClassificationRequest = (id: number, spDivisionId: number) => action(ClassificationTypes.LOAD_CLASSIFICATION_REQUEST, {id, spDivisionId}) 
export const loadClassificationSuccess = (data: Classification[]) => action(ClassificationTypes.LOAD_CLASSIFICATION_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadClassificationFailure = () => action(ClassificationTypes.LOAD_CLASSIFICATION_FAILURE) 

//Create
export const createClassificationRequest = (data: Classification) => action(ClassificationTypes.CREATE_CLASSIFICATION_REQUEST, data)
export const createClassificationSuccess = (data: Classification) => action(ClassificationTypes.CREATE_CLASSIFICATION_SUCCESS, data)
export const createClassificationFailure = (error: {}) => action(ClassificationTypes.CREATE_CLASSIFICATION_FAILURE, error)

//Update
export const updateClassificationRequest = (classificationToUpdate: Classification) => action(ClassificationTypes.UPDATE_CLASSIFICATION_REQUEST, classificationToUpdate) 
export const updateClassificationSuccess = (data: Classification) => action(ClassificationTypes.UPDATE_CLASSIFICATION_SUCCESS, data)
export const updateClassificationFailure = () => action(ClassificationTypes.UPDATE_CLASSIFICATION_FAILURE) 

//Delete
export const deleteClassificationRequest = () => action(ClassificationTypes.DELETE_CLASSIFICATION_REQUEST) 
export const deleteClassificationSuccess = (data: Classification) => action(ClassificationTypes.DELETE_CLASSIFICATION_SUCCESS, data)
export const deleteClassificationFailure = () => action(ClassificationTypes.DELETE_CLASSIFICATION_FAILURE) 
