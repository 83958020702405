import { action } from 'typesafe-actions';
import { MatchesTypes, Match, Result } from './types'


//Load single
export const loadMatchesRequest = (id: number) => action(MatchesTypes.LOAD_MATCHES_REQUEST, id) 
export const loadMatchesSuccess = (data: Match[]) => action(MatchesTypes.LOAD_MATCHES_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadMatchesFailure = () => action(MatchesTypes.LOAD_MATCHES_FAILURE) 

export const loadMatchesByGroupRequest = (id: number) => action(MatchesTypes.LOAD_MATCHESBYGROUP_REQUEST, id) 
export const loadMatchesByGroupSuccess = (data: Match[]) => action(MatchesTypes.LOAD_MATCHESBYGROUP_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadMatchesByGroupFailure = () => action(MatchesTypes.LOAD_MATCHESBYGROUP_FAILURE) 

export const loadMatchesFinalsBySportRequest = (id: number) => action(MatchesTypes.LOAD_MATCHESFINALSBYSPORT_REQUEST, id) 
export const loadMatchesFinalsBySportSuccess = (data: Match[]) => action(MatchesTypes.LOAD_MATCHESFINALSBYSPORT_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadMatchesFinalsBySportFailure = () => action(MatchesTypes.LOAD_MATCHESFINALSBYSPORT_FAILURE) 

//Create
export const createMatchRequest = (data: Match[]) => action(MatchesTypes.CREATE_MATCH_REQUEST, data)
export const createMatchsuccess = (data: Match) => action(MatchesTypes.CREATE_MATCH_SUCCESS, data)
export const createMatchFailure = (error: {}) => action(MatchesTypes.CREATE_MATCH_FAILURE, error)

//Update
export const updateMatchRequest = (toUpdate: Match) => action(MatchesTypes.UPDATE_MATCH_REQUEST, toUpdate) 
export const updateMatchSuccess = (data: Match) => action(MatchesTypes.UPDATE_MATCH_SUCCESS, data)
export const updateMatchFailure = () => action(MatchesTypes.UPDATE_MATCH_FAILURE) 

//Update
export const updateMatchResultRequest = (toUpdate: Match) => action(MatchesTypes.UPDATE_MATCHRESULT_REQUEST, toUpdate) 
export const updateMatchResultSuccess = (data: Match) => action(MatchesTypes.UPDATE_MATCHRESULT_SUCCESS, data)
export const updateMatchResultFailure = () => action(MatchesTypes.UPDATE_MATCHRESULT_FAILURE) 

//Delete
export const deleteMatchRequest = (id: number) => action(MatchesTypes.DELETE_MATCH_REQUEST, id) 
export const deleteMatchSuccess = (data: Match) => action(MatchesTypes.DELETE_MATCH_SUCCESS, data)
export const deleteMatchFailure = () => action(MatchesTypes.DELETE_MATCH_FAILURE) 
