import { action } from 'typesafe-actions';
import { AthleteTypes, Athlete } from './types'

//Load single
export const loadAthleteRequest = (id: number) => action(AthleteTypes.LOAD_ATHLETE_REQUEST, id) 
export const loadAthleteSuccess = (data: Athlete[]) => action(AthleteTypes.LOAD_ATHLETE_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadAthleteFailure = () => action(AthleteTypes.LOAD_ATHLETE_FAILURE) 

//Create
export const createAthleteRequest = (data: Athlete) => action(AthleteTypes.CREATE_ATHLETE_REQUEST, data)
export const createAthleteSuccess = (data: Athlete) => action(AthleteTypes.CREATE_ATHLETE_SUCCESS, data)
export const createAthleteFailure = (error: {}) => action(AthleteTypes.CREATE_ATHLETE_FAILURE, error)

//Update
export const updateAthleteRequest = (teamToUpdate: Athlete) => action(AthleteTypes.UPDATE_ATHLETE_REQUEST, teamToUpdate) 
export const updateAthleteSuccess = (data: Athlete) => action(AthleteTypes.UPDATE_ATHLETE_SUCCESS, data)
export const updateAthleteFailure = () => action(AthleteTypes.UPDATE_ATHLETE_FAILURE) 

//Delete
export const deleteAthleteRequest = (id: number) => action(AthleteTypes.DELETE_ATHLETE_REQUEST, id) 
export const deleteAthleteSuccess = (data: Athlete) => action(AthleteTypes.DELETE_ATHLETE_SUCCESS, data)
export const deleteAthleteFailure = () => action(AthleteTypes.DELETE_ATHLETE_FAILURE) 
