import {SportConfig} from '../../../../../../../store/ducks/spsportconfig/types'

const verifyConditionalScore = (config: SportConfig[]) => {
  //Verifica se tem placar condicional (ex: Volei)
  let placarVitoriaCondicional = config.filter(
    (child: any) => child.prop! === 'PlacarVitoriaCondicional'
  )
  let placarDerrotaCondicional = config.filter(
    (child: any) => child.prop! === 'PlacarDerrotaCondicional'
  )
  let placarVCond: any
  let placarDCond: any

  let pontuacaoVitoriaCondicional = config.filter(
    (child: any) => child.prop! === 'PontuacaoVitoriaCondicional'
  )
  let pontuacaoDerrotaCondicional = config.filter(
    (child: any) => child.prop! === 'PontuacaoDerrotaCondicional'
  )
  let pontuacaoVCond: any = 0
  let pontuacaoDCond: any = 0

  if (
    placarVitoriaCondicional.length &&
    placarDerrotaCondicional.length &&
    pontuacaoVitoriaCondicional.length &&
    pontuacaoDerrotaCondicional.length
  ) {
    placarVCond = +placarVitoriaCondicional[0].value!
    placarDCond = +placarDerrotaCondicional[0].value!
    pontuacaoVCond = +pontuacaoVitoriaCondicional[0].value!
    pontuacaoDCond = +pontuacaoDerrotaCondicional[0].value!
  }

  let pontuacaoV: number = 0
  let pontuacaoD: number = 0
  let pontuacaoE: number = 0

  let pontuacaoVitoria = config.filter((child: any) => child.prop! === 'PontuacaoVitoria')
  let pontuacaoDerrota = config.filter((child: any) => child.prop! === 'PontuacaoDerrota')
  let pontuacaoEmpate = config.filter((child: any) => child.prop! === 'PontuacaoEmpate')

  if (pontuacaoVitoria.length) {
    pontuacaoV = +pontuacaoVitoria[0].value!
  }
  if (pontuacaoDerrota.length) {
    pontuacaoD = +pontuacaoDerrota[0].value!
  }
  if (pontuacaoEmpate.length) {
    pontuacaoE = +pontuacaoEmpate[0].value!
  }

  //console.log('job [verifyConditionalScore] Done')
  return {
    placarVCond,
    placarDCond,
    pontuacaoVCond,
    pontuacaoDCond,
    pontuacaoV,
    pontuacaoD,
    pontuacaoE,
  }
}

export default verifyConditionalScore
