import { Component } from "../component/types"

/** 
 * Action types
 */
export enum  DelegationsTypes {
    //Load all
    LOAD_ALLDELEGATIONS_REQUEST = '@delegation/LOAD_ALLDELEGATIONS_REQUEST',
    LOAD_ALLDELEGATIONS_SUCCESS = '@delegation/LOAD_ALLDELEGATIONS_SUCCESS',
    LOAD_ALLDELEGATIONS_FAILURE = '@delegation/LOAD_ALLDELEGATIONS_FAILURE',

    //Load single
    LOAD_DELEGATION_REQUEST = '@delegation/LOAD_DELEGATION_REQUEST',
    LOAD_DELEGATION_SUCCESS = '@delegation/LOAD_DELEGATION_SUCCESS',
    LOAD_DELEGATION_FAILURE = '@delegation/LOAD_DELEGATION_FAILURE',

    //Create
    CREATE_DELEGATION_REQUEST = '@delegation/CREATE_DELEGATION_REQUEST',
    CREATE_DELEGATION_SUCCESS = '@delegation/CREATE_DELEGATION_SUCCESS',
    CREATE_DELEGATION_FAILURE = '@delegation/CREATE_DELEGATION_FAILURE',

    //Update
    UPDATE_DELEGATION_REQUEST = '@delegation/UPDATE_DELEGATION_REQUEST',
    UPDATE_DELEGATION_SUCCESS = '@delegation/UPDATE_DELEGATION_SUCCESS',
    UPDATE_DELEGATION_FAILURE = '@delegation/UPDATE_DELEGATION_FAILURE',

    //Delete
    DELETE_DELEGATION_REQUEST = '@delegation/DELETE_DELEGATION_REQUEST',
    DELETE_DELEGATION_SUCCESS = '@delegation/DELETE_DELEGATION_SUCCESS',
    DELETE_DELEGATION_FAILURE = '@delegation/DELETE_DELEGATION_FAILURE',

    //Delete
    UPLOAD_IMAGE_REQUEST = '@delegation/UPLOAD_IMAGE_REQUEST',
    UPLOAD_IMAGE_SUCCESS = '@delegation/UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_FAILURE = '@delegation/UPLOAD_IMAGE_FAILURE',
}

/**
 * Data types
 */
export interface Delegation {
    id?: number

    parentComponent?: Component | undefined,
    componentId?: number,

    name?: string,
    image?: string,
    description?: string,

    createdAt?: string,
    status?: string | boolean,
}

/**
 * State type
 */
export interface DelegationState {
    readonly all: Delegation[]
    readonly data: Delegation[]
    readonly loading: boolean
    readonly error: boolean
}