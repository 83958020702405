import React, {FC, useEffect, useState} from "react";

import {useIntl} from 'react-intl'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { loadComponentRequest } from '../../../../../store/ducks/component/actions';
import { ComponentState } from '../../../../../store/ducks/component/types'
import Loading from '../../../../loading'

import MenuChampionshipWidget from "../MenuChampionshipWidget";
import { loadDivisionsRequest } from "../../../../../store/ducks/spdivision/actions";
import { ManageMatchWidget } from "./ManageMatchWidget";
import { DivisionState } from "../../../../../store/ducks/spdivision/types";
import { loadMatchesRequest } from "../../../../../store/ducks/spmatch/actions";
import { MatchState } from "../../../../../store/ducks/spmatch/types";
import { loadSportsByChampionshipRequest, loadSportsRequest } from "../../../../../store/ducks/spsport/actions";
import { SportState } from "../../../../../store/ducks/spsport/types";
import { PageTitle } from "../../../../design/layout/core";

interface ParamTypes {
  id: string
}

const MOMENT= require( 'moment' );

type Props = {
  matches: MatchState
  id: string
  sports: SportState
  selectedOption: string
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
}

const ManagePage: React.FC<Props> = ({matches, id, sports, setSelectedOption, selectedOption}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      
      <div className='col-xxl-2'>
        <MenuChampionshipWidget id={id!} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      <div className='col-xxl-10'>
        <ManageMatchWidget 
          matches={matches} 
          sports={sports} 
          setSelectedOption={setSelectedOption} 
          selectedOption={selectedOption} 
          className='card-xxl-stretch mb-5 mb-xxl-8' 
        />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const Match: FC = () => {
    const intl = useIntl()
    const [selectedOption, setSelectedOption] = useState('');

    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);
    const matches = useSelector((state: ApplicationState) => state.matches);
    const sports = useSelector((state: ApplicationState) => state.sports);

    // console.log('matches', matches)
    let { id } = useParams();

    useEffect(() => {
        dispatch(loadComponentRequest(id!, 'asc')); //Puxa componentes com seus filhos primários
        dispatch(loadSportsByChampionshipRequest(+id!)); //Puxa componentes com seus filhos primários
        // if(selectedOption)
        dispatch(loadMatchesRequest(+selectedOption)); //Puxa componentes com seus filhos primários
        
    }, [id, selectedOption]);

    if(component.loading && component.data)
        return <Loading/>

    if(sports.loading)
        return <Loading/>

    return ( 
      <>
        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
        <PageTitle breadcrumbs={[]}>{component.data.name}</PageTitle>
        <ManagePage id={id!} matches={matches} sports={sports} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />
      </> 
    )
}   
export default Match

