import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    
    //Load single
    loadPartialRequest,
    loadPartialSuccess,
    loadPartialFailure,

    //Create
    createPartialRequest,
    createPartialSuccess,
    createPartialFailure,

    //Update
    updatePartialRequest,
    updatePartialSuccess,
    updatePartialFailure,

    //Delete
    deletePartialRequest,
    deletePartialSuccess,
    deletePartialFailure

} from './actions';
import { Partial } from './types';

//Load single
export function* loadPartial(payload:ReturnType<typeof loadPartialRequest>) {
    console.log("LOEAD TEAMS")
    try {
        const response : Partial[] = yield call(api.get, 'partial/'+payload.payload);
        yield put(loadPartialSuccess(response));
    } catch (error) {
        yield put(loadPartialFailure());
    }
}

//Create
export function* createPartial(payload:ReturnType<typeof createPartialRequest>) {
    try {
        put(createPartialRequest(payload.payload))
        const response : Partial = yield call(api.post, 'partial', payload.payload);
        yield put(createPartialSuccess(response));
    } catch (error:any) {
        yield put(createPartialFailure(error.response.message));
    }
}

//Update
export function* updatePartial(payload:ReturnType<typeof updatePartialRequest>) {
    try {
        put(updatePartialRequest(payload.payload))
        const response : Partial = yield call(api.post, 'partial', payload.payload);
        yield put(updatePartialSuccess(response));
    } catch (error) {
        yield put(updatePartialFailure());
    }
}

//Delete
export function* deletePartial(payload:ReturnType<typeof deletePartialRequest>) {
    try {
        const response : Partial = yield call(api.delete, 'partial/'+payload.payload);
        yield put(deletePartialSuccess(response));
    } catch (error) {
        yield put(deletePartialFailure());
    }
}