/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Link, useParams } from 'react-router-dom'
import { Component, ComponentState } from '../../../../../store/ducks/component/types'
import { Extras } from '../../../../../store/ducks/extras/types'

import { useDispatch } from 'react-redux';
import { deleteComponentRequest, updateComponentRequest } from '../../../../../store/ducks/component/actions'
import { useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap-v5'

import Create from '../create'
import Update from '../update'
import { DivisionState } from '../../../../../store/ducks/spdivision/types'
import { Classification, ClassificationState } from '../../../../../store/ducks/spclassification/types'
import { Delegation, DelegationState } from '../../../../../store/ducks/spdelegation/types'
import { createClassificationRequest, updateClassificationRequest } from '../../../../../store/ducks/spclassification/actions'

type Props = {
  className: string,
  classification: ClassificationState
  divisions: DivisionState
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
  selectedOption: string
  delegations: DelegationState
}

interface ParamTypes {
  id: string
}

const ManageMedalWidget: React.FC<Props> = ({className, classification, divisions, setSelectedOption, selectedOption, delegations}) => {
  
  console.log("Classification", classification)
  let { id } = useParams();

  const [show, setShow] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");
  const [child, setChild] = useState<Component>({});
  const [validated, setValidated] = useState(false);

  const [inputList, setInputList] = useState<Classification[]>([]);
  // handle input change
  const handleInputChange = (e:any, index:number) => {
    const { name, value } = e.target;
    const list:any = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  
  useEffect(() => {

    setInputList(classification.data)
    console.log("INPUT-LIST", inputList)

  }, [classification.data, selectedOption]);


  const dispatch = useDispatch();
  const history = useNavigate();

  const handleClose = () => {
    setShow(false);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);

        console.log("classification", classification)
        console.log("Salvar inputList", inputList)

       
        // //1 - Remover
        // dispatch(deleteSportConfigRequest(sportSelected.id!)) 

        // //2 - Incluir os novos
        // //Trabalhando com novos items:
        
        // setTimeout(() => {
        inputList.map((item:any, index: any) => {
            // console.log("NEW DATA",item)
            // var data = new Date(); 
            let oldId = item.children[0] ? item.children[0].id! : '';

            if(oldId) {
              let classificationToUpdate: Classification = {
                id: oldId,
                spDelegationId: +item.id,
                componentId: +id!,
                spDivisionId: +selectedOption,
                gold: +item.newGold || 0,
                silver: +item.newSilver || 0,
                bronze: +item.newBronze || 0,
                total: (+item.newGold) + (+item.newSilver) + (+item.newBronze),
                status: "1",
                // createdAt: (data.getTime()/1000).toString(),
              }
              console.log("ToUpdate", classificationToUpdate)
              dispatch(updateClassificationRequest(classificationToUpdate))
            }else{
              let classificationToUpdate: Classification = {
                spDelegationId: +item.id,
                componentId: +id!,
                spDivisionId: +selectedOption,
                gold: +item.newGold || 0,
                silver: +item.newSilver || 0,
                bronze: +item.newBronze || 0,
                total: (+item.newGold) + (+item.newSilver) + (+item.newBronze),
                status: "1",
                // createdAt: (data.getTime()/1000).toString(),
              }
              console.log("ToUpdate", classificationToUpdate)
              dispatch(createClassificationRequest(classificationToUpdate))
            }
            
            // 
          })
        // }, 300);

        
    }


  return (
    <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton placeholder={'Hey'}>
      <Modal.Title>
          { (action === 'createComponent')?'Adicionar componente':'' }
          { (action === 'updateComponent')?'Editar componente':'' }
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          { (action === 'createComponent')?<Create handleClose={handleClose} />:'' }
          { (action === 'updateComponent')?<Update handleClose={handleClose} child={child} />:'' }
      </Modal.Body>
    </Modal>
   
      
    <div className={`card ${className}`}>  
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Medalhas</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Gerenciador de medalhas</span>
        </h3>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <select className="selectpicker form-control" onChange={(e)=>setSelectedOption(e.target.value)}>
          <option value="" selected disabled hidden>Selecione uma divisão</option>
          {divisions.data.map((division, index) => {
            return <option key={index} selected={(division.id === +selectedOption)?true:false} value={division.id}>{division.name}</option>
          })}
        </select>

        {/* {classification.data.map((class, index)=>{
          return <div key={index}>{class.name} - {class.pontuation}</div>
        })} */}

        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <Form validated={validated} onSubmit={handleSubmit}> 
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th> */}
                <th className='min-w-150px'>Equipe</th>
                <th className='min-w-140px'>Ouro</th>
                <th className='min-w-140px'>Prata</th>
                <th className='min-w-140px'>Bronze</th>
                {/* <th className='min-w-140px'>Ordem</th> */}
                {/* <th className='min-w-120px'>Progress</th> */}
                {/* <th className='min-w-100px text-end'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            
            <tbody>
            
              {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}

                {inputList.map((child: any, index: any) => {
                  // let classificationDelegation = classification.data?.filter(classification => classification.parentDelegation?.id === child.id)
                  // let pontuation = classificationDelegation[0]? classificationDelegation[0].pontuation : 0;
                  let children = child.children;
                  let pontuation = 0
                  let gold = 0
                  let silver = 0
                  let bronze = 0
                  

                  if(children){
                    if(children[0]){
                      pontuation = children[0].pontuation
                      gold=children[0].gold
                      silver=children[0].silver
                      bronze=children[0].bronze
                    }
                  }
                   
                  console.log("Pontuation", pontuation)
                  // let pontuation =  ?[0].pontuation || '';
                  return(
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          {/* <div className='symbol symbol-45px me-5'>
                            <img src={toAbsoluteUrl('/media/avatars/150-11.jpg')} alt='' />
                          </div> */}
                          <div className='d-flex justify-content-start flex-column'>
                            <div className='text-dark fw-bolder fs-6'>
                              {child?.name}
                            </div>
                            {/* <span className='text-muted fw-bold text-muted d-block fs-7'>
                              {child.description}
                            </span> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          <Form.Group>
                              <Form.Control 
                                  placeholder='Ouro'
                                  name="newGold" 
                                  defaultValue={gold}
                                  onChange={e => handleInputChange(e, index)}
                              />
                          </Form.Group>
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          <Form.Group>
                              <Form.Control 
                                  placeholder='Prata'
                                  name="newSilver" 
                                  defaultValue={silver}
                                  onChange={e => handleInputChange(e, index)}
                              />
                          </Form.Group>
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          <Form.Group>
                              <Form.Control 
                                  placeholder='Bronze'
                                  name="newBronze" 
                                  defaultValue={bronze}
                                  onChange={e => handleInputChange(e, index)}
                              />
                          </Form.Group>
                        </span>
                      </td>
                      <td>
                        {/* <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                          Intertico
                        </a> */}
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {/* {child.order} */}
                        </span>
                      </td>
                    </tr>
                    )
                  }
                )}
                <Button size="sm" variant="primary" type="submit" className="float-right">
                    Salvar
                </Button> 
              
            </tbody>
           
            {/* end::Table body */}
          </table>
          </Form>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    </>
  )
}

export {ManageMedalWidget}