import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { ApplicationState } from '../../../../../store';
import { createDelegationRequest, uploadImageRequest } from '../../../../../store/ducks/spdelegation/actions';
import { Delegation } from '../../../../../store/ducks/spdelegation/types';
import { createDivisionRequest } from '../../../../../store/ducks/spdivision/actions';
import { Division } from '../../../../../store/ducks/spdivision/types';
// import { Modal } from 'react-bootstrap'
interface ParamTypes {
    id: string
}

interface handleCloseProps {
    handleClose: () => void;
}


const Create = ({handleClose}: handleCloseProps) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState('');
    const [selectedFile, setSelectedFile] = useState<any>();
    const [isSelected, setIsSelected] = useState(false);

    const [validated, setValidated] = useState(false);
    const {id} = useParams();
    // const history = useNavigate();
    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);

    console.log('Component inside - create', component)
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            
            event.stopPropagation();
        }
        setValidated(true);

        

        
        if(name){
            // const formData = new FormData();
            // let nameFile = Date.now() + '' + selectedFile.name
            // formData.append("file", selectedFile, nameFile);
            // console.log("FORMDATA", formData)
            // dispatch(uploadImageRequest(formData))

            //// var data = new Date(); 
            const component:Delegation = { 
                name,
                componentId: +id!,
                //image: nameFile,
                description,
                status: '1',
            };
            console.log('component to save:', component)
            dispatch(createDelegationRequest(component))
            handleClose();
        }
    }

    const changeHandler = (event:any) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
    };

    // const uploadFile = function (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    //     if (isSelected) {
    //         const formData = new FormData();
    //         formData.append("image", selectedFile, selectedFile.name);
    //     }
    // };
    
    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}> 
                <Form.Group controlId="fromName">
                    <Form.Label>Nome da equipe</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome
                    </Form.Control.Feedback>
                </Form.Group>
                <br/>

                <Form.Group controlId="fromName">
                    <Form.Label>Descr</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={description} 
                        onChange={e => setDescription(e.target.value)} 
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe a descrição
                    </Form.Control.Feedback>
                </Form.Group>
                <br/>
                {/* <Form.Group>
                    <Form.Control 
                        required
                        name="file"
                        id="exampleFormControlFile1"
                        type="file"
                        // label="Selecione um arquivo"
                        onChange={changeHandler}
                    />
                </Form.Group>
                <br/> */}
                {isSelected ? (
                    <div>
                        <p>Filename: {selectedFile.name}</p>
                        <p>Filetype: {selectedFile.type}</p>
                        <p>Size in bytes: {selectedFile.size}</p>
                        <p>
                            lastModifiedDate:{' '}
                            {selectedFile.lastModifiedDate.toLocaleDateString()}
                        </p>
                    </div>
                    ) : (
                        <p>Select a file to show details</p>
                    )
                }
                
                <Button size="sm" variant="primary" type="submit" className="float-right">
                    Salvar
                </Button> 
                
                
            </Form>
            {/* Deixar o button fora do form.. */}
        </>
    );
}
export default Create;
