import { Division } from "../spdivision/types"
import { SportConfig } from "../spsportconfig/types"

/** 
 * Action types
 */
export enum  SportsTypes {
    //Load all
    LOAD_ALLSPORTS_REQUEST = '@sport/LOAD_ALLSPORTS_REQUEST',
    LOAD_ALLSPORTS_SUCCESS = '@sport/LOAD_ALLSPORTS_SUCCESS',
    LOAD_ALLSPORTS_FAILURE = '@sport/LOAD_ALLSPORTS_FAILURE',

    //Load single
    LOAD_SPORT_REQUEST = '@sport/LOAD_SPORT_REQUEST',
    LOAD_SPORT_SUCCESS = '@sport/LOAD_SPORT_SUCCESS',
    LOAD_SPORT_FAILURE = '@sport/LOAD_SPORT_FAILURE',

    //Load single
    LOAD_SPORTS_BY_CHAMPIONSHIP_REQUEST = '@sport/LOAD_SPORTS_BY_CHAMPIONSHIP_REQUEST',
    LOAD_SPORTS_BY_CHAMPIONSHIP_SUCCESS = '@sport/LOAD_SPORTS_BY_CHAMPIONSHIP_SUCCESS',
    LOAD_SPORTS_BY_CHAMPIONSHIP_FAILURE = '@sport/LOAD_SPORTS_BY_CHAMPIONSHIP_FAILURE',

    //Create
    CREATE_SPORT_REQUEST = '@sport/CREATE_SPORT_REQUEST',
    CREATE_SPORT_SUCCESS = '@sport/CREATE_SPORT_SUCCESS',
    CREATE_SPORT_FAILURE = '@sport/CREATE_SPORT_FAILURE',

    //Update
    UPDATE_SPORT_REQUEST = '@sport/UPDATE_SPORT_REQUEST',
    UPDATE_SPORT_SUCCESS = '@sport/UPDATE_SPORT_SUCCESS',
    UPDATE_SPORT_FAILURE = '@sport/UPDATE_SPORT_FAILURE',

    //Delete
    DELETE_SPORT_REQUEST = '@sport/DELETE_SPORT_REQUEST',
    DELETE_SPORT_SUCCESS = '@sport/DELETE_SPORT_SUCCESS',
    DELETE_SPORT_FAILURE = '@sport/DELETE_SPORT_FAILURE',
}

/**
 * Data types
 */
export interface Sport {
    id?: number
    spDivisionId?: number
    parentDivision?: Division
    name?: string
    description?: string
    type?: string
    sets?: number
    penaltis?: number
    fasegrupo?: number
    createdAt?: string
    status?: string
    childrenSportConfig?: SportConfig[]
}

/**
 * State type
 */
export interface SportState {
    readonly all: Sport[]
    readonly data: Sport[]
    readonly loading: boolean
    readonly error: boolean
}