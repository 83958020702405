import { action } from 'typesafe-actions';
import { DelegationsTypes, Delegation } from './types'

//Load
export const loadAllDelegationsRequest = () => action(DelegationsTypes.LOAD_ALLDELEGATIONS_REQUEST) 
export const loadAllDelegationsSuccess = (data: Delegation[]) => action(DelegationsTypes.LOAD_ALLDELEGATIONS_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadAllDelegationsFailure = () => action(DelegationsTypes.LOAD_ALLDELEGATIONS_FAILURE) 

//Load single
export const loadDelegationRequest = (id: number) => action(DelegationsTypes.LOAD_DELEGATION_REQUEST, id) 
export const loadDelegationSuccess = (data: Delegation[]) => action(DelegationsTypes.LOAD_DELEGATION_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadDelegationFailure = () => action(DelegationsTypes.LOAD_DELEGATION_FAILURE) 

//Create
export const createDelegationRequest = (newCart: Delegation) => action(DelegationsTypes.CREATE_DELEGATION_REQUEST, newCart)
export const createDelegationSuccess = (cart: Delegation) => action(DelegationsTypes.CREATE_DELEGATION_SUCCESS, cart)
export const createDelegationFailure = (error: {}) => action(DelegationsTypes.CREATE_DELEGATION_FAILURE, error)

//Update
export const updateDelegationRequest = (divisionToUpdate: Delegation) => action(DelegationsTypes.UPDATE_DELEGATION_REQUEST, divisionToUpdate) 
export const updateDelegationSuccess = (data: Delegation) => action(DelegationsTypes.UPDATE_DELEGATION_SUCCESS, data)
export const updateDelegationFailure = () => action(DelegationsTypes.UPDATE_DELEGATION_FAILURE) 

//Delete
export const deleteDelegationRequest = (id: number) => action(DelegationsTypes.DELETE_DELEGATION_REQUEST, id) 
export const deleteDelegationSuccess = (data: Delegation) => action(DelegationsTypes.DELETE_DELEGATION_SUCCESS, data)
export const deleteDelegationFailure = () => action(DelegationsTypes.DELETE_DELEGATION_FAILURE) 

export const uploadImageRequest = (image: any) => action(DelegationsTypes.UPLOAD_IMAGE_REQUEST, image) 
export const uploadImageSuccess = (image: any) => action(DelegationsTypes.UPLOAD_IMAGE_SUCCESS, image)
export const uploadImageFailure = () => action(DelegationsTypes.UPLOAD_IMAGE_FAILURE) 