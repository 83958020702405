import React, {useState, useEffect} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../../store'

import {Component} from '../../../../../store/ducks/component/types'
import {Match} from '../../../../../store/ducks/spmatch/types'
import Moment from 'moment'
import {
  loadMatchesFinalsBySportRequest,
  updateMatchRequest,
} from '../../../../../store/ducks/spmatch/actions'
import {loadTeamsRequest} from '../../../../../store/ducks/spteam/actions'
import {loadGroupsRequest} from '../../../../../store/ducks/spgroup/actions'
// import { updateDelegationRequest } from '../../../../../store/ducks/spdelegation/actions';
// import { Delegation } from '../../../../../store/ducks/spdelegation/types';
// import { updateDivisionRequest } from '../../../../../store/ducks/spdivision/actions';
// import { Division } from '../../../../../store/ducks/spdivision/types';

interface handleCloseProps {
  handleClose: () => void
  child: Match
  selectedOption: string
}

const Update = ({handleClose, child, selectedOption}: handleCloseProps) => {
  const dispatch = useDispatch()
  const [validated, setValidated] = useState(false)
  const component = useSelector((state: ApplicationState) => state.component)
  //const [name, setName] = useState<string | undefined>('')
  //const [description, setDescription] = useState<string | undefined>('')
  //const [order, setOrder] = useState<number | undefined>(0)

  const teams = useSelector((state: ApplicationState) => state.teams)

  const [inputList, setInputList] = useState({
    id: child.id,
    datetime: child.datetime,
    description: child.description,
    location: child.location,
    gamenumber: child.gamenumber,
    name: child.name,
    detail: child.detail,
    spSportId: child.spSportId,
    componentId: child.componentId,
    status: child.status,
    childrenResults: child.childrenResults,
  })

  const groups = useSelector((state: ApplicationState) => state.groups)
  const matches = useSelector((state: ApplicationState) => state.matches)

  useEffect(() => {
    // setName(child.name)
    // setDescription(child.description)
    dispatch(loadTeamsRequest(+selectedOption)) //Puxa componentes com seus filhos primários
    dispatch(loadGroupsRequest(+selectedOption))
    dispatch(loadMatchesFinalsBySportRequest(+selectedOption))
  }, [])

  // handle input change
  const handleInputChange = (e: any, timesIndex?: number) => {
    const {name, value} = e.target
    const list: any = {...inputList}
    if (timesIndex === undefined) {
      list[name] = value
    } else {
      let num: number = Number(value)

      //Checa se Index existe:
      if (list['childrenResults'][timesIndex]) {
        if (Number.isInteger(num)) {
          list['childrenResults'][timesIndex]['spAthleteTeamId'] = num;
          list['childrenResults'][timesIndex]['parentTeam'] = {id: ''}
          list['childrenResults'][timesIndex]['parentTeam']['id'] = num
          // list['childrenResults'][timesIndex]['ref'] = null;
        } else {
          list['childrenResults'][timesIndex]['parentTeam'] = ''
          list['childrenResults'][timesIndex]['ref'] = value
        }
      }

      // else {
      //     if(Number.isInteger(num)) {
      //         list['childrenResults'][timesIndex] = { spTeamId: "" }
      //         list['childrenResults'][timesIndex]['spTeamId'] = num;
      //         list['childrenResults'][timesIndex]['parentTeam'] = { id: "" }
      //         list['childrenResults'][timesIndex]['parentTeam']['id'] = num;
      //         list['childrenResults'][timesIndex]['ref'] = null;
      //     } else {
      //         list['childrenResults'][timesIndex] = { ref: "" }
      //         list['childrenResults'][timesIndex]['spTeamId'] = null;
      //         list['childrenResults'][timesIndex]['parentTeam']['id'] = null;
      //         list['childrenResults'][timesIndex]['ref'] = value;
      //     }
      // }
    }

    setInputList(list)
  }
  console.log("INPUT LIST", inputList)

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    console.log('submit', component.data.id)
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)

    const matchToUpdate: Match = {
      id: inputList.id,
      datetime: inputList.datetime,
      description: inputList.description,
      location: inputList.location,
      gamenumber: inputList.gamenumber,
      name: inputList.name,
      detail: inputList.detail,
      spSportId: inputList.spSportId,
      componentId: inputList.componentId,
      status: inputList.status,
      childrenResults: inputList.childrenResults,
    }

    console.log('------------------ COMPONENT TO UPDATE', matchToUpdate)
    dispatch(updateMatchRequest(matchToUpdate))
    handleClose()
  }

  if (teams.loading || matches.loading) return <div>Loading</div>

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-3'>
            <Form.Group>
              <Form.Label>Datetime</Form.Label>
              <Form.Control
                placeholder='DateTime'
                name='datetime'
                value={Moment(inputList.datetime).format('YYYY-MM-DD HH:mm')}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </div>
          <div className='col-3'>
            <Form.Label>Detail</Form.Label>
            <select
              className='selectpicker form-control'
              name='detail'
              onChange={(e) => handleInputChange(e)}
            >
              <option value='' selected disabled hidden>
                --Selecione--
              </option>
              <option value='Fase de grupos' selected={'Fase de grupos' === inputList.detail}>
                Fase de grupos
              </option>
              <option value='Pré oitavas' selected={'Pré oitavas' === inputList.detail}>
                Pré oitavas
              </option>
              <option value='Oitavas de final' selected={'Oitavas de final' === inputList.detail}>
                Oitavas de final
              </option>
              <option value='Pré quartas' selected={'Pré quartas' === inputList.detail}>
                Pré quartas
              </option>
              <option value='Quartas de final' selected={'Quartas de final' === inputList.detail}>
                Quartas de final
              </option>
              <option value='Pré semifinal' selected={'Pré semifinal' === inputList.detail}>
                Pré semifinal
              </option>
              <option value='Semifinal' selected={'Semifinal' === inputList.detail}>
                Semifinal
              </option>
              <option value='7/8' selected={'7/8' === inputList.detail}>
                7/8
              </option>
              <option value='5/6' selected={'5/6' === inputList.detail}>
                5/6
              </option>
              <option value='3/4' selected={'3/4' === inputList.detail}>
                3/4
              </option>
              <option value='Pré final' selected={'Pré final' === inputList.detail}>
                Pré final
              </option>
              <option value='Final' selected={'Final' === inputList.detail}>
                Final
              </option>
            </select>
          </div>
          {/* <div className='col-3'>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                placeholder='Description'
                name='description'
                value={inputList.description}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </div> */}
          <div className='col-3'>
            <Form.Group>
              <Form.Label>Location</Form.Label>
              <Form.Control
                placeholder='Location'
                name='location'
                value={inputList.location}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </div>
          <div className='col-3'>
            <Form.Group>
              <Form.Label>Gamenumber</Form.Label>
              <Form.Control
                placeholder='Gamenumber'
                name='gamenumber'
                value={inputList.gamenumber}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </div>
        </div>
        <br />
        <div className='row'>
          {/* <div className='col-3'>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder='name'
                name='name'
                value={inputList.name}
                onChange={(e) => handleInputChange(e)}
              />
            </Form.Group>
          </div> */}

          <div className='col-6'>
            <Form.Label>Equipe 1</Form.Label>
            <select
              className='selectpicker form-control'
              name='times'
              onChange={(e) => handleInputChange(e, 0)}
            >
              <option value='' selected disabled hidden>
                --Selecione--
              </option>

              {teams.data.map((team, index) => {
                return (
                  <option
                    value={team.id}
                    selected={team.id === inputList.childrenResults![0].spAthleteTeamId}
                  >
                    {team.parentDelegation?.name}
                  </option>
                )
              })}

              {/* TODO: aqui */}
              <option disabled>────────────────────</option>
              {groups.data.map((group, index) => {
                return (
                  <>
                    <option
                      selected={
                        '1º ' + group.name === inputList.childrenResults![0].ref &&
                        !inputList.childrenResults![0].spAthleteTeamId
                      }
                      value={'1º ' + group.name}
                    >
                      1º {group.name}
                    </option>
                    <option
                      selected={
                        '2º ' + group.name === inputList.childrenResults![0].ref &&
                        !inputList.childrenResults![0].spAthleteTeamId
                      }
                      value={'2º ' + group.name}
                    >
                      2º {group.name}
                    </option>
                    <option
                      selected={
                        '3º ' + group.name === inputList.childrenResults![0].ref &&
                        !inputList.childrenResults![0].spAthleteTeamId
                      }
                      value={'3º ' + group.name}
                    >
                      3º {group.name}
                    </option>
                  </>
                )
              })}

              <option disabled>────────────────────</option>
              <option
                selected={
                  '1º MELHOR 2º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'1º MELHOR 2º'}
              >
                1º MELHOR 2º
              </option>
              <option
                selected={
                  '2º MELHOR 2º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'2º MELHOR 2º'}
              >
                2º MELHOR 2º
              </option>
              <option
                selected={
                  '3º MELHOR 2º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'3º MELHOR 2º'}
              >
                3º MELHOR 2º
              </option>
              <option
                selected={
                  '4º MELHOR 2º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'4º MELHOR 2º'}
              >
                4º MELHOR 2º
              </option>
              <option disabled>────────────────────</option>
              <option
                selected={
                  '1º MELHOR 3º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'1º MELHOR 3º'}
              >
                1º MELHOR 3º
              </option>
              <option
                selected={
                  '2º MELHOR 3º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'2º MELHOR 3º'}
              >
                2º MELHOR 3º
              </option>
              <option
                selected={
                  '3º MELHOR 3º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'3º MELHOR 3º'}
              >
                3º MELHOR 3º
              </option>
              <option
                selected={
                  '4º MELHOR 3º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'4º MELHOR 3º'}
              >
                4º MELHOR 3º
              </option>
              <option disabled>────────────────────</option>
              <option
                selected={
                  '1º MELHOR 4º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'1º MELHOR 4º'}
              >
                1º MELHOR 4º
              </option>
              <option
                selected={
                  '2º MELHOR 4º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'2º MELHOR 4º'}
              >
                2º MELHOR 4º
              </option>
              <option
                selected={
                  '3º MELHOR 4º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'3º MELHOR 4º'}
              >
                3º MELHOR 4º
              </option>
              <option
                selected={
                  '4º MELHOR 4º' === inputList.childrenResults![0].ref &&
                  !inputList.childrenResults![0].spAthleteTeamId
                }
                value={'4º MELHOR 4º'}
              >
                4º MELHOR 4º
              </option>
              <option disabled>────────────────────</option>
              {matches.dataMatchesFinalsBySport.map((final, index) => {
                return (
                  <>
                    <option
                      selected={
                        'Vencedor Jogo ' + final.gamenumber === inputList.childrenResults![0].ref &&
                        !inputList.childrenResults![0].spAthleteTeamId
                      }
                      value={'Vencedor Jogo ' + final.gamenumber}
                    >
                      Vencedor Jogo {final.gamenumber}
                    </option>
                    <option
                      selected={
                        'Perdedor Jogo ' + final.gamenumber === inputList.childrenResults![0].ref &&
                        !inputList.childrenResults![0].spAthleteTeamId
                      }
                      value={'Perdedor Jogo ' + final.gamenumber}
                    >
                      Perdedor Jogo {final.gamenumber}
                    </option>
                  </>
                )
              })}
            </select>
          </div>
          <div className='col-6'>
            <Form.Label>Equipe 2</Form.Label>
            <select
              className='selectpicker form-control'
              name='times'
              onChange={(e) => handleInputChange(e, 1)}
            >
              <option value='' selected disabled hidden>
                --Selecione--
              </option>
              {teams.data.map((team, index) => {
                return (
                  <option
                    value={team.id}
                    selected={team.id === inputList.childrenResults![1].spAthleteTeamId}
                  >
                    {team.parentDelegation?.name}
                  </option>
                )
              })}
              {/* TODO: aqui */}
              <option disabled>────────────────────</option>
              {groups.data.map((group, index) => {
                return (
                  <>
                    <option
                      selected={
                        '1º ' + group.name === inputList.childrenResults![1].ref &&
                        !inputList.childrenResults![1].spAthleteTeamId
                      }
                      value={'1º ' + group.name}
                    >
                      1º {group.name}
                    </option>
                    <option
                      selected={
                        '2º ' + group.name === inputList.childrenResults![1].ref &&
                        !inputList.childrenResults![1].spAthleteTeamId
                      }
                      value={'2º ' + group.name}
                    >
                      2º {group.name}
                    </option>
                    <option
                      selected={
                        '3º ' + group.name === inputList.childrenResults![1].ref &&
                        !inputList.childrenResults![1].spAthleteTeamId
                      }
                      value={'3º ' + group.name}
                    >
                      3º {group.name}
                    </option>
                  </>
                )
              })}
              <option disabled>────────────────────</option>
              <option
                selected={
                  '1º MELHOR 2º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'1º MELHOR 2º'}
              >
                1º MELHOR 2º
              </option>
              <option
                selected={
                  '2º MELHOR 2º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'2º MELHOR 2º'}
              >
                2º MELHOR 2º
              </option>
              <option
                selected={
                  '3º MELHOR 2º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'3º MELHOR 2º'}
              >
                3º MELHOR 2º
              </option>
              <option
                selected={
                  '4º MELHOR 2º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'4º MELHOR 2º'}
              >
                4º MELHOR 2º
              </option>
              <option disabled>────────────────────</option>
              <option
                selected={
                  '1º MELHOR 3º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'1º MELHOR 3º'}
              >
                1º MELHOR 3º
              </option>
              <option
                selected={
                  '2º MELHOR 3º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'2º MELHOR 3º'}
              >
                2º MELHOR 3º
              </option>
              <option
                selected={
                  '3º MELHOR 3º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'3º MELHOR 3º'}
              >
                3º MELHOR 3º
              </option>
              <option
                selected={
                  '4º MELHOR 3º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'4º MELHOR 3º'}
              >
                4º MELHOR 3º
              </option>
              <option disabled>────────────────────</option>
              <option
                selected={
                  '1º MELHOR 4º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'1º MELHOR 4º'}
              >
                1º MELHOR 4º
              </option>
              <option
                selected={
                  '2º MELHOR 4º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'2º MELHOR 4º'}
              >
                2º MELHOR 4º
              </option>
              <option
                selected={
                  '3º MELHOR 4º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'3º MELHOR 4º'}
              >
                3º MELHOR 4º
              </option>
              <option
                selected={
                  '4º MELHOR 4º' === inputList.childrenResults![1].ref &&
                  !inputList.childrenResults![1].spAthleteTeamId
                }
                value={'4º MELHOR 4º'}
              >
                4º MELHOR 4º
              </option>
              <option disabled>────────────────────</option>
              {matches.dataMatchesFinalsBySport.map((final, index) => {
                return (
                  <>
                    <option
                      selected={
                        'Vencedor Jogo ' + final.gamenumber === inputList.childrenResults![1].ref &&
                        !inputList.childrenResults![1].spAthleteTeamId
                      }
                      value={'Vencedor Jogo ' + final.gamenumber}
                    >
                      Vencedor Jogo {final.gamenumber}
                    </option>
                    <option
                      selected={
                        'Perdedor Jogo ' + final.gamenumber === inputList.childrenResults![1].ref &&
                        !inputList.childrenResults![1].spAthleteTeamId
                      }
                      value={'Perdedor Jogo ' + final.gamenumber}
                    >
                      Perdedor Jogo {final.gamenumber}
                    </option>
                  </>
                )
              })}
            </select>
          </div>
        </div>

        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
        <br />
        <Button variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
    </>
  )
}
export default Update
