import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap-v5';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../store';

import { Component } from '../../../../../store/ducks/component/types'
import { updateDelegationRequest, uploadImageRequest } from '../../../../../store/ducks/spdelegation/actions';
import { Delegation } from '../../../../../store/ducks/spdelegation/types';
import { updateDivisionRequest } from '../../../../../store/ducks/spdivision/actions';
import { Division } from '../../../../../store/ducks/spdivision/types';

interface handleCloseProps {
    handleClose: () => void
    child: Delegation
}

const Update = ({handleClose, child}:handleCloseProps) => {
    
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const component = useSelector((state: ApplicationState) => state.component);
    const [name, setName] = useState<string | undefined>("");
    const [description, setDescription] = useState<string | undefined>("");
    const [order, setOrder] = useState<number | undefined>(0);

    const [selectedFile, setSelectedFile] = useState<any>();
    const [isSelected, setIsSelected] = useState(false);
    
    useEffect(() => {
        setName(child.name)
        setDescription(child.description)

    }, [])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        console.log("submit", component.data.id)
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        
        if(name){

            let nameFile= ""
            // if(isSelected){
            //     nameFile = Date.now() + '' + selectedFile.name
            //     const formData = new FormData();
                
            //     formData.append("file", selectedFile, nameFile);
            //     console.log("FORMDATA", formData)
            //     dispatch(uploadImageRequest(formData))
            // }
            
            // var data = new Date(); 
            const delegationToUpdate: Delegation = { 
                id: child.id,
                name,
                //image: isSelected? nameFile : child.image,
                description,
                //// createdAt: (data.getTime()/1000).toString(), //updatedAt
                status: '1',
            };

            console.log("------------------ COMPONENT TO UPDATE", delegationToUpdate)
            dispatch(updateDelegationRequest(delegationToUpdate))
            handleClose()
        }
    }
    const changeHandler = (event:any) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
    };

    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}> 
                <Form.Group controlId="fromName">
                    <Form.Label>Nome da equipe</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                        name="name"
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome do produto
                    </Form.Control.Feedback>
                </Form.Group>

                <br/>
                <Form.Group controlId="fromName">
                    <Form.Label>Descr</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={description} 
                        onChange={e => setDescription(e.target.value)} 
                        name="description"
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome do produto
                    </Form.Control.Feedback>
                </Form.Group>

                {/* <br/>
                <Form.Group>
                    <Form.Control 
                        name="file"
                        id="exampleFormControlFile1"
                        type="file"
                        // label="Selecione um arquivo"
                        onChange={changeHandler}
                    />
                </Form.Group> */}
                {isSelected ? (
                    <div>
                        <p>Filename: {selectedFile.name}</p>
                        <p>Filetype: {selectedFile.type}</p>
                        <p>Size in bytes: {selectedFile.size}</p>
                        <p>
                            lastModifiedDate:{' '}
                            {selectedFile.lastModifiedDate.toLocaleDateString()}
                        </p>
                    </div>
                    ) : (
                        <p>Select a file to show details</p>
                    )
                }
                <br/>
                <Button variant="primary" type="submit" className="float-right" >
                    Salvar
                </Button> 
            </Form>
        </>
    );
}
export default Update;
