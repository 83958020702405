import {combineReducers} from 'redux'
// import repositories from './repositories';
import carts from './carts'
import users from './users'
import me from './me'
import component from './component'
import extras from './extras'
import lead from './lead'
import leads from './leads'
import course from './course'
import lists from './lists'
import emailToList from './email'
import annotation from './annotation'
import annotations from './annotations'
import supports from './support'
import wppcamp from './wppcamp'
import wppgroup from './wppgroup'
import contact from './contact'
import city from './city'
import state from './state'

import divisions from './spdivision'
import delegations from './spdelegation'
import sports from './spsport'
import matches from './spmatch'
import classification from './spclassification'
import teams from './spteam'
import sportconfig from './spsportconfig'
import groups from './spgroup'
import groupteams from './spgroupteam'
// import results from './spresultOLD'
import partial from './sppartial'
import grouppontuation from './spgrouppontuation'
import athleteteam from './spathleteteam'
import athlete from './spathlete'

export default combineReducers({
  carts,
  users,
  me,
  component,
  extras,
  lead,
  leads,
  course,
  lists,
  emailToList,
  annotation,
  annotations,
  supports,
  wppcamp,
  wppgroup,
  contact,
  city,
  state,

  divisions,
  delegations,
  sports,
  sportconfig,
  matches,
  classification,
  teams,
  groups,
  groupteams,
  // results,
  partial,
  grouppontuation,
  athleteteam,
  athlete,
})
