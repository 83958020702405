import { action } from 'typesafe-actions';
import { GroupteamTypes, Groupteam } from './types'

//Load single
export const loadGroupteamRequest = (id: number) => action(GroupteamTypes.LOAD_GROUPTEAM_REQUEST, id) 
export const loadGroupteamSuccess = (data: Groupteam[]) => action(GroupteamTypes.LOAD_GROUPTEAM_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadGroupteamFailure = () => action(GroupteamTypes.LOAD_GROUPTEAM_FAILURE) 

//Create
export const createGroupteamRequest = (groupTeamId: number, data: Groupteam[]) => action(GroupteamTypes.CREATE_GROUPTEAM_REQUEST, {groupTeamId,data})
export const createGroupteamSuccess = (data: Groupteam[]) => action(GroupteamTypes.CREATE_GROUPTEAM_SUCCESS, data)
export const createGroupteamFailure = (error: {}) => action(GroupteamTypes.CREATE_GROUPTEAM_FAILURE, error)

//Update
export const updateGroupteamRequest = (data: Groupteam) => action(GroupteamTypes.UPDATE_GROUPTEAM_REQUEST, data) 
export const updateGroupteamSuccess = (data: Groupteam) => action(GroupteamTypes.UPDATE_GROUPTEAM_SUCCESS, data)
export const updateGroupteamFailure = () => action(GroupteamTypes.UPDATE_GROUPTEAM_FAILURE) 

//Delete
export const deleteGroupteamRequest = (team_id: number, group_id: number) => action(GroupteamTypes.DELETE_GROUPTEAM_REQUEST, {team_id, group_id}) 
export const deleteGroupteamSuccess = (data: Groupteam) => action(GroupteamTypes.DELETE_GROUPTEAM_SUCCESS, data)
export const deleteGroupteamFailure = () => action(GroupteamTypes.DELETE_GROUPTEAM_FAILURE) 
