import _ from 'lodash';
import { Partial } from '../../../../../../../store/ducks/sppartial/types';
import { Match, MatchState, Result } from '../../../../../../../store/ducks/spmatch/types';


const createBigPontuation = (arrayGroupPontuation:any) => {
 
    let bigPontuation = 
         _.chain(arrayGroupPontuation)
        .groupBy("spTeamId")
        .map(function(value, key) {
            // return [key, _.reduce(value, function(result, currentObject) {
            return _.reduce(value, function(result, currentObject) {
                console.log("result.pontuacao", result.pontuacao)
                console.log("currentObject.pontuacao", currentObject.pontuacao)
                return {
                    // spTeamId: key,
                    id: currentObject.idGroupTeam,
                    jogos: result.jogos + currentObject.jogos,
                    pontuacao: result.pontuacao + currentObject.pontuacao,

                    vitorias: result.vitorias + currentObject.vitorias,
                    empates: result.empates + currentObject.empates,
                    derrotas: result.derrotas + currentObject.derrotas,
                    vitoriascondicionais: result.vitoriascondicionais + currentObject.vitoriascondicionais,
                    derrotascondicionais: result.derrotascondicionais + currentObject.derrotascondicionais,

                    geralfeito: result.geralfeito + currentObject.geralfeito,
                    geraltomado: result.geraltomado + currentObject.geraltomado,
                    parcialfeito: +result.parcialfeito + +currentObject.parcialfeito,
                    parcialtomado: +result.parcialtomado + +currentObject.parcialtomado,
                    
                    saldogeral: result.saldogeral + currentObject.saldogeral,
                    saldoparcial: result.saldoparcial + currentObject.saldoparcial,

                    geralaverage: ( result.geralfeito + currentObject.geralfeito ) / ( result.geraltomado + currentObject.geraltomado ),
                    parcialaverage: ( +result.parcialfeito + +currentObject.parcialfeito ) / (+result.parcialtomado + +currentObject.parcialtomado),
                }
            }, {
                // spTeamId: key,
                id: 0,
                jogos: 0,
                pontuacao: 0,

                vitorias: 0,
                empates: 0,
                derrotas: 0,
                vitoriascondicionais: 0,
                derrotascondicionais: 0,

                geralfeito: 0,
                geraltomado: 0,
                parcialfeito: 0,
                parcialtomado: 0,

                saldogeral: 0,
                saldoparcial: 0,

                geralaverage: 0,
                parcialaverage: 0,

            });
        })
        // .object()
        .value();

        return bigPontuation

        

}

export default createBigPontuation
