import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    //Load
    loadAllSportsRequest,
    loadAllSportsSuccess,
    loadAllSportsFailure,
    
    //Load single
    loadSportsRequest,
    loadSportsSuccess,
    loadSportsFailure,

    //Load by championship
    loadSportsByChampionshipRequest,
    loadSportsByChampionshipSuccess,
    loadSportsByChampionshipFailure,

    //Create
    createSportRequest,
    createSportsuccess,
    createSportFailure,

    //Update
    updateSportRequest,
    updateSportSuccess,
    updateSportFailure,

    //Delete
    deleteSportRequest,
    deleteSportSuccess,
    deleteSportFailure
    

} from './actions';
import { Sport } from './types';

//Load
export function* loadAllSports() {
    try {
        const response : Sport[] = yield call(api.get, 'sport');
        yield put(loadAllSportsSuccess(response));
    } catch (error:any) {
        yield put(loadAllSportsFailure());
    }
}

//Load single
export function* loadSports(payload:ReturnType<typeof loadSportsRequest>) {
    console.log("LOAD SPORTS")
    try {
        const response : Sport[] = yield call(api.get, 'sport/'+payload.payload);
        yield put(loadSportsSuccess(response));
    } catch (error) {
        yield put(loadSportsFailure());
    }
}

export function* loadSportsByChampionship(payload:ReturnType<typeof loadSportsByChampionshipRequest>) {
    console.log("LOAD SPORTS BY CHAMPIONSHIP")
    try {
        const response : Sport[] = yield call(api.get, 'sport/bychampionship/'+payload.payload);
        yield put(loadSportsByChampionshipSuccess(response));
    } catch (error) {
        console.log("ERRX", error)
        yield put(loadSportsByChampionshipFailure());
    }
}

//Create
export function* createSport(payload:ReturnType<typeof createSportRequest>) {
    try {
        put(createSportRequest(payload.payload))
        const response : Sport = yield call(api.post, 'sport', payload.payload);
        yield put(createSportsuccess(response));
    } catch (error:any) {
        console.log("error",error)
        yield put(createSportFailure(error.response.message));
    }
}

//Update
export function* updateSport(payload:ReturnType<typeof updateSportRequest>) {
    try {
        put(updateSportRequest(payload.payload))
        const response : Sport = yield call(api.patch, 'sport/'+payload.payload.id, payload.payload);
        yield put(updateSportSuccess(response));
    } catch (error) {
        console.log("errorxxx",error)
        yield put(updateSportFailure());
    }
}

//Delete
export function* deleteSport(payload:ReturnType<typeof deleteSportRequest>) {
    try {
        put(deleteSportRequest(payload.payload))
        const response : Sport = yield call(api.delete, 'sport/'+payload.payload);
        yield put(deleteSportSuccess(response));
    } catch (error) {
        yield put(deleteSportFailure());
    }
}
