import React, {useEffect, useState} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {ApplicationState} from '../../../../../../store'
import {loadDelegationRequest} from '../../../../../../store/ducks/spdelegation/actions'

import {Sport} from '../../../../../../store/ducks/spsport/types'
// import {
//   createTeamRequest,
//   deleteTeamRequest,
//   loadTeamsRequest,
//   updateTeamRequest,
// } from '../../../../../../store/ducks/spteam/actions'
// import {Team} from '../../../../../../store/ducks/spteam/types'
import {Athlete} from '../../../../../../store/ducks/spathlete/types'
import { loadAthleteTeamBySportRequest, updateAthleteTeamRequest } from '../../../../../../store/ducks/spathleteteam/actions'
import { AthleteTeam } from '../../../../../../store/ducks/spathleteteam/types'
//import { updateAthleteRequest } from '../../../../../../store/ducks/spathlete/actions'

interface ParamTypes {
  id: string
}

interface handleCloseProps {
  handleClose: () => void
  sportSelected: Sport
}

const Classification = ({handleClose, sportSelected}: handleCloseProps) => {
  const {id} = useParams()
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    dispatch(loadDelegationRequest(+id!)) //Puxa componentes com seus filhos primários
    dispatch(loadAthleteTeamBySportRequest(+sportSelected.id!)) //Puxa componentes com seus filhos primários
    // console.log("SPORT SELECTED", sportSelected.id)
  }, [id, sportSelected])

  // const history = useNavigate();
  const dispatch = useDispatch()
  const athleteteams = useSelector((state: ApplicationState) => state.athleteteam)

  const names = (athletes: Athlete[]) => {
    const commasep = athletes?.map((item) => item.name).join(', ')
    return commasep
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)

    //Pega todos os itens selecionados:
    let checkboxes = document.getElementsByName('check[]')
    let selectedCboxes = Array.prototype.slice.call(checkboxes)

    console.log('Selected', selectedCboxes)
    //Para cada item da lista, vamos verificar:
    selectedCboxes.map((item) => {
      console.log('Item', item.id + ' ' + item.value + 'xx')
      let teamToUpdate: AthleteTeam = {
        id: +item.id,
        classification: +item.value,
      }
      dispatch(updateAthleteTeamRequest(teamToUpdate))
    })

    // dispatch(createTeamRequest(team))
    handleClose()
  }

  console.log('athleteteams', athleteteams)
  // console.log("DELEGATIONS", delegations)
  // console.log("COMPONENT", component)

  if (athleteteams.loading) return <div>Loading</div>

  return (
    <>
      <h2>
        {sportSelected.name} - {sportSelected.parentDivision?.name}
      </h2>
      <Form validated={validated} onSubmit={handleSubmit}>
        <br />
        {athleteteams.data.map((athleteteam, index) => {
       
            return (
              <div key={index}>
                <div className='row'>
                  <div className='col-3'>
                    {athleteteam.parentTeam?.parentDelegation?.name} {athleteteam.name}{' '}
                    {names(athleteteam.childrenAthletes!)}
                  </div>
                  <div className='col-9'>
                    <Form.Group>
                      <Form.Control
                        placeholder=''
                        name={'check[]'}
                        id={'' + athleteteam.id}
                        defaultValue={athleteteam.classification}
                        // onChange={e => console.log(e)}
                      />
                    </Form.Group>
                  </div>
                </div>
                <br />
              </div>
            )
         
        })}
        <br />
        <br />
        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
    </>
  )
}
export default Classification
