/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Link, useParams } from 'react-router-dom'
import { Component, ComponentState } from '../../../../../store/ducks/component/types'

import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import Create from './create'
import Update from './update'
import { DivisionState } from '../../../../../store/ducks/spdivision/types'
import { Sport, SportState } from '../../../../../store/ducks/spsport/types'
import { deleteSportRequest } from '../../../../../store/ducks/spsport/actions'
import SelectTeam from './team/Team'
import Configuration from './configuration/Configuration'
import Classification from './classification/Classification'
import { KTSVG } from '../../../../design/helpers';

interface ParamTypes {
  id: string
}

type Props = {
  className: string
  divisions: DivisionState
  sports: SportState
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
  selectedOption: string
}

const ManageSportWidget: React.FC<Props> = ({className, sports, divisions, setSelectedOption, selectedOption}) => {
  // console.log("COMP AQUI", divisions)
  let { id } = useParams();
  const [show, setShow] = useState<boolean>(false);
  const [showTeams, setShowTeams] = useState<boolean>(false);
  const [showConfig, setShowConfig] = useState<boolean>(false);
  const [showClassification, setShowClassification] = useState<boolean>(false);
  
  const [sportSelected, setSportSelected] = useState<Sport>({});

  const [action, setAction] = useState<string>("");
  const [child, setChild] = useState<Sport>({});
 
  const dispatch = useDispatch();
  const history = useNavigate();

  const createTeam = (child: Sport) => {
    console.log("SELECIONEI O SPORT", child)
    setSportSelected(child)
    setShowTeams(true);
  }

  const handleCloseTeams = () => {
    setShowTeams(false);
  }

  const createConfig = (child: Sport) => {
    console.log("SELECIONEI O SPORT", child)
    setSportSelected(child)
    setShowConfig(true);
  }

  const handleCloseConfig = () => {
    setShowConfig(false);
  }

  const createClassification = (child: Sport) => {
    console.log("SELECIONEI O SPORT", child)
    setSportSelected(child)
    setShowClassification(true);
  }

  const handleCloseClassification = () => {
    setShowClassification(false);
  }

 
  /////////////////////////////////

  const createComponent = () => {
      setAction('createComponent')
      setShow(true);
  }

  const updateComponent = (child: Sport) => {
      setAction('updateComponent')
      setShow(true);
      setChild(child)
  }

  const handleClose = () => {
    setShow(false);
  }

  // Deleta componente: CHILD
  const deleteSport = (sport: Sport) => {
      dispatch(deleteSportRequest(sport.id!))
  }

  return (
    <>
    <Modal size={'lg'} centered show={show} onHide={handleClose}>
      <Modal.Header closeButton placeholder={'Hey'}>
      <Modal.Title>
          { (action === 'createComponent')?'Adicionar componente':'' }
          { (action === 'updateComponent')?'Editar componente':'' }
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          { (action === 'createComponent')?<Create handleClose={handleClose} idDivision={selectedOption} />:'' }
          { (action === 'updateComponent')?<Update handleClose={handleClose} child={child} />:'' }
      </Modal.Body>
    </Modal>

    <Modal size={'lg'} centered show={showTeams} onHide={handleCloseTeams}>
      <Modal.Header closeButton placeholder={'Hey'}>
      <Modal.Title>
          Times
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <SelectTeam handleClose={handleCloseTeams} sportSelected={sportSelected} />
      </Modal.Body>
    </Modal>

    <Modal size={'lg'} centered show={showConfig} onHide={handleCloseConfig}>
      <Modal.Header closeButton placeholder={'Hey'}>
      <Modal.Title>
          Configuração
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Configuration handleClose={handleCloseConfig} sportSelected={sportSelected} />
      </Modal.Body>
    </Modal>

    <Modal size={'lg'} centered show={showClassification} onHide={handleCloseClassification}>
      <Modal.Header closeButton placeholder={'Hey'}>
      <Modal.Title>
          Classificação
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Classification handleClose={handleCloseClassification} sportSelected={sportSelected} />
      </Modal.Body>
    </Modal>

    
    <div className={`card ${className}`}>  
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Modalidades</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Gerenciador de modalidades</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            onClick={() => createComponent()}
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Adicionar modalidade
          </a>
          
        </div>
      </div>

      

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}

        <select className="selectpicker form-control" onChange={(e)=>setSelectedOption(e.target.value)}>
          <option value="" selected disabled hidden>Selecione uma divisão</option>
          {divisions.data.map((division, index) => {
            return <option key={index} selected={(division.id === +selectedOption)?true:false} value={division.id}>{division.name}</option>
          })}
        </select>
        {/* Selected: {selectedOption} */}


        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Modalidade</th>
                <th className='min-w-140px'>Configuração</th>
                <th className='min-w-140px'>Times</th>
                <th className='min-w-140px'>Atletas</th>
                <th className='min-w-140px'>Grupos</th>
                <th className='min-w-140px'>Classificação</th>
                {/* <th className='min-w-120px'>Progress</th> */}
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {sports.data.map((child: any, index) => {
                return(
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <div className='text-dark fw-bolder fs-6'>
                            {child.name}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className='text-dark fw-bolder text-hover-primary fs-6' onClick={() => createConfig(child)} style={{ cursor:'pointer' }}>Configuração</div>
                    </td>
                    
                    <td>
                      <div className='text-dark fw-bolder text-hover-primary fs-6' onClick={() => createTeam(child)} style={{ cursor:'pointer' }}>Times</div>
                    </td>
                    <td>
                      <Link to={'/selectteam/' + id + "/" + child.id } style={{display:'flex' }} className='text-dark fw-bolder text-hover-primary fs-6'>
                        Atletas
                      </Link>
                    </td>
                    <td>
                      <Link to={'/group/' + id + "/" + child.id } style={{display:'flex' }} className='text-dark fw-bolder text-hover-primary fs-6'>
                        Grupos
                      </Link>
                    </td>
                    <td>
                      {/* <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        Intertico
                      </a> */}
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        <div className='text-dark fw-bolder text-hover-primary fs-6' onClick={() => createClassification(child)} style={{ cursor:'pointer' }}>Classificação</div>
                      </span>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          href='#'
                          onClick={() => updateComponent(child)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                        </a>
                        <a
                          href='#'
                          onClick={() => { if (window.confirm('Deseja realmente excluir: ' + child.name + '?')) deleteSport(child)  } }
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                  )
                }
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    </>
  )
}

export {ManageSportWidget}