import {Match, MatchState, Result} from '../../../../../../store/ducks/spmatch/types'
import {Partial} from '../../../../../../store/ducks/sppartial/types'
import {SportConfig} from '../../../../../../store/ducks/spsportconfig/types'
import verifyConditionalScore from './group/aVerifyConditionalScore'
import _ from 'lodash'
import createGroupPontuation from './group/bCreateGroupPontuation'
import createBigPontuation from './group/cCreateBigPontuation'

const jobGroup = (matchSelected: Match, matches: MatchState, inputList: Result[]) => {
  let config: SportConfig[] = matchSelected.parentSport?.childrenSportConfig!
  //let idchave = match.childrenResults![0].parentTeam?.childrenGroupteam![0].spGroupId

  //Verifica se tem placar condicional (ex: Volei)
  let scoreConfig = verifyConditionalScore(config)
  //console.log('##### scoreConfig', scoreConfig)

  //Para cada partida que aconteceu no grupo
  let arrayGroupPontuation = createGroupPontuation(matchSelected, matches, inputList, scoreConfig)
  //console.log('##### arrayGroupPontuation', arrayGroupPontuation)

  //Cria o BigPontuation
  let bigPontuation = createBigPontuation(arrayGroupPontuation)
  //console.log('##### bigPontuation', bigPontuation)

  return bigPontuation
}

export default jobGroup
