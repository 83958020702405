import { Component } from "react"
import { Delegation } from "../spdelegation/types"
import { Sport } from "../spsport/types"

/** 
 * Action types
 */
export enum  ClassificationTypes {
    //Load all
    LOAD_ALLCLASSIFICATION_REQUEST = '@classification/LOAD_ALLCLASSIFICATION_REQUEST',
    LOAD_ALLCLASSIFICATION_SUCCESS = '@classification/LOAD_ALLCLASSIFICATION_SUCCESS',
    LOAD_ALLCLASSIFICATION_FAILURE = '@classification/LOAD_ALLCLASSIFICATION_FAILURE',

    //Load single
    LOAD_CLASSIFICATION_REQUEST = '@classification/LOAD_CLASSIFICATION_REQUEST',
    LOAD_CLASSIFICATION_SUCCESS = '@classification/LOAD_CLASSIFICATION_SUCCESS',
    LOAD_CLASSIFICATION_FAILURE = '@classification/LOAD_CLASSIFICATION_FAILURE',

    //Create
    CREATE_CLASSIFICATION_REQUEST = '@classification/CREATE_CLASSIFICATION_REQUEST',
    CREATE_CLASSIFICATION_SUCCESS = '@classification/CREATE_CLASSIFICATION_SUCCESS',
    CREATE_CLASSIFICATION_FAILURE = '@classification/CREATE_CLASSIFICATION_FAILURE',

    //Update
    UPDATE_CLASSIFICATION_REQUEST = '@classification/UPDATE_CLASSIFICATION_REQUEST',
    UPDATE_CLASSIFICATION_SUCCESS = '@classification/UPDATE_CLASSIFICATION_SUCCESS',
    UPDATE_CLASSIFICATION_FAILURE = '@classification/UPDATE_CLASSIFICATION_FAILURE',

    //Delete
    DELETE_CLASSIFICATION_REQUEST = '@classification/DELETE_CLASSIFICATION_REQUEST',
    DELETE_CLASSIFICATION_SUCCESS = '@classification/DELETE_CLASSIFICATION_SUCCESS',
    DELETE_CLASSIFICATION_FAILURE = '@classification/DELETE_CLASSIFICATION_FAILURE',
}

/**
 * Data types
 */
export interface Classification {
    id?: number

    spDivisionId?: number
    parentDivision?: Sport

    spDelegationId?: number
    parentDelegation?: Delegation

    componentId?: number
    parentComponent?: Component

    children?: any

    pontuation?: number
    gold?: number
    silver?: number
    bronze?: number
    total?: number
    createdAt?: string,
    status?: string,
}

/**
 * State type
 */
export interface ClassificationState {
    readonly all: Classification[]
    readonly data: Classification[]
    readonly loading: boolean
    readonly error: boolean
}