/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {Component, ComponentState} from '../../../../../store/ducks/component/types'

import {useDispatch} from 'react-redux'
import Moment from 'moment'

import Update from './update'
import {Match, MatchState} from '../../../../../store/ducks/spmatch/types'
import {SportState} from '../../../../../store/ducks/spsport/types'
import CreateColetiva from './createColetiva'
import {deleteMatchRequest} from '../../../../../store/ducks/spmatch/actions'
import {Result} from '../../../../../store/ducks/spmatch/types'

import ManageResult from './result/ManageResult'
import CreateIndividual from './createIndividual'
import UpdateIndividual from './updateIndividual'
import {Athlete} from '../../../../../store/ducks/spathlete/types'
import CreateDS from './createDS'
import UpdateDS from './updateDS'
import {KTSVG} from '../../../../design/helpers'
import Loading from '../../../../loading'

type Props = {
  className: string
  matches: MatchState
  sports: SportState
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
  selectedOption: string
}

const ManageMatchWidget: React.FC<Props> = ({
  className,
  matches,
  selectedOption,
  setSelectedOption,
  sports,
}) => {
  console.log('Matches', matches)
  const [show, setShow] = useState<boolean>(false)
  const [showIndividual, setShowIndividual] = useState<boolean>(false)
  const [showDS, setShowDS] = useState<boolean>(false)

  const [action, setAction] = useState<string>('')
  const [child, setChild] = useState<Match>({})

  const [showManageResult, setShowManageResult] = useState<boolean>(false)
  const [matchSelected, setMatchSelected] = useState<Match>({})

  let sportSelected = sports.data.filter((sport) => sport.id! === +selectedOption)[0]
  // console.log("selectedOption", selectedOption)
  // console.log("sports", sports)
  // console.log("sportSelected", sportSelected)

  const dispatch = useDispatch()
  // const history = useNavigate();

  const handleCloseManageResult = () => {
    setShowManageResult(false)
  }

  const handleCloseIndividual = () => {
    setShowIndividual(false)
  }

  const handleClose = () => {
    setShow(false)
  }

  const createMatchDS = () => {
    setAction('createComponent')
    setShowDS(true)
  }

  const updateMatchDS = (child: Match) => {
    setAction('updateComponent')
    setShowDS(true)
    setChild(child)
  }
  const handleCloseDS = () => {
    setShowDS(false)
  }

  const createMatchIndividual = () => {
    setAction('createComponent')
    setShowIndividual(true)
  }

  const updateMatchIndividual = (child: Match) => {
    setAction('updateComponent')
    setShowIndividual(true)
    setChild(child)
  }

  const createComponent = () => {
    setAction('createComponent')
    setShow(true)
  }

  const updateComponent = (child: Match) => {
    setAction('updateComponent')
    setShow(true)
    setChild(child)
  }

  // Deleta componente: CHILD
  const deleteMatch = (match: Match) => {
    // console.log("match delete", match.id)
    dispatch(deleteMatchRequest(match.id!))
  }

  const manageResult = (match: Match) => {
    // console.log("Manage Result", match)
    setShowManageResult(true)
    setMatchSelected(match)
  }

  const names = (athletes: Athlete[]) => {
    if(athletes.length === 0) return ''
    const commasep = athletes.map((item) => item.name).join(', ')
    return commasep
    //return 'test'
  }

  return (
    <>
      
      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton placeholder={'Hey'}>
          <Modal.Title>
            {action === 'createComponent' ? 'Adicionar Partida Coletiva' : ''}
            {action === 'updateComponent' ? 'Editar Partida' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {action === 'createComponent' ? (
            <CreateColetiva selectedOption={selectedOption} handleClose={handleClose} />
          ) : (
            ''
          )}
          {action === 'updateComponent' ? (
            <Update selectedOption={selectedOption} handleClose={handleClose} child={child} />
          ) : (
            ''
          )}
        </Modal.Body>
      </Modal>

      <Modal size='lg' show={showIndividual} onHide={handleCloseIndividual}>
        <Modal.Header closeButton placeholder={'Hey'}>
          <Modal.Title>
            {action === 'createComponent' ? 'Adicionar Partida Individual' : ''}
            {action === 'updateComponent' ? 'Editar Partida Individual' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {action === 'createComponent' ? (
            <CreateIndividual selectedOption={selectedOption} handleClose={handleCloseIndividual} />
          ) : (
            ''
          )}
          {action === 'updateComponent' ? (
            <UpdateIndividual
              selectedOption={selectedOption}
              handleClose={handleCloseIndividual}
              child={child}
            />
          ) : (
            ''
          )}
        </Modal.Body>
      </Modal>

      <Modal size='lg' show={showDS} onHide={handleCloseDS}>
        <Modal.Header closeButton placeholder={'Hey'}>
          <Modal.Title>
            {action === 'createComponent' ? 'Adicionar Partida Dupla/Simples' : ''}
            {action === 'updateComponent' ? 'Editar Partida Dupla/Simples' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {action === 'createComponent' ? (
            <CreateDS selectedOption={selectedOption} handleClose={handleCloseDS} />
          ) : (
            ''
          )}
          {action === 'updateComponent' ? (
            <UpdateDS selectedOption={selectedOption} handleClose={handleCloseDS} child={child} />
          ) : (
            ''
          )}
        </Modal.Body>
      </Modal>

      <Modal size='lg' show={showManageResult} onHide={handleCloseManageResult}>
        <Modal.Header closeButton placeholder={'Hey'}>
          <Modal.Title>Resultado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ManageResult matchSelected={matchSelected} handleClose={handleCloseManageResult} />
        </Modal.Body>
      </Modal>
        
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Partidas</span>
            <span className='text-muted mt-1 fw-bold fs-7'>Gerenciador de partidas</span>
            
          </h3>
          
          <div
            className='card-toolbar flex justify-content-end '
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
           <div>
            {matches.loading && <Loading />}
            {/* <Loading /> */}
           </div>

            {/* {sportSelected?.type === 'Coletiva' ? (
              <a
                href='#'
                className='btn btn-sm btn-light-primary'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_invite_friends'
                onClick={() => createComponent()}
              >
                
                <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Nova partida Coletiva
              </a>
            ) : null} */}
             

            {sportSelected?.type === 'Individual' ? (
              <a
                href='#'
                className='btn btn-sm btn-light-primary'
                style={{marginLeft: 5}}
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_invite_friends'
                onClick={() => createMatchIndividual()}
              >
                <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Nova partida Individual
              </a>
            ) : null}

            {sportSelected?.type === 'Coletiva/Dupla/Simples' ? (
              <a
                href='#'
                className='btn btn-sm btn-light-primary'
                style={{marginLeft: 5}}
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_invite_friends'
                onClick={() => createMatchDS()}
              >
                <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Nova partida
              </a>
            ) : null}
          </div>
        </div>
        
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          <select
            className='selectpicker form-control'
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <option value='' selected disabled hidden>
              Selecione uma modalidade
            </option>
            {sports.data.map((sport, index) => {
              return (
                <option
                  key={index}
                  selected={sport.id === +selectedOption ? true : false}
                  value={sport.id}
                >
                  {sport.name} - {sport.parentDivision?.name}
                </option>
              )
            })}
          </select>
          

          {/* begin::Table container */}
          <div className='table-responsive'>
          
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted'>
                  {/* <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th> */}
                  <th className='w-25px'>Nº</th>
                  <th className='min-w-80px'>Data/Horário</th>
                  <th className='min-w-140px'>Detail</th>
                  <th className='min-w-140px'>Name</th>
                  {/* <th className='min-w-140px'>Description</th> */}
                  <th className='min-w-350px'>Confronto</th>
                  {/* <th className='min-w-120px'>Progress</th> */}
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
              
                {matches.data.map((child: any, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <div className='text-dark fw-bolder text-hover-primary fs-6'>
                              {/*child.id*/} {child.gamenumber}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {/* {child.datetime} */}
                          {Moment(child.datetime).format('DD/MM/YY HH:mm')}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {child.detail}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {child.name}
                        </span>
                      </td>
                      {/* <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {child.description}
                        </span>
                      </td> */}
                      <td>
                        <span className='text-muted fw-bold text-muted d-block fs-7'>
                          {child.childrenResults.map((result: Result, index: number) => {
                            if (result.ref && !result.spAthleteTeamId) {
                              return (
                                <div key={index} style={{color: 'gray'}}>
                                  <span style={{color: 'green'}}>{result.ref}</span>{' '}
                                  {result.pontuation}
                                </div>
                              )
                            } else {
                              // if (result.spAthleteTeamId) {
                              //   return (
                              //     <div key={index}>
                              //       {result.parentAthleteTeam?.parentTeam?.parentDelegation?.name}{' '}
                              //       {result.parentAthleteTeam?.name}
                              //       <div style={{color: 'red'}}>
                              //         {names(result.parentAthleteTeam?.childrenAthletes!)}xx
                              //       </div>
                              //       <div style={{width: 30}}>{result.pontuation}</div>
                              //     </div>
                              //   )
                              // } else {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      color: 'gray',
                                      display: 'flex',
                                      justifyContent: 'start',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div style={{width: 350}}>
                                      {result.parentAthleteTeam?.parentTeam?.parentDelegation?.name}
                                      {" ("+result.parentAthleteTeam?.name+") "}
                                      {names(result.parentAthleteTeam?.childrenAthletes!) + " "}
                                      ({result.parentAthleteTeam?.childrenGroupteam![0]?.parentGroup?.name})
                                    </div>
                                    <div
                                      style={{
                                        fontSize: 14,
                                        color: 'purple',
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div style={{width: 50, textAlign: 'center'}}>
                                        {result.pontuation}
                                      </div>
                                      {result.childrenPartials?.map((partial) => {
                                        if (partial.pontuation) {
                                          return (
                                            <div
                                              style={{
                                                fontSize: 10,
                                                color: 'darkgreen',
                                                width: 30,
                                                textAlign: 'center',
                                              }}
                                            >
                                              {partial.pontuation}
                                            </div>
                                          )
                                        }
                                        return ''
                                      })}
                                    </div>
                                  </div>
                                )
                              //}
                            }
                          })}
                        </span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            href='#'
                            onClick={() => manageResult(child)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          {sportSelected?.type === 'Coletiva' ? (
                            <a
                              href='#'
                              onClick={() => updateComponent(child)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          ) : null}
                          {sportSelected?.type === 'Individual' ? (
                            <a
                              href='#'
                              onClick={() => updateMatchIndividual(child)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          ) : null}
                          {sportSelected?.type === 'Coletiva/Dupla/Simples' ? (
                            <a
                              href='#'
                              onClick={() => updateMatchDS(child)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-3'
                              />
                            </a>
                          ) : null}
                          <a
                            href='#'
                            onClick={() => {
                              if (window.confirm('Deseja realmente excluir: ' + child.name + '?'))
                                deleteMatch(child)
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  )
                })}
                
              </tbody>
              
              {/* end::Table body */}
            </table>
            
            
            {/* end::Table */}
          </div>
          
          {/* end::Table container */}
        </div>
        
        {/* begin::Body */}
      </div>
      
    </>
  )
}

export {ManageMatchWidget}
