import { Component } from "react"
import { AthleteTeam } from "../spathleteteam/types"

/** 
 * Action types
 */
export enum  AthleteTypes {
    //Load single
    LOAD_ATHLETE_REQUEST = '@athlete/LOAD_ATHLETE_REQUEST',
    LOAD_ATHLETE_SUCCESS = '@athlete/LOAD_ATHLETE_SUCCESS',
    LOAD_ATHLETE_FAILURE = '@athlete/LOAD_ATHLETE_FAILURE',

    //Create
    CREATE_ATHLETE_REQUEST = '@athlete/CREATE_ATHLETE_REQUEST',
    CREATE_ATHLETE_SUCCESS = '@athlete/CREATE_ATHLETE_SUCCESS',
    CREATE_ATHLETE_FAILURE = '@athlete/CREATE_ATHLETE_FAILURE',

    //Update
    UPDATE_ATHLETE_REQUEST = '@athlete/UPDATE_ATHLETE_REQUEST',
    UPDATE_ATHLETE_SUCCESS = '@athlete/UPDATE_ATHLETE_SUCCESS',
    UPDATE_ATHLETE_FAILURE = '@athlete/UPDATE_ATHLETE_FAILURE',

    //Delete
    DELETE_ATHLETE_REQUEST = '@athlete/DELETE_ATHLETE_REQUEST',
    DELETE_ATHLETE_SUCCESS = '@athlete/DELETE_ATHLETE_SUCCESS',
    DELETE_ATHLETE_FAILURE = '@athlete/DELETE_ATHLETE_FAILURE',
}

/**
 * Data types
 */
export interface Athlete {
    id?: number

    spAthleteTeamId?: number
    parentAthleteTeam?: AthleteTeam

    name?: string
    description?: string
    image?: string
    detail?: string


    createdAt?: string,
    status?: string,

    // childrenGroupteam?: Groupteam[]
}

/**
 * State type
 */
export interface AthleteState {
    readonly data: Athlete[]
    readonly loading: boolean
    readonly error: boolean
}