import { Component } from "react"
import { AthleteTeam } from "../spathleteteam/types"
import { Partial } from "../sppartial/types"
// import { Result } from "../spresultOLD/types"
import { Sport } from "../spsport/types"
import { Team } from "../spteam/types"

/** 
 * Action types
 */
export enum  MatchesTypes {

    //Load single
    LOAD_MATCHES_REQUEST = '@match/LOAD_MATCHES_REQUEST',
    LOAD_MATCHES_SUCCESS = '@match/LOAD_MATCHES_SUCCESS',
    LOAD_MATCHES_FAILURE = '@match/LOAD_MATCHES_FAILURE',

    //Load matches by group
    LOAD_MATCHESBYGROUP_REQUEST = '@match/LOAD_MATCHESBYGROUP_REQUEST',
    LOAD_MATCHESBYGROUP_SUCCESS = '@match/LOAD_MATCHESBYGROUP_SUCCESS',
    LOAD_MATCHESBYGROUP_FAILURE = '@match/LOAD_MATCHESBYGROUP_FAILURE',

    //Load matches by group
    LOAD_MATCHESFINALSBYSPORT_REQUEST = '@match/LOAD_MATCHESFINALSBYSPORT_REQUEST',
    LOAD_MATCHESFINALSBYSPORT_SUCCESS = '@match/LOAD_MATCHESFINALSBYSPORT_SUCCESS',
    LOAD_MATCHESFINALSBYSPORT_FAILURE = '@match/LOAD_MATCHESFINALSBYSPORT_FAILURE',

    //Create
    CREATE_MATCH_REQUEST = '@match/CREATE_MATCH_REQUEST',
    CREATE_MATCH_SUCCESS = '@match/CREATE_MATCH_SUCCESS',
    CREATE_MATCH_FAILURE = '@match/CREATE_MATCH_FAILURE',

    //Update
    UPDATE_MATCH_REQUEST = '@match/UPDATE_MATCH_REQUEST',
    UPDATE_MATCH_SUCCESS = '@match/UPDATE_MATCH_SUCCESS',
    UPDATE_MATCH_FAILURE = '@match/UPDATE_MATCH_FAILURE',

    //Update Match RESULT
    UPDATE_MATCHRESULT_REQUEST = '@match/UPDATE_MATCHRESULT_REQUEST',
    UPDATE_MATCHRESULT_SUCCESS = '@match/UPDATE_MATCHRESULT_SUCCESS',
    UPDATE_MATCHRESULT_FAILURE = '@match/UPDATE_MATCHRESULT_FAILURE',

    //Delete
    DELETE_MATCH_REQUEST = '@match/DELETE_MATCH_REQUEST',
    DELETE_MATCH_SUCCESS = '@match/DELETE_MATCH_SUCCESS',
    DELETE_MATCH_FAILURE = '@match/DELETE_MATCH_FAILURE',
}

/**
 * Data types
 */
export interface Match {
    id?: number

    spSportId?: number
    parentSport?: Sport

    componentId?: number
    parentComponent?: Component

    local_id?: number
    parentComponentLocal?: Component

    datetime?: string
    description?: string
    location?: string
    gamenumber?: number
    name?: string
    wo?: number
    manualorder?: string
    detail?: string
    createdAt?: string,
    status?: string,
    childrenResults?: Result[]
}

export interface Result {
    id?: number

    sp_match_id?: number
    parentMatch?: Match

    spAthleteTeamId?: number | null;
    parentAthleteTeam?: AthleteTeam;

    ref?: string
    unity?: string
    pontuation?: string
    
    createdAt?: string,
    status?: string,

    childrenPartials?: Partial[]
}

/**
 * State type
 */
export interface MatchState {
    readonly data: Match[]
    readonly dataMatchesByGroup: Match[]
    readonly dataMatchesFinalsBySport: Match[]
    readonly loading: boolean
    readonly error: boolean
}