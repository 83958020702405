import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap-v5';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../../store';

import { Component } from '../../../../../../store/ducks/component/types'
import { updateGroupRequest } from '../../../../../../store/ducks/spgroup/actions';
import { Group } from '../../../../../../store/ducks/spgroup/types';
import { updateGroupteamRequest } from '../../../../../../store/ducks/spgroupteam/actions';

interface handleCloseProps {
    handleClose: () => void
    child: Group
}

const Update = ({handleClose, child}:handleCloseProps) => {
    
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const component = useSelector((state: ApplicationState) => state.component);
    const [name, setName] = useState<string | undefined>("");
    const [description, setDescription] = useState<string | undefined>("");
    const [order, setOrder] = useState<number | undefined>(0);
    
    useEffect(() => {
        setName(child.name)
        setDescription(child.description)
    }, [])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        console.log("submit", component.data.id)
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if(name){
            
            // var data = new Date(); 
            const groupToUpdate: Group = { 
                id: child.id,
                name,
                description: '',
                // createdAt: (data.getTime()/1000).toString(), //updatedAt
                status: "1",
            };

            console.log("------------------ COMPONENT TO UPDATE", groupToUpdate)
            dispatch(updateGroupRequest(groupToUpdate))
            handleClose()
        }
    }

    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}> 
                <Form.Group controlId="fromName">
                    <Form.Label>Nome do grupo</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                        name="name"
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome do produto
                    </Form.Control.Feedback>
                </Form.Group>

                <br/>
                {/* <Form.Group controlId="fromName">
                    <Form.Label>Descr</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={description} 
                        onChange={e => setDescription(e.target.value)} 
                        name="description"
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome do produto
                    </Form.Control.Feedback>
                </Form.Group> */}

                <br/>
                <Button variant="primary" type="submit" className="float-right" >
                    Salvar
                </Button> 
            </Form>
        </>
    );
}
export default Update;
