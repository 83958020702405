import { Groupteam } from "../spgroupteam/types"
import { SportConfig } from "../spsportconfig/types"


/** 
 * Action types
 */
export enum  GrouppontuationTypes {
    //Load single
    LOAD_GROUPPONTUATION_REQUEST = '@grouppontuation/LOAD_GROUPPONTUATION_REQUEST',
    LOAD_GROUPPONTUATION_SUCCESS = '@grouppontuation/LOAD_GROUPPONTUATION_SUCCESS',
    LOAD_GROUPPONTUATION_FAILURE = '@grouppontuation/LOAD_GROUPPONTUATION_FAILURE',

    //Create
    CREATE_GROUPPONTUATION_REQUEST = '@grouppontuation/CREATE_GROUPPONTUATION_REQUEST',
    CREATE_GROUPPONTUATION_SUCCESS = '@grouppontuation/CREATE_GROUPPONTUATION_SUCCESS',
    CREATE_GROUPPONTUATION_FAILURE = '@grouppontuation/CREATE_GROUPPONTUATION_FAILURE',

    //Update
    UPDATE_GROUPPONTUATION_REQUEST = '@grouppontuation/UPDATE_GROUPPONTUATION_REQUEST',
    UPDATE_GROUPPONTUATION_SUCCESS = '@grouppontuation/UPDATE_GROUPPONTUATION_SUCCESS',
    UPDATE_GROUPPONTUATION_FAILURE = '@grouppontuation/UPDATE_GROUPPONTUATION_FAILURE',

    //Delete
    DELETE_GROUPPONTUATION_REQUEST = '@grouppontuation/DELETE_GROUPPONTUATION_REQUEST',
    DELETE_GROUPPONTUATION_SUCCESS = '@grouppontuation/DELETE_GROUPPONTUATION_SUCCESS',
    DELETE_GROUPPONTUATION_FAILURE = '@grouppontuation/DELETE_GROUPPONTUATION_FAILURE',
}

/**
 * Data types
 */
export interface Grouppontuation {
    id?: number

    sp_group_team_id?: number
    parentGroupteam?: Groupteam

    sp_sport_config_id?: number
    parentSportconfig?: SportConfig

    pontuation?: number
    manualorder?: number

    createdAt?: string,
    status?: string,
}

/**
 * State type
 */
export interface GrouppontuationState {
    readonly data: Grouppontuation[]
    readonly loading: boolean
    readonly error: boolean
}