import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    //Load
    loadAllClassificationRequest,
    loadAllClassificationSuccess,
    loadAllClassificationFailure,

    loadClassificationRequest,
    loadClassificationSuccess,
    loadClassificationFailure,

    //Create
    createClassificationRequest,
    createClassificationSuccess,
    createClassificationFailure,
    

    //Update
    updateClassificationRequest,
    updateClassificationSuccess,
    updateClassificationFailure,
    

    //Delete
    deleteClassificationRequest,
    deleteClassificationSuccess,
    deleteClassificationFailure
    

} from './actions';
import { Classification } from './types';

//Load
export function* loadAllClassifications() {
    try {
        const response : Classification[] = yield call(api.get, 'classification');
        yield put(loadAllClassificationSuccess(response));
    } catch (error:any) {
        yield put(loadAllClassificationFailure());
    }
}

//Load single
export function* loadClassifications(payload:ReturnType<typeof loadClassificationRequest>) {
    console.log("LOAD CLASSIFICATION")
    try {
        const response : Classification[] = yield call(api.get, 'classification/' + payload.payload.id + '/' + payload.payload.spDivisionId);
        yield put(loadClassificationSuccess(response));
    } catch (error) {
        yield put(loadClassificationFailure());
    }
}

//Create
export function* createClassification(payload:ReturnType<typeof createClassificationRequest>) {
    try {
        put(createClassificationRequest(payload.payload))
        const response : Classification = yield call(api.post, 'classification', payload.payload);
        yield put(createClassificationSuccess(response));
    } catch (error:any) {
        yield put(createClassificationFailure(error.response.message));
    }
}

//Update
export function* updateClassification(payload:ReturnType<typeof updateClassificationRequest>) {
    try {
        put(updateClassificationRequest(payload.payload))
        const response : Classification = yield call(api.patch, 'classification/'+payload.payload.id, payload.payload);
        yield put(updateClassificationSuccess(response));
    } catch (error:any) {
        console.log("ERRXX", error)
        yield put(updateClassificationFailure());
    }
}

//Delete
export function* deleteClassification(payload:ReturnType<typeof deleteClassificationRequest>) {
    try {
        put(deleteClassificationRequest())
        const response : Classification = yield call(api.delete, 'classification/'+payload);
        yield put(deleteClassificationSuccess(response));
    } catch (error) {
        yield put(deleteClassificationFailure());
    }
}
