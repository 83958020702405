import { Reducer } from 'redux';
import { AthleteState, AthleteTypes } from './types';
const INITIAL_STATE: AthleteState = {
    data: [],
    error: false,
    loading: false,
}

const reducer: Reducer<AthleteState, any> = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //Load single
        case AthleteTypes.LOAD_ATHLETE_REQUEST:
            return { ...state, loading: true }
        case AthleteTypes.LOAD_ATHLETE_SUCCESS:
            return { ...state, loading: false, error: false, data: action.payload.data } 
        case AthleteTypes.LOAD_ATHLETE_FAILURE:
            return { ...state, loading: false, error:true, data: [] }
        
        //Create
        case AthleteTypes.CREATE_ATHLETE_REQUEST:
            return { ...state }
        case AthleteTypes.CREATE_ATHLETE_SUCCESS:
            return { ...state, loading: false, error: false, data: [... state.data, action.payload.data]  }
        case AthleteTypes.CREATE_ATHLETE_FAILURE:
            return { ...state, loading: false, error:true, data: [] }

        //Update 
        case AthleteTypes.UPDATE_ATHLETE_REQUEST:
            return { ...state }
        case AthleteTypes.UPDATE_ATHLETE_SUCCESS:
            console.log("ACTION PAYLOAD VER", action.payload)
            return { 
                ...state,
                loading: false, 
                error: false, 
                data: state.data?.map(child=> child.id === action.payload.data.id ? action.payload.data : child ) 
            } //update data?
        case AthleteTypes.UPDATE_ATHLETE_FAILURE:
            return { ...state, loading: false, error:true }
        
        //Delete
        case AthleteTypes.DELETE_ATHLETE_REQUEST:
            return { ...state, loading: true }
        case AthleteTypes.DELETE_ATHLETE_SUCCESS:
            return { ...state, loading: false, error: false, data: state.data?.filter(item => item.id !== action.payload.data.id) } //só uma data: pq excluiu o user "Excluido com sucesso."
        case AthleteTypes.DELETE_ATHLETE_FAILURE:
            return { ...state, loading: false, error: true }
            
        default:
            return state;

    }
}

export default reducer;