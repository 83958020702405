import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    
    //Load single
    loadGroupteamRequest,
    loadGroupteamSuccess,
    loadGroupteamFailure,

    //Create
    createGroupteamRequest,
    createGroupteamSuccess,
    createGroupteamFailure,

    //Update
    updateGroupteamRequest,
    updateGroupteamSuccess,
    updateGroupteamFailure,

    //Delete
    deleteGroupteamRequest,
    deleteGroupteamSuccess,
    deleteGroupteamFailure
    

} from './actions';
import { Groupteam } from './types';

//Load

//Load single
export function* loadGroupteams(payload:ReturnType<typeof loadGroupteamRequest>) {
    console.log("LOAD SPORTS")
    try {
        const response : Groupteam[] = yield call(api.get, 'groupteam/'+payload.payload);
        yield put(loadGroupteamSuccess(response));
    } catch (error) {
        yield put(loadGroupteamFailure());
    }
}


//Create
export function* createGroupteam(payload:ReturnType<typeof createGroupteamRequest>) {
    try {
        put(createGroupteamRequest(payload.payload.groupTeamId, payload.payload.data))
        const response : Groupteam[] = yield call(api.post, 'groupteam/'+payload.payload.groupTeamId, payload.payload.data);
        yield put(createGroupteamSuccess(response));
    } catch (error:any) {
        console.log("ERR", error.response.data.message)
        yield put(createGroupteamFailure(error.response.message));
    }
}

//Update
export function* updateGroupteam(payload:ReturnType<typeof updateGroupteamRequest>) {
    try {
        put(updateGroupteamRequest(payload.payload))
        const response : Groupteam = yield call(api.patch, 'groupteam/'+payload.payload.id, payload.payload);
        yield put(updateGroupteamSuccess(response));
    } catch (error) {
        yield put(updateGroupteamFailure());
    }
}

//Delete
export function* deleteGroupteam(payload:ReturnType<typeof deleteGroupteamRequest>) {
    // console.log('TESTE', 'groupteam/'+payload.payload.team_id+'/'+payload.payload.group_id)
    try {
        // put(deleteGroupteamRequest(payload.payload))
        const response : Groupteam = yield call(api.delete, 'groupteam/'+payload.payload.team_id+'/'+payload.payload.group_id);
        // console.log('AAXXX','groupteam/'+payload.payload.team_id+'/'+payload.payload.group_id)
        yield put(deleteGroupteamSuccess(response));
    } catch (error) {
        console.log("ERRO", error)
        yield put(deleteGroupteamFailure());
    }
}
