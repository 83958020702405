import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 

    //Load 
    loadSportConfigRequest,
    loadSportConfigSuccess,
    loadSportConfigFailure,
    
    //Create
    createSportConfigRequest,
    createSportConfigSuccess,
    createSportConfigFailure,

    //Update
    updateSportConfigRequest,
    updateSportConfigSuccess,
    updateSportConfigFailure,

    //Delete
    deleteSportConfigRequest,
    deleteSportConfigSuccess,
    deleteSportConfigFailure

} from './actions';
import { SportConfig } from './types';

//Load single
export function* loadSportConfig(payload:ReturnType<typeof loadSportConfigRequest>) {
    console.log("LOAD SPORTS")
    try {
        const response : SportConfig[] = yield call(api.get, 'sportconfig/'+payload.payload);
        yield put(loadSportConfigSuccess(response));
    } catch (error) {
        yield put(loadSportConfigFailure());
    }
}

//Create
export function* createSportConfig(payload:ReturnType<typeof createSportConfigRequest>) {
    try {
        put(createSportConfigRequest(payload.payload))
        const response : SportConfig = yield call(api.post, 'sportconfig', payload.payload);
        yield put(createSportConfigSuccess(response));
    } catch (error:any) {
        yield put(createSportConfigFailure(error.response.message));
    }
}

//Update
export function* updateSportConfig(payload:ReturnType<typeof updateSportConfigRequest>) {
    try {
        put(updateSportConfigRequest(payload.payload))
        const response : SportConfig = yield call(api.patch, 'sportconfig/'+payload.payload.id, payload.payload);
        yield put(updateSportConfigSuccess(response));
    } catch (error) {
        yield put(updateSportConfigFailure());
    }
}

//Delete
export function* deleteSportConfig(payload:ReturnType<typeof deleteSportConfigRequest>) {
    try {
        put(deleteSportConfigRequest(payload.payload))
        const response : SportConfig = yield call(api.delete, 'sportconfig/'+payload.payload);
        yield put(deleteSportConfigSuccess(response));
    } catch (error) {
        yield put(deleteSportConfigFailure());
    }
}
