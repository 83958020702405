import clsx from "clsx";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

type Props = {
    className: string,
    id: string,
  }

const MenuChampionshipWidget: React.FC<Props> = ({className, id}) => {
    const history = useNavigate();
    return (
        <div className={`card ${className}`}>  
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Menu Esportivo</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Alou</span> */}
        </h3>
        
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <div className='fw-bolder text-muted'>


            <div className='menu-item'>
              <Link className={clsx('menu-link without-sub text-dark fw-bolder text-hover-primary fs-6', {active: true})} to={'/championship/'+id}>
                <span className='menu-title'>Início</span>
              </Link>
              <br/>
              <Link className={clsx('menu-link without-sub text-dark fw-bolder text-hover-primary fs-6', {active: true})} to={'/division/'+id}>
                <span className='menu-title'>Divisões</span>
              </Link>
              <Link className={clsx('menu-link without-sub text-dark fw-bolder text-hover-primary fs-6', {active: true})} to={'/delegation/'+id}>
                <span className='menu-title'>Equipes</span>
              </Link>
              <br/>
              
              
              <Link className={clsx('menu-link without-sub text-dark fw-bolder text-hover-primary fs-6', {active: true})} to={'/sport/'+id}>
                <span className='menu-title'>Modalidades</span>
              </Link>
              {/* <Link className={clsx('menu-link without-sub text-white', {active: true})} to={'/team/4'}>
                <span className='menu-title'>Atletas</span>
              </Link> */}
              {/* <Link className={clsx('menu-link without-sub text-dark fw-bolder text-hover-primary fs-6', {active: true})} to={'/manageathlete/'+id}>
                <span className='menu-title'>Atletas</span>
              </Link> */}
              <br/>
              <Link className={clsx('menu-link without-sub text-dark fw-bolder text-hover-primary fs-6', {active: true})} to={'/match/'+id}>
                <span className='menu-title'>Partidas</span>
              </Link>
              <br/>
              <Link className={clsx('menu-link without-sub text-dark fw-bolder text-hover-primary fs-6', {active: true})} to={'/classification/'+id}>
                <span className='menu-title'>Classificação Final</span>
              </Link>
              <Link className={clsx('menu-link without-sub text-dark fw-bolder text-hover-primary fs-6', {active: true})} to={'/medal/'+id}>
                <span className='menu-title'>Quadro de medalhas</span>
              </Link>
            </div>

           
            
            
          </div>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    )
}

export default MenuChampionshipWidget