import React, {FC, useEffect} from "react";
import {PageTitle} from '../../../design/layout/core'
import {useIntl} from 'react-intl'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { loadComponentRequest } from '../../../../store/ducks/component/actions';
import { ComponentState } from '../../../../store/ducks/component/types'
import Loading from '../../../loading'

import {
    ManageItemWidget,
} from './ManageItemWidget'
import { ManageItemExtraWidget } from './ManageItemExtraWidget' 

interface ParamTypes {
  id: string
}

const MOMENT= require( 'moment' );

type Props = {
  comp: ComponentState
}

const ManagePage: React.FC<Props> = ({comp}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      <div className='col-xxl-12'>
        <ManageItemWidget comp={comp} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      {/* <div className='col-xxl-5'>
        <ManageItemExtraWidget comp={comp} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div> */}
    </div>
    {/* end::Row */}
  </>
)

const ManageChampionships: FC = () => {
    const intl = useIntl()

    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);
    console.log("COMPONENTEXXXXXXXXXXXXXXX", component)
    let { id } = useParams();

    useEffect(() => {
        dispatch(loadComponentRequest(id!, 'asc')); //Puxa componentes com seus filhos primários
    }, [id]);

    if(component.loading && component.data)
        return <Loading/>

    return ( 
      <>
        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
        <PageTitle breadcrumbs={[]}>{component.data.name}</PageTitle>
        <ManagePage comp={component} />
      </> 
    )
}   
export default ManageChampionships

