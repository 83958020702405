import React, {FC, useEffect, useState} from "react";

import {useIntl} from 'react-intl'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { loadComponentRequest } from '../../../../../store/ducks/component/actions';
import Loading from '../../../../loading'

import MenuChampionshipWidget from "../MenuChampionshipWidget";
import { loadDivisionsRequest } from "../../../../../store/ducks/spdivision/actions";
import { ManageSportWidget } from "./ManageSportWidget";
import { DivisionState } from "../../../../../store/ducks/spdivision/types";
import { loadSportsRequest } from "../../../../../store/ducks/spsport/actions";
import { SportState } from "../../../../../store/ducks/spsport/types";
import { PageTitle } from "../../../../design/layout/core";

interface ParamTypes {
  id: string
}

const MOMENT= require( 'moment' );

type Props = {
  sports: SportState
  divisions: DivisionState
  id: string
  selectedOption: string
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
}

const ManagePage: React.FC<Props> = ({sports, id, divisions, setSelectedOption, selectedOption}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      
      <div className='col-xxl-2'>
        <MenuChampionshipWidget id={id!} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      <div className='col-xxl-10'>
        <ManageSportWidget sports={sports} divisions={divisions} setSelectedOption={setSelectedOption} selectedOption={selectedOption} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const Sport: FC = () => {
    const intl = useIntl()
    const [selectedOption, setSelectedOption] = useState('');

    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);
    const sports = useSelector((state: ApplicationState) => state.sports);
    const divisions = useSelector((state: ApplicationState) => state.divisions);
    
    console.log('Sports', sports)
    let { id } = useParams();

    useEffect(() => {
        dispatch(loadComponentRequest(id!,'SORT_ASC')); //Puxa componentes com seus filhos primários
        dispatch(loadDivisionsRequest(+id!)); //Puxa componentes com seus filhos primários
        if(selectedOption)
          dispatch(loadSportsRequest(+selectedOption)); //Puxa componentes com seus filhos primários

    }, [id, selectedOption]);

    if(component.loading && component.data)
        return <Loading/>

    if(divisions.loading)
        return <Loading/>

    return ( 
      <>
        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
        <PageTitle breadcrumbs={[]}>{component.data.name}</PageTitle>
        <ManagePage id={id!} sports={sports} divisions={divisions} setSelectedOption={setSelectedOption} selectedOption={selectedOption} />

      </> 
    )
}   
export default Sport

