import React, {FC, useEffect, useState} from "react";

import {useIntl} from 'react-intl'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../../store';
import { loadComponentRequest } from '../../../../../../store/ducks/component/actions';
import Loading from '../../../../../loading'

import MenuChampionshipWidget from "../../MenuChampionshipWidget";
import { ManageSubTeamWidget } from "./ManageSubTeamWidget";
import { loadAthleteTeamByTeamRequest } from "../../../../../../store/ducks/spathleteteam/actions";
import { AthleteTeamState } from "../../../../../../store/ducks/spathleteteam/types";
import { PageTitle } from "../../../../../design/layout/core";

interface ParamTypes {
  id: string
  team_id: string
}

const MOMENT= require( 'moment' );

type Props = {
  athleteteam: AthleteTeamState
  id: string
}

const ManagePage: React.FC<Props> = ({athleteteam, id}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      
      <div className='col-xxl-2'>
        <MenuChampionshipWidget id={id!} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      <div className='col-xxl-10'>
        <ManageSubTeamWidget athleteteam={athleteteam} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const SubTeam: FC = () => {
    const intl = useIntl()

    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);
    const athleteteam = useSelector((state: ApplicationState) => state.athleteteam);
    const divisions = useSelector((state: ApplicationState) => state.divisions);
    
    let { id, team_id } = useParams();
    console.log('team_id', team_id)
    console.log('athleteteam', athleteteam)
    

    useEffect(() => {

        dispatch(loadComponentRequest(id!, 'asc')); //Puxa componentes com seus filhos primários
        dispatch(loadAthleteTeamByTeamRequest(+team_id!)); //Puxa componentes com seus filhos primários

    }, [id, team_id]);


    if(component.loading && component.data)
        return <Loading/>

    if(divisions.loading)
        return <Loading/>

    return ( 
      <>
        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
        <PageTitle breadcrumbs={[]}>{component.data.name}</PageTitle>
        <ManagePage id={id!} athleteteam={athleteteam} />

      </> 
    )
}   
export default SubTeam

