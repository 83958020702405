import { Sport } from "../spsport/types"

/** 
 * Action types
 */
export enum  GroupsTypes {

    //Load single
    LOAD_GROUP_REQUEST = '@group/LOAD_GROUP_REQUEST',
    LOAD_GROUP_SUCCESS = '@group/LOAD_GROUP_SUCCESS',
    LOAD_GROUP_FAILURE = '@group/LOAD_GROUP_FAILURE',

    //Create
    CREATE_GROUP_REQUEST = '@group/CREATE_GROUP_REQUEST',
    CREATE_GROUP_SUCCESS = '@group/CREATE_GROUP_SUCCESS',
    CREATE_GROUP_FAILURE = '@group/CREATE_GROUP_FAILURE',

    //Update
    UPDATE_GROUP_REQUEST = '@group/UPDATE_GROUP_REQUEST',
    UPDATE_GROUP_SUCCESS = '@group/UPDATE_GROUP_SUCCESS',
    UPDATE_GROUP_FAILURE = '@group/UPDATE_GROUP_FAILURE',

    //Delete
    DELETE_GROUP_REQUEST = '@group/DELETE_GROUP_REQUEST',
    DELETE_GROUP_SUCCESS = '@group/DELETE_GROUP_SUCCESS',
    DELETE_GROUP_FAILURE = '@group/DELETE_GROUP_FAILURE',
}

/**
 * Data types
 */
export interface Group {
    id?: number
    spSportId?: number
    parentSport?: Sport
    name?: string
    description?: string
    message?: string
    createdAt?: string,
    status?: string,
}

/**
 * State type
 */
export interface GroupState {
    readonly data: Group[]
    readonly loading: boolean
    readonly error: boolean
}