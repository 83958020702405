/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Link, useParams } from 'react-router-dom'
import { Component, ComponentState } from '../../../../../../store/ducks/component/types'
import { KTSVG } from '../../../../../design/helpers';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import Create from './create'
import Update from './update'
import { DivisionState } from '../../../../../../store/ducks/spdivision/types'
import { Sport, SportState } from '../../../../../../store/ducks/spsport/types'
import { deleteSportRequest } from '../../../../../../store/ducks/spsport/actions'
import { AthleteTeam, AthleteTeamState } from '../../../../../../store/ducks/spathleteteam/types'
import { deleteAthleteTeamRequest } from '../../../../../../store/ducks/spathleteteam/actions'
import { Athlete, AthleteState } from '../../../../../../store/ducks/spathlete/types'
import { deleteAthleteRequest } from '../../../../../../store/ducks/spathlete/actions'
// import SelectTeam from './team/Team'
// import Configuration from './configuration/Configuration'
// import Classification from './classification/Classification'

interface ParamTypes {
  id: string
  athlete_team_id: string
}

type Props = {
  className: string
  athlete: AthleteState
}

const ManageAthleteWidget: React.FC<Props> = ({className, athlete}) => {
  // console.log("COMP AQUI", divisions)
  let { id, athlete_team_id } = useParams();
  const [show, setShow] = useState<boolean>(false);
  const [showTeams, setShowTeams] = useState<boolean>(false);
  const [showConfig, setShowConfig] = useState<boolean>(false);
  const [showClassification, setShowClassification] = useState<boolean>(false);
  
  const [sportSelected, setSportSelected] = useState<Sport>({});

  const [action, setAction] = useState<string>("");
  const [child, setChild] = useState<Sport>({});
 
  const dispatch = useDispatch();
  const history = useNavigate();

  const createTeam = (child: Sport) => {
    console.log("SELECIONEI O SPORT", child)
    setSportSelected(child)
    setShowTeams(true);
  }

  const handleCloseTeams = () => {
    setShowTeams(false);
  }

  const createConfig = (child: Sport) => {
    console.log("SELECIONEI O SPORT", child)
    setSportSelected(child)
    setShowConfig(true);
  }

  const handleCloseConfig = () => {
    setShowConfig(false);
  }

  const createClassification = (child: Sport) => {
    console.log("SELECIONEI O SPORT", child)
    setSportSelected(child)
    setShowClassification(true);
  }

  const handleCloseClassification = () => {
    setShowClassification(false);
  }

 
  /////////////////////////////////

  const createComponent = () => {
      setAction('createComponent')
      setShow(true);
  }

  const updateComponent = (child: Sport) => {
      setAction('updateComponent')
      setShow(true);
      setChild(child)
  }

  const handleClose = () => {
    setShow(false);
  }

  // Deleta componente: CHILD
  const deleteAthlete = (athlete: Athlete) => {
      dispatch(deleteAthleteRequest(athlete.id!))
  }

  return (
    <>
    <Modal size={'lg'} centered show={show} onHide={handleClose}>
      <Modal.Header closeButton placeholder={'Hey'}>
      <Modal.Title>
          { (action === 'createComponent')?'Adicionar Atleta':'' }
          { (action === 'updateComponent')?'Editar Atleta':'' }
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          { (action === 'createComponent')?<Create handleClose={handleClose} athlete_team_id={athlete_team_id!} />:'' }
          { (action === 'updateComponent')?<Update handleClose={handleClose} child={child} />:'' }
      </Modal.Body>
    </Modal>


    
    <div className={`card ${className}`}>  
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Atletas</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Gerenciador de Atletas em Sub Times</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            onClick={() => createComponent()}
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Adicionar atleta
          </a>
          
        </div>
      </div>

      

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}

        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Atleta</th>
                {/* <th className='min-w-140px'>Descrição</th> */}
                {/* <th className='min-w-140px'>Atletas</th> */}
                {/* <th className='min-w-120px'>Progress</th> */}
                <th className='min-w-100px text-end'>Ações</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {athlete.data.map((child: any, index) => {
                return(
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <div className='text-dark fw-bolder fs-6'>
                            {child.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {child.description}
                      </span>
                    </td> */}
                    {/* <td>
                      {/* <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        Intertico
                      </a> *x/}
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                      
                        
                        <Link to={'/group/' + id + "/" + child.id } style={{display:'flex' }} className='text-dark fw-bolder text-hover-primary fs-6'>
                          Gerenciar atletas
                        </Link>

                      
                      </span>
                    </td> */}
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          href='#'
                          onClick={() => updateComponent(child)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                        </a>
                        <a
                          href='#'
                          onClick={() => { if (window.confirm('Deseja realmente excluir: ' + child.name + '?')) deleteAthlete(child)  } }
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                  )
                }
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

    </>
  )
}

export {ManageAthleteWidget}