import { action } from 'typesafe-actions';
import { GroupsTypes, Group } from './types'


//Load single
export const loadGroupsRequest = (id: number) => action(GroupsTypes.LOAD_GROUP_REQUEST, id) 
export const loadGroupsSuccess = (data: Group[]) => action(GroupsTypes.LOAD_GROUP_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadGroupsFailure = () => action(GroupsTypes.LOAD_GROUP_FAILURE) 

//Create
export const createGroupRequest = (data: Group) => action(GroupsTypes.CREATE_GROUP_REQUEST, data)
export const createGroupSuccess = (data: Group) => action(GroupsTypes.CREATE_GROUP_SUCCESS, data)
export const createGroupFailure = (error: {}) => action(GroupsTypes.CREATE_GROUP_FAILURE, error)

//Update
export const updateGroupRequest = (data: Group) => action(GroupsTypes.UPDATE_GROUP_REQUEST, data) 
export const updateGroupSuccess = (data: Group) => action(GroupsTypes.UPDATE_GROUP_SUCCESS, data)
export const updateGroupFailure = () => action(GroupsTypes.UPDATE_GROUP_FAILURE) 

//Delete
export const deleteGroupRequest = (id: number) => action(GroupsTypes.DELETE_GROUP_REQUEST, id) 
export const deleteGroupSuccess = (data: Group) => action(GroupsTypes.DELETE_GROUP_SUCCESS, data)
export const deleteGroupFailure = () => action(GroupsTypes.DELETE_GROUP_FAILURE) 
