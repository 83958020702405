import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap-v5';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { updateComponentRequest } from '../../../../store/ducks/component/actions';

import { Component } from '../../../../store/ducks/component/types'

interface handleCloseProps {
    handleClose: () => void
    child: Component
}

const Update = ({handleClose, child}:handleCloseProps) => {
    
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const component = useSelector((state: ApplicationState) => state.component);
    const [name, setName] = useState<string | undefined>("");
    const [description, setDescription] = useState<string | undefined>("");
    const [order, setOrder] = useState<number | undefined>(0);
    
    useEffect(() => {
        setName(child.name)
        setDescription(child.description)
        setOrder(parseInt(child.order!))
    }, [])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        console.log("submit", component.data.id)
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        if(name && description){
            
            // var data = new Date(); 
            const componentToUpdate:Component = { 
                id: child.id,
                name,
                description,
                order: order?.toString(),
                //// createdAt: (data.getTime()/1000).toString(), //updatedAt
                status: '1',
            };

            console.log("------------------ COMPONENT TO UPDATE", componentToUpdate)
            dispatch(updateComponentRequest(componentToUpdate))
            handleClose()
        }
    }

    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}> 
                <Form.Group controlId="fromName">
                    <Form.Label>Nome do componente</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                        name="name"
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome do produto
                    </Form.Control.Feedback>
                </Form.Group>
                <br/>
                <Form.Group controlId="formDescription">
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control 
                        placeholder=""
                        required
                        value={description} 
                        onChange={e => setDescription(e.target.value)} 
                        as="textarea" rows={3}
                        name="description"
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe a descrição do produto
                    </Form.Control.Feedback>
                </Form.Group>
                <br/>
                <Form.Group controlId="fromName">
                    <Form.Label>Ordem</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        value={order} 
                        onChange={e => setOrder(+e.target.value)} 
                        name="order"
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe a ordem
                    </Form.Control.Feedback>
                </Form.Group>
                <br/>
                <Button variant="primary" type="submit" className="float-right" >
                    Salvar
                </Button> 
            </Form>
        </>
    );
}
export default Update;
