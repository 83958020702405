import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { ApplicationState } from '../../../../../../store';
import { createAthleteRequest } from '../../../../../../store/ducks/spathlete/actions';
import { Athlete } from '../../../../../../store/ducks/spathlete/types';
import { createAthleteTeamRequest } from '../../../../../../store/ducks/spathleteteam/actions';
import { AthleteTeam } from '../../../../../../store/ducks/spathleteteam/types';
import { createDivisionRequest } from '../../../../../../store/ducks/spdivision/actions';
import { Division } from '../../../../../../store/ducks/spdivision/types';
import { createSportRequest } from '../../../../../../store/ducks/spsport/actions';
import { Sport } from '../../../../../../store/ducks/spsport/types';
// import { Modal } from 'react-bootstrap'
interface ParamTypes {
    id: string
}

interface handleCloseProps {
    handleClose: () => void;
    athlete_team_id: string;
}


const Create = ({handleClose, athlete_team_id}: handleCloseProps) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [order, setOrder] = useState('');
    const [type, setType] = useState('');

    const [validated, setValidated] = useState(false);
    const {id} = useParams();
    // const history = useNavigate();
    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);

    console.log('Component inside - create', component)
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            
            event.stopPropagation();
        }
        setValidated(true);
        
        if(name){
            // var data = new Date(); 
            const component:Athlete = { 
                name,
                // spTeamId: +team_id,
                spAthleteTeamId: +athlete_team_id,
                // createdAt: (data.getTime()/1000).toString(),
                status: "1",
            };
            console.log('component to save:', component)
            dispatch(createAthleteRequest(component))
            handleClose();
            // history.goBack()
        }
    }
    
    return (
        <>
            <Form validated={validated} onSubmit={handleSubmit}> 
                <Form.Group controlId="fromName">
                    <Form.Label>Nome do atleta</Form.Label>
                    <Form.Control 
                        placeholder="" 
                        required  
                        value={name} 
                        onChange={e => setName(e.target.value)} 
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor informe o nome
                    </Form.Control.Feedback>
                </Form.Group>
                <br/>
                <Button size="sm" variant="primary" type="submit" className="float-right">
                    Salvar
                </Button> 
                
                
            </Form>
            {/* Deixar o button fora do form.. */}
        </>
    );
}
export default Create;
