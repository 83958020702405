import React, {FC, useEffect, useState} from "react";
import { PageTitle } from "../../../../../design/layout/core";
import {useIntl} from 'react-intl'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../../store';
import { loadComponentRequest } from '../../../../../../store/ducks/component/actions';
import Loading from '../../../../../loading'

import MenuChampionshipWidget from "../../MenuChampionshipWidget";
import { ManageAthleteWidget } from "./ManageAthleteWidget";
import { loadAthleteRequest } from "../../../../../../store/ducks/spathlete/actions";
import { AthleteState } from "../../../../../../store/ducks/spathlete/types";

interface ParamTypes {
  id: string
  athlete_team_id: string
}

const MOMENT= require( 'moment' );

type Props = {
  athlete: AthleteState
  id: string
}

const ManagePage: React.FC<Props> = ({athlete, id}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      
      <div className='col-xxl-2'>
        <MenuChampionshipWidget id={id!} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      <div className='col-xxl-10'>
        <ManageAthleteWidget athlete={athlete} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const Athlete: FC = () => {
    const intl = useIntl()
    const [selectedOption, setSelectedOption] = useState('');

    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);
    const athlete = useSelector((state: ApplicationState) => state.athlete);
    const divisions = useSelector((state: ApplicationState) => state.divisions);
    
    let { id, athlete_team_id } = useParams();
    console.log('athlete_team_id', athlete_team_id)
    console.log('athlete', athlete)
    

    useEffect(() => {
        dispatch(loadComponentRequest(id!, 'asc')); //Puxa componentes com seus filhos primários

        dispatch(loadAthleteRequest(+athlete_team_id!)); //Puxa componentes com seus filhos primários
        
          // dispatch(loadAthleteTeamRequest(+id)); //Puxa componentes com seus filhos primários

    }, [id, selectedOption]);


    if(component.loading && component.data)
        return <Loading/>

    if(divisions.loading)
        return <Loading/>

    return ( 
      <>
        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
        <PageTitle breadcrumbs={[]}>{component.data.name}</PageTitle>
        <ManagePage id={id!} athlete={athlete} />

      </> 
    )
}   
export default Athlete

