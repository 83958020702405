import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { ApplicationState } from '../../../../../../store';
import { updateSportRequest } from '../../../../../../store/ducks/spsport/actions';

import { Sport } from '../../../../../../store/ducks/spsport/types';
import { SportConfig } from '../../../../../../store/ducks/spsportconfig/types';
import { createSportConfigRequest, deleteSportConfigRequest, loadSportConfigRequest } from '../../../../../../store/ducks/spsportconfig/actions';


interface ParamTypes {
    id: string
}

interface handleCloseProps {
    handleClose: () => void
    sportSelected: Sport
}

const Configuration = ({handleClose, sportSelected}: handleCloseProps) => {

    //const {id} = useParams();
    const [validated, setValidated] = useState(false);

    const [type, setType] = useState('');
    const [sets, setSets] = useState(0);
    const [penaltis, setPenaltis] = useState(0);
    const [fasegrupo, setFaseGrupo] = useState(0);

    const [inputList, setInputList] = useState<SportConfig[]>([{ id:0, type: "", prop: "", value: "" }]);

    // handle input change
    const handleInputChange = (e:any, index:number) => {
        const { name, value } = e.target;
        const list:any = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    
    // handle click event of the Remove button
    const handleRemoveClick = (index:any) => {
        console.log("INDEX to remove", index)
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    
    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { type: inputList[inputList.length - 1].type, prop: "", value: "" }]);
    };

    const setConfigItems = (list: SportConfig[]) => {
        // console.log('setConfigItems', list)
        let initialList:any= [];
        list.map(spconfig => {
            
            let item = { id: ""+spconfig.id!, type: spconfig.type!, prop: spconfig.prop!, value: spconfig.value! }
            //let item = { type: spconfig.type!, prop: spconfig.prop!, value: spconfig.value! }
            initialList.push(item)
        })
        initialList.push({ type: "", prop: "", value: "" })
        setInputList(initialList);
        
    }
    const sportconfig = useSelector((state: ApplicationState) => state.sportconfig);
    
    useEffect(() => {
        //Itens para modalidade:
        setType(sportSelected.type!)
        setSets(+sportSelected.sets!)
        setPenaltis(+sportSelected.penaltis!)
        setFaseGrupo(+sportSelected.fasegrupo!)

        //SportConfig
        dispatch(loadSportConfigRequest(sportSelected.id!))
        setConfigItems(sportconfig.data)

    }, [sportSelected, sportconfig.data.length]);

    const dispatch = useDispatch();
   
    console.log("SELETECTED ", sportSelected)
    console.log("INPUTLIST ", inputList)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
        let sportToUpdate: Sport = {
            id: sportSelected.id,
            name: sportSelected.name,
            type,
            sets,
            penaltis,
            fasegrupo,
            childrenSportConfig: inputList
        }
        dispatch(updateSportRequest(sportToUpdate))
        handleClose();
    }

    if(sportconfig.loading){
        return <div>Loading</div>
    }

    
    // let sportConfigItems:any = [];
    let configItems = [
        { id: 0, name: 'PontuacaoVitoria' },
        { id: 1, name: 'PontuacaoEmpate' },
        { id: 2, name: 'PontuacaoDerrota' },
        { id: 3, name: 'PlacarVitoriaCondicional' },
        { id: 4, name: 'PlacarDerrotaCondicional' },
        { id: 5, name: 'PontuacaoVitoriaCondicional' },
        { id: 6, name: 'PontuacaoDerrotaCondicional' },
    ]
 
    return (
        <>
            <h2>{/*sportSelected.id*/} {sportSelected.name} {sportSelected.parentDivision?.name? "(" + sportSelected.parentDivision?.name + ")":'' }</h2>
            <Form validated={validated} onSubmit={handleSubmit}> 
                {/* Tipo: {type} <br/>
                SEts: {sets} <br/>
                PENALTIS: {penaltis} <br/>
                FGrupo: {fasegrupo} <br/><br/>

                Coletiva: {"Coletiva" === type?'sim':'nao'}<br/>
                Individual: {"Individual" === type?'sim':'nao'}<br/>
                DS: {"Coletiva/Dupla/Simples" === type?'sim':'nao'}<br/><br/>

                {1 === sets?'sim':'nao'}<br/>
                {1 === penaltis?'sim':'nao'}<br/>
                {1 === fasegrupo?'sim':'nao'}<br/><br/> */}

                Tipo da modalidade: <br/><br/>
                <Form.Group className="mb-3" controlId="A">
                    {/* <Form.Check name={'check[]'} id={"Coletiva"} type="radio" label={"Coletiva"} checked={type === "Coletiva"} onChange={()=>setType("Coletiva")} style={{paddingBottom: 5}} /> */}
                    <Form.Check name={'check[]'} id={"Coletiva/Dupla/Simples"} type="radio" label={"Coletiva/Dupla/Simples"} checked={type === "Coletiva/Dupla/Simples"} onChange={()=>setType("Coletiva/Dupla/Simples")} style={{paddingBottom: 5}} />
                    <Form.Check name={'check[]'} id={"Individual"} type="radio" label={"Individual"} checked={type === "Individual"} onChange={()=>setType("Individual")} style={{paddingBottom: 5}} />
                </Form.Group>

                Específico: <br/><br/>
                <Form.Group className="mb-3" controlId="B">
                    <Form.Check name={'checkB'} id={"testeB"} type="checkbox" label={"Tem sets?"} checked={1 === sets} onChange={()=>setSets(sets===0?1:0)} style={{paddingBottom: 5}} />
                    <Form.Check name={'checkC'} id={"testeB"} type="checkbox" label={"Tem pênaltis"} checked={1 === penaltis} onChange={()=>setPenaltis(penaltis===0?1:0)} style={{paddingBottom: 5}} />
                </Form.Group> 
                
                <br/>
                Fase de grupos: <br/><br/>
                <Form.Group className="mb-3" controlId="C">
                    <Form.Check name={'checkD'} id={"testex"} type="checkbox" label={"Tem Fase de grupos?"} checked={1 === fasegrupo} onChange={()=>setFaseGrupo(fasegrupo===0?1:0)} style={{paddingBottom: 5}} />
                </Form.Group>
                <br/>


                <div className="App">
                    {/* <h3><a href="https://cluemediator.com">Clue Mediator</a></h3> */}
                    {inputList.map((x, i) => {
                        return (
                            <div key={i}>
                                <div className='row'>
                                    <div className='col-3'>
                                        <Form.Group>
                                            <Form.Control 
                                                placeholder='Tipo'
                                                name="type"
                                                value={x.type}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className='col-4'>
                                        {/* <Form.Group>
                                            <Form.Control 
                                                placeholder='Prop'
                                                name="prop"
                                                value={x.prop}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </Form.Group> */}
                                        <select className="selectpicker form-control" name="prop" onChange={(e)=>handleInputChange(e,i)}>
                                            <option value="" selected disabled hidden>Selecione um item</option>
                                            {configItems.map((config, index) => {
                                                return <option key={index} selected={(config.name === x.prop)?true:false} value={config.name}>{config.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className='col-3'>
                                        <Form.Group>
                                            <Form.Control 
                                                placeholder='Value'
                                                name="value" 
                                                value={x.value}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </Form.Group>
                                    </div>
                                    
                                    <div className="col-1">
                                        {inputList.length !== 1 &&  <Button size="sm" variant="primary" className="float-right" onClick={() => handleRemoveClick(i)}>
                                            X
                                        </Button> 

                                        } <br/><br/>
                                        {inputList.length - 1 === i && <Button size="sm" variant="primary" className="float-right" onClick={handleAddClick}>
                                            +
                                        </Button>}
                                    </div>
                                </div>
                                <br/>
                            </div>
                        );
                    })}
                    {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                    </div>

    
                
                <Button size="sm" variant="primary" type="submit" className="float-right">
                    Salvar
                </Button> 
            </Form>
        </>
    );
}
export default Configuration;
