import { AthleteTeam } from "../spathleteteam/types"
import { Group } from "../spgroup/types"
import { Team } from "../spteam/types"

/** 
 * Action types
 */
export enum  GroupteamTypes {

    //Load single
    LOAD_GROUPTEAM_REQUEST = '@groupteam/LOAD_GROUPTEAM_REQUEST',
    LOAD_GROUPTEAM_SUCCESS = '@groupteam/LOAD_GROUPTEAM_SUCCESS',
    LOAD_GROUPTEAM_FAILURE = '@groupteam/LOAD_GROUPTEAM_FAILURE',

     //Create
    CREATE_GROUPTEAM_REQUEST = '@groupteam/CREATE_GROUPTEAM_REQUEST',
    CREATE_GROUPTEAM_SUCCESS = '@groupteam/CREATE_GROUPTEAM_SUCCESS',
    CREATE_GROUPTEAM_FAILURE = '@groupteam/CREATE_GROUPTEAM_FAILURE',

    //Update
    UPDATE_GROUPTEAM_REQUEST = '@groupteam/UPDATE_GROUPTEAM_REQUEST',
    UPDATE_GROUPTEAM_SUCCESS = '@groupteam/UPDATE_GROUPTEAM_SUCCESS',
    UPDATE_GROUPTEAM_FAILURE = '@groupteam/UPDATE_GROUPTEAM_FAILURE',

    //Delete
    DELETE_GROUPTEAM_REQUEST = '@groupteam/DELETE_GROUPTEAM_REQUEST',
    DELETE_GROUPTEAM_SUCCESS = '@groupteam/DELETE_GROUPTEAM_SUCCESS',
    DELETE_GROUPTEAM_FAILURE = '@groupteam/DELETE_GROUPTEAM_FAILURE',
}

/**
 * Data types
 */
export interface Groupteam {
    id?: number

    spAthleteTeamId?: number
    parentAthleteTeam?: AthleteTeam

    spGroupId?: number
    parentGroup?: Group

    manualorder?: number
    jogos?: number
    pontuacao?: number
    vitorias?: number
    empates?: number
    derrotas?: number
    vitoriascondicionais?: number
    derrotascondicionais?: number
    geralfeito?: number
    geraltomado?: number
    parcialfeito?: number
    parcialtomado?: number
    saldogeral?: number
    saldoparcial?: number
    geralaverage?: number
    parcialaverage?: number

    createdAt?: string
    status?: string
}

/**
 * State type
 */
export interface GroupteamState {
    readonly data: Groupteam[]
    readonly loading: boolean
    readonly error: boolean
}