import { action } from 'typesafe-actions';
import { TeamsTypes, Team } from './types'

//Load single
export const loadTeamsRequest = (id: number) => action(TeamsTypes.LOAD_TEAMS_REQUEST, id) 
export const loadTeamsSuccess = (data: Team[]) => action(TeamsTypes.LOAD_TEAMS_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadTeamsFailure = () => action(TeamsTypes.LOAD_TEAMS_FAILURE) 

//Load teams by division
export const loadTeamsByDivisionRequest = (id: number) => action(TeamsTypes.LOAD_TEAMSBYDIVISION_REQUEST, id) 
export const loadTeamsByDivisionSuccess = (data: Team[]) => action(TeamsTypes.LOAD_TEAMSBYDIVISION_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadTeamsByDivisionFailure = () => action(TeamsTypes.LOAD_TEAMSBYDIVISION_FAILURE) 

//Create
export const createTeamRequest = (sport_id: number, data: Team[]) => action(TeamsTypes.CREATE_TEAM_REQUEST, {sport_id, data})
export const createTeamSuccess = (data: Team[]) => action(TeamsTypes.CREATE_TEAM_SUCCESS, data)
export const createTeamFailure = (error: {}) => action(TeamsTypes.CREATE_TEAM_FAILURE, error)

//Update
export const updateTeamRequest = (teamToUpdate: Team) => action(TeamsTypes.UPDATE_TEAM_REQUEST, teamToUpdate) 
export const updateTeamSuccess = (data: Team) => action(TeamsTypes.UPDATE_TEAM_SUCCESS, data)
export const updateTeamFailure = () => action(TeamsTypes.UPDATE_TEAM_FAILURE) 

//Delete
export const deleteTeamRequest = (delegation_id: number, sport_id: number) => action(TeamsTypes.DELETE_TEAM_REQUEST, {delegation_id, sport_id}) 
export const deleteTeamSuccess = () => action(TeamsTypes.DELETE_TEAM_SUCCESS)
export const deleteTeamFailure = () => action(TeamsTypes.DELETE_TEAM_FAILURE) 
