import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    
    //Load single
    loadAthleteRequest,
    loadAthleteSuccess,
    loadAthleteFailure,

    //Create
    createAthleteRequest,
    createAthleteSuccess,
    createAthleteFailure,

    //Update
    updateAthleteRequest,
    updateAthleteSuccess,
    updateAthleteFailure,

    //Delete
    deleteAthleteRequest,
    deleteAthleteSuccess,
    deleteAthleteFailure,

} from './actions';
import { Athlete } from './types';

//Load single
export function* loadAthlete(payload:ReturnType<typeof loadAthleteRequest>) {
    console.log("LOEAD TEAMS")
    try {
        const response : Athlete[] = yield call(api.get, 'athlete/'+payload.payload);
        yield put(loadAthleteSuccess(response));
    } catch (error) {
        yield put(loadAthleteFailure());
    }
}

//Create
export function* createAthlete(payload:ReturnType<typeof createAthleteRequest>) {
    try {
        put(createAthleteRequest(payload.payload))
        const response : Athlete = yield call(api.post, 'athlete', payload.payload);
        yield put(createAthleteSuccess(response));
    } catch (error:any) {
        yield put(createAthleteFailure(error.response.message));
    }
}

//Update
export function* updateAthlete(payload:ReturnType<typeof updateAthleteRequest>) {
    try {
        put(updateAthleteRequest(payload.payload))
        const response : Athlete = yield call(api.patch, 'athlete/'+payload.payload.id, payload.payload);
        yield put(updateAthleteSuccess(response));
    } catch (error) {
        yield put(updateAthleteFailure());
    }
}

//Delete
export function* deleteAthlete(payload:ReturnType<typeof deleteAthleteRequest>) {
    try {
        //put(deleteTeamRequest(payload.payload.delegation_id, payload.payload.sport_id))
        const response : Athlete = yield call(api.delete, 'athlete/'+payload.payload);
        yield put(deleteAthleteSuccess(response));
    } catch (error) {
        yield put(deleteAthleteFailure());
    }
}
