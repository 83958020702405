import {Match, MatchState, Result} from '../../../../../../store/ducks/spmatch/types'
import {Partial} from '../../../../../../store/ducks/sppartial/types'
import {SportConfig} from '../../../../../../store/ducks/spsportconfig/types'
import verifyConditionalScore from './group/aVerifyConditionalScore'
import _ from 'lodash'
import createGroupPontuation from './group/bCreateGroupPontuation'
import createBigPontuation from './group/cCreateBigPontuation'
import unsetupNextGame from './playoff/aUnsetupNextGame'
import setupNextGame from './playoff/aSetupNextGame'

const jobPlayoff = (match:Match, matches: MatchState, inputList: Result[]) => {
  let resultTeamA = inputList[0].pontuation
  let resultTeamB = inputList[1].pontuation

  // console.log ("RESULTADOS", {resultTeamA, resultTeamB})
  if (
    (resultTeamA == null || resultTeamA == '' || resultTeamA == undefined || resultTeamA == '') &&
    (resultTeamB == null ||
      resultTeamB == '' ||
      resultTeamB == undefined ||
      (resultTeamB == '') == null)
  ) {
    //desmontaProximoJogo(match)

    console.log("#####DESMONTA PROXIMO JOGO")
    return unsetupNextGame(match,matches,inputList)

  } else {
    //montaProximoJogo(match)
    console.log("#######MONTA PROXIMO JOGO")
    return setupNextGame(match,matches,inputList)
  }
}

export default jobPlayoff
