import { Component } from "../component/types"

/** 
 * Action types
 */
export enum  DivisionsTypes {
    //Load all
    LOAD_ALLDIVISIONS_REQUEST = '@division/LOAD_ALLDIVISIONS_REQUEST',
    LOAD_ALLDIVISIONS_SUCCESS = '@division/LOAD_ALLDIVISIONS_SUCCESS',
    LOAD_ALLDIVISIONS_FAILURE = '@division/LOAD_ALLDIVISIONS_FAILURE',

    //Load single
    LOAD_DIVISION_REQUEST = '@division/LOAD_DIVISION_REQUEST',
    LOAD_DIVISION_SUCCESS = '@division/LOAD_DIVISION_SUCCESS',
    LOAD_DIVISION_FAILURE = '@division/LOAD_DIVISION_FAILURE',

    //Create
    CREATE_DIVISION_REQUEST = '@division/CREATE_DIVISION_REQUEST',
    CREATE_DIVISION_SUCCESS = '@division/CREATE_DIVISION_SUCCESS',
    CREATE_DIVISION_FAILURE = '@division/CREATE_DIVISION_FAILURE',

    //Update
    UPDATE_DIVISION_REQUEST = '@division/UPDATE_DIVISION_REQUEST',
    UPDATE_DIVISION_SUCCESS = '@division/UPDATE_DIVISION_SUCCESS',
    UPDATE_DIVISION_FAILURE = '@division/UPDATE_DIVISION_FAILURE',

    //Delete
    DELETE_DIVISION_REQUEST = '@division/DELETE_DIVISION_REQUEST',
    DELETE_DIVISION_SUCCESS = '@division/DELETE_DIVISION_SUCCESS',
    DELETE_DIVISION_FAILURE = '@division/DELETE_DIVISION_FAILURE',
}

/**
 * Data types
 */
export interface Division {
    id?: number
    parent?: Component | undefined,
    componentId?: number,
    name?: string,
    createdAt?: string,
    status?: string,
}

/**
 * State type
 */
export interface DivisionState {
    readonly all: Division[]
    readonly data: Division[]
    readonly loading: boolean
    readonly error: boolean
}