import { Result } from "../spmatch/types"


/** 
 * Action types
 */
export enum  PartialTypes {
    //Load single
    LOAD_PARTIAL_REQUEST = '@partial/LOAD_PARTIAL_REQUEST',
    LOAD_PARTIAL_SUCCESS = '@partial/LOAD_PARTIAL_SUCCESS',
    LOAD_PARTIAL_FAILURE = '@partial/LOAD_PARTIAL_FAILURE',

    //Create
    CREATE_PARTIAL_REQUEST = '@partial/CREATE_PARTIAL_REQUEST',
    CREATE_PARTIAL_SUCCESS = '@partial/CREATE_PARTIAL_SUCCESS',
    CREATE_PARTIAL_FAILURE = '@partial/CREATE_PARTIAL_FAILURE',

    //Update
    UPDATE_PARTIAL_REQUEST = '@partial/UPDATE_PARTIAL_REQUEST',
    UPDATE_PARTIAL_SUCCESS = '@partial/UPDATE_PARTIAL_SUCCESS',
    UPDATE_PARTIAL_FAILURE = '@partial/UPDATE_PARTIAL_FAILURE',

    //Delete
    DELETE_PARTIAL_REQUEST = '@partial/DELETE_PARTIAL_REQUEST',
    DELETE_PARTIAL_SUCCESS = '@partial/DELETE_PARTIAL_SUCCESS',
    DELETE_PARTIAL_FAILURE = '@partial/DELETE_PARTIAL_FAILURE',
}

/**
 * Data types
 */
export interface Partial {
    id?: number

    spResultId?: number
    parentResult?: Result

    nset?: string
    pontuation?: string
}

/**
 * State type
 */
export interface PartialState {
    readonly data: Partial[]
    readonly loading: boolean
    readonly error: boolean
}