import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    //Load
    // loadAllsupportsRequest,
    loadAllDelegationsRequest,
    loadAllDelegationsSuccess,
    loadAllDelegationsFailure,
    
    
    //Load single
    loadDelegationRequest,
    loadDelegationSuccess,
    loadDelegationFailure,
    

    //Create
    createDelegationRequest,
    createDelegationSuccess,
    createDelegationFailure,

    //Update
    updateDelegationRequest,
    updateDelegationSuccess,
    updateDelegationFailure,

    //Delete
    deleteDelegationRequest,
    deleteDelegationSuccess,
    deleteDelegationFailure,

    uploadImageRequest,
    uploadImageSuccess,
    uploadImageFailure,
    

} from './actions';
import { Delegation } from './types';

//Load
export function* loadAllDelegations() {
    try {
        const response : Delegation[] = yield call(api.get, 'delegation');
        yield put(loadAllDelegationsSuccess(response));
    } catch (error:any) {
        yield put(loadAllDelegationsFailure());
    }
}

//Load single
export function* loadDelegations(payload:ReturnType<typeof loadDelegationRequest>) {
    try {
        const response : Delegation[] = yield call(api.get, 'delegation/'+payload.payload);
        yield put(loadDelegationSuccess(response));
    } catch (error) {
        yield put(loadDelegationFailure());
    }
}

//Create
export function* createDelegation(payload:ReturnType<typeof createDelegationRequest>) {
    try {
        put(createDelegationRequest(payload.payload))
        const response : Delegation = yield call(api.post, 'delegation', payload.payload);
        yield put(createDelegationSuccess(response));
    } catch (error:any) {
        yield put(createDelegationFailure(error.response.message));
    }
}

//Update
export function* updateDelegation(payload:ReturnType<typeof updateDelegationRequest>) {
    try {
        put(updateDelegationRequest(payload.payload))
        const response : Delegation = yield call(api.patch, 'delegation/'+payload.payload.id, payload.payload);
        yield put(updateDelegationSuccess(response));
    } catch (error) {
        yield put(updateDelegationFailure());
    }
}

//Delete
export function* deleteDelegation(payload:ReturnType<typeof deleteDelegationRequest>) {
    try {
        const response : Delegation = yield call(api.delete, 'delegation/'+payload.payload);
        yield put(deleteDelegationSuccess(response));
    } catch (error) {
        yield put(deleteDelegationFailure());
    }
}

//Delete
export function* uploadImage(payload:ReturnType<typeof uploadImageRequest>) {
    try {
        console.log("PAYLOAD", payload.payload)
        const response : Delegation = yield call(api.post, 'upload', payload.payload);
        console.log("RESPONSE", response)
    } catch (error) {
        // yield put(uploadImageFailure());
        console.log("error", error)
    }
}