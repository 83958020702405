import { Sport } from "../spsport/types"

/** 
 * Action types
 */
export enum  SportConfigTypes {
    //Load single
    LOAD_SPORTCONFIG_REQUEST = '@sportconfig/LOAD_SPORTCONFIG_REQUEST',
    LOAD_SPORTCONFIG_SUCCESS = '@sportconfig/LOAD_SPORTCONFIG_SUCCESS',
    LOAD_SPORTCONFIG_FAILURE = '@sportconfig/LOAD_SPORTCONFIG_FAILURE',

    //Create
    CREATE_SPORTCONFIG_REQUEST = '@sportconfig/CREATE_SPORTCONFIG_REQUEST',
    CREATE_SPORTCONFIG_SUCCESS = '@sportconfig/CREATE_SPORTCONFIG_SUCCESS',
    CREATE_SPORTCONFIG_FAILURE = '@sportconfig/CREATE_SPORTCONFIG_FAILURE',

    //Update
    UPDATE_SPORTCONFIG_REQUEST = '@sportconfig/UPDATE_SPORTCONFIG_REQUEST',
    UPDATE_SPORTCONFIG_SUCCESS = '@sportconfig/UPDATE_SPORTCONFIG_SUCCESS',
    UPDATE_SPORTCONFIG_FAILURE = '@sportconfig/UPDATE_SPORTCONFIG_FAILURE',

    //Delete
    DELETE_SPORTCONFIG_REQUEST = '@sportconfig/DELETE_SPORTCONFIG_REQUEST',
    DELETE_SPORTCONFIG_SUCCESS = '@sportconfig/DELETE_SPORTCONFIG_SUCCESS',
    DELETE_SPORTCONFIG_FAILURE = '@sportconfig/DELETE_SPORTCONFIG_FAILURE',
}

/**
 * Data types
 */
export interface SportConfig {
    id?: number
    spSportId?: number
    parentSport?: Sport
    prop?: string
    value?: string
    type?: string
    createdAt?: string,
    status?: string,
}

/**
 * State type
 */
export interface SportConfigState {
    readonly data: SportConfig[]
    readonly loading: boolean
    readonly error: boolean
}