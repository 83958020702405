import { Reducer } from 'redux';
import { PartialState, PartialTypes } from './types';
const INITIAL_STATE: PartialState = {
    data: [],
    error: false,
    loading: false,
}

const reducer: Reducer<PartialState, any> = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //Load single
        case PartialTypes.LOAD_PARTIAL_REQUEST:
            return { ...state, loading: true }
        case PartialTypes.LOAD_PARTIAL_SUCCESS:
            return { ...state, loading: false, error: false, data: action.payload.data } 
        case PartialTypes.LOAD_PARTIAL_FAILURE:
            return { ...state, loading: false, error:true, data: [] }
        
        //Create
        case PartialTypes.CREATE_PARTIAL_REQUEST:
            return { ...state }
        case PartialTypes.CREATE_PARTIAL_SUCCESS:
            return { ...state, loading: false, error: false, data: [... state.data, action.payload.data]  }
        case PartialTypes.CREATE_PARTIAL_FAILURE:
            return { ...state, loading: false, error:true, data: [] }

        //Update 
        case PartialTypes.UPDATE_PARTIAL_REQUEST:
            return { ...state }
        case PartialTypes.UPDATE_PARTIAL_SUCCESS:
            console.log("ACTION PAYLOAD VER", action.payload)
            return { 
                ...state,
                loading: false, 
                error: false, 
                data: state.data?.map(child=> child.id === action.payload.data.id ? action.payload.data : child ) 
            } //update data?
        case PartialTypes.UPDATE_PARTIAL_FAILURE:
            return { ...state, loading: false, error:true }
        
        //Delete
        case PartialTypes.DELETE_PARTIAL_REQUEST:
            return { ...state, loading: true }
        case PartialTypes.DELETE_PARTIAL_SUCCESS:
            return { ...state, loading: false, error: false, data: state.data?.filter(item => item.id !== action.payload.data.id) } //só uma data: pq excluiu o user "Excluido com sucesso."
        case PartialTypes.DELETE_PARTIAL_FAILURE:
            return { ...state, loading: false, error:true }
            
        default:
            return state;

    }
}

export default reducer;