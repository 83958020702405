import {Reducer} from 'redux'
import {MatchState, MatchesTypes} from './types'
const INITIAL_STATE: MatchState = {
  data: [],
  dataMatchesByGroup: [],
  dataMatchesFinalsBySport: [],
  error: false,
  loading: false,
}

const reducer: Reducer<MatchState, any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //Load single
    case MatchesTypes.LOAD_MATCHES_REQUEST:
      return {...state, loading: true}
    case MatchesTypes.LOAD_MATCHES_SUCCESS:
      return {...state, loading: false, error: false, data: action.payload.data}
    case MatchesTypes.LOAD_MATCHES_FAILURE:
      return {...state, loading: false, error: true, data: []}

    case MatchesTypes.LOAD_MATCHESBYGROUP_REQUEST:
      return {...state, loading: true}
    case MatchesTypes.LOAD_MATCHESBYGROUP_SUCCESS:
      return {...state, loading: false, error: false, dataMatchesByGroup: action.payload.data}
    case MatchesTypes.LOAD_MATCHESBYGROUP_FAILURE:
      return {...state, loading: false, error: true, dataMatchesByGroup: []}

    case MatchesTypes.LOAD_MATCHESFINALSBYSPORT_REQUEST:
      return {...state, loading: true}
    case MatchesTypes.LOAD_MATCHESFINALSBYSPORT_SUCCESS:
      return {...state, loading: false, error: false, dataMatchesFinalsBySport: action.payload.data}
    case MatchesTypes.LOAD_MATCHESFINALSBYSPORT_FAILURE:
      return {...state, loading: false, error: true, dataMatchesFinalsBySport: []}

    //Create
    case MatchesTypes.CREATE_MATCH_REQUEST:
      return {...state, loading: true}
    case MatchesTypes.CREATE_MATCH_SUCCESS:
      // return { ...state, loading: false, error: false, data: [... state.data, action.payload.data]  }
      //return { ...state, loading: false, error: false, data: state.data?.concat(action.payload.data) }
      return {...state, loading: false, error: false, data: action.payload.data}
    case MatchesTypes.CREATE_MATCH_FAILURE:
      return {...state, loading: false, error: true, data: []}

    //Update
    case MatchesTypes.UPDATE_MATCH_REQUEST:
      return {...state, loading: true}
    case MatchesTypes.UPDATE_MATCH_SUCCESS:
      console.log('ACTION PAYLOAD VER', action.payload)
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data?.map((child) =>
          child.id === action.payload.data.id ? action.payload.data : child
        ),
      } //update data?
    case MatchesTypes.UPDATE_MATCH_FAILURE:
      return {...state, loading: false, error: true}


    //Match Result
    case MatchesTypes.UPDATE_MATCHRESULT_REQUEST:
      return {...state, loading: true}
    case MatchesTypes.UPDATE_MATCHRESULT_SUCCESS:
      console.log('ACTION PAYLOAD VER', action.payload)
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data?.map((child) =>
          child.id === action.payload.data.id ? action.payload.data : child
        ),
      } //update data?
    case MatchesTypes.UPDATE_MATCHRESULT_FAILURE:
      return {...state, loading: false, error: true}

    //Update
    // case MatchesTypes.UPDATE_MATCHRESULT_REQUEST:
    //     return { ...state }
    // case MatchesTypes.UPDATE_MATCHRESULT_SUCCESS:
    //     // console.log("-------- MATCHRESULTSUCCESS - VER AQUI", action.payload)
    //     // console.log("-------- MATCHRESULTSUCCESS - VER AQUI", action.payload.match_id)
    //     // console.log("-------- MATCHRESULTSUCCESS - VER AQUI", action.payload.data.data.id)
    //     return {
    //         ...state,
    //         loading: false,
    //         error: false,
    //         data: state.data?.map(child=> {
    //             if(child.id === action.payload.match_id) {
    //                 // console.log("É AQUI!!!", child.id)
    //                 return Object.assign({}, child, {
    //                     childrenResults: child.childrenResults?.map(result => {
    //                         if(result.id === action.payload.data.data.id) {
    //                             // console.log("!! É AQUI RESULTADO !!!", result.id)
    //                             return Object.assign({}, result, {
    //                                 pontuation: action.payload.data.data.pontuation,
    //                                 childrenPartials: action.payload.data.data.childrenPartials
    //                             })
    //                         } else {
    //                             return result
    //                         }
    //                     })
    //                 })
    //             }
    //             else
    //                 return child
    //             //child.id === action.payload.data.id ? action.payload.data : child
    //         }),

    //         dataMatchesByGroup: state.dataMatchesByGroup?.map(child=> {
    //             if(child.id === action.payload.match_id) {
    //                 // console.log("É AQUI!!!", child.id)
    //                 return Object.assign({}, child, {
    //                     childrenResults: child.childrenResults?.map(result => {
    //                         if(result.id === action.payload.data.data.id) {
    //                             // console.log("!! É AQUI RESULTADO !!!", result.id)
    //                             return Object.assign({}, result, {
    //                                 pontuation: action.payload.data.data.pontuation,
    //                                 childrenPartials: action.payload.data.data.childrenPartials
    //                             })
    //                         } else {
    //                             return result
    //                         }
    //                     })
    //                 })
    //             }
    //             else
    //                 return child
    //             //child.id === action.payload.data.id ? action.payload.data : child
    //         })

    //     } //update data?
    // case MatchesTypes.UPDATE_MATCHRESULT_FAILURE:
    //     return { ...state, loading: false, error:true }

    //Delete
    case MatchesTypes.DELETE_MATCH_REQUEST:
      return {...state, loading: true}
    case MatchesTypes.DELETE_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: state.data?.filter((item) => item.id !== action.payload.data.id),
      } //só uma data: pq excluiu o user "Excluido com sucesso."
    case MatchesTypes.DELETE_MATCH_FAILURE:
      return {...state, loading: false, error: true}

    default:
      return state
  }
}

export default reducer
