import React, {useEffect, useState} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {ApplicationState} from '../../../../../store'
import {loadGroupsRequest} from '../../../../../store/ducks/spgroup/actions'
import {
  createMatchRequest,
  loadMatchesFinalsBySportRequest,
} from '../../../../../store/ducks/spmatch/actions'
import {Match} from '../../../../../store/ducks/spmatch/types'
import {loadTeamsRequest} from '../../../../../store/ducks/spteam/actions'
import Loading from '../../../../loading'

// import { Modal } from 'react-bootstrap'
interface ParamTypes {
  id: string
}

interface handleCloseProps {
  handleClose: () => void
  selectedOption: string
}

const CreateColetiva = ({handleClose, selectedOption}: handleCloseProps) => {
  const [inputList, setInputList] = useState([
    {
      id: '',
      datetime: '',
      description: '',
      location: '',
      gamenumber: '',
      name: '',
      // wo: "",
      // manualorder: "",
      detail: '',
      childrenResults: [
        {spTeamId: null, ref: null},
        {spTeamId: null, ref: null},
      ],
    },
  ])

  const [validated, setValidated] = useState(false)
  const {id} = useParams()
  // const history = useNavigate();
  const dispatch = useDispatch()
  const component = useSelector((state: ApplicationState) => state.component)
  const teams = useSelector((state: ApplicationState) => state.teams)
  const groups = useSelector((state: ApplicationState) => state.groups)
  const matches = useSelector((state: ApplicationState) => state.matches)

  useEffect(() => {
    dispatch(loadTeamsRequest(+selectedOption)) //Puxa componentes com seus filhos primários
    dispatch(loadGroupsRequest(+selectedOption))
    dispatch(loadMatchesFinalsBySportRequest(+selectedOption))
  }, [id, selectedOption])

  // handle input change
  const handleInputChange = (e: any, index: number, timesIndex?: number) => {
    const {name, value} = e.target
    const list: any = [...inputList]
    if (timesIndex === undefined) {
      list[index][name] = value
    } else {
      //Transforma value em number pra checar se é Equipe ou se é Referência (ex: 1º Grupo A)
      let num: number = Number(value)
      //Checa se Index existe:
      if (list[index]['childrenResults'][timesIndex]) {
        if (Number.isInteger(num)) {
          list[index]['childrenResults'][timesIndex]['spTeamId'] = value
          list[index]['childrenResults'][timesIndex]['ref'] = null
        } else {
          list[index]['childrenResults'][timesIndex]['spTeamId'] = null
          list[index]['childrenResults'][timesIndex]['ref'] = value
        }
      } else {
        if (Number.isInteger(num)) {
          list[index]['childrenResults'][timesIndex] = {spTeamId: ''}
          list[index]['childrenResults'][timesIndex]['spTeamId'] = value
          list[index]['childrenResults'][timesIndex]['ref'] = null
        } else {
          list[index]['childrenResults'][timesIndex] = {ref: ''}
          list[index]['childrenResults'][timesIndex]['ref'] = value
          list[index]['childrenResults'][timesIndex]['spTeamId'] = null
        }
      }
    }

    setInputList(list)
  }

  // handle click event of the Remove button

  const handleRemoveClick = (index: any) => {
    // console.log("INDEX to remove", index)
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        id: '',
        datetime: inputList[inputList.length - 1].datetime,
        description: inputList[inputList.length - 1].description,
        location: inputList[inputList.length - 1].location,
        gamenumber: (+inputList[inputList.length - 1].gamenumber + 1).toString(),
        name: inputList[inputList.length - 1].name,
        // wo: "",
        // manualorder: "",
        detail: inputList[inputList.length - 1].detail,
        childrenResults: [
          {spTeamId: null, ref: null},
          {spTeamId: null, ref: null},
        ],
      },
    ])
  }

  console.log('Component inside - create', component)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)

    let matchesToSave: any[] = []
    inputList.map((item: any, index: any) => {
      // var data = new Date();
      let match: Match = {
        // id: +item.id,
        datetime: item.datetime,
        description: item.description,
        location: item.location,
        gamenumber: item.gamenumber,
        name: item.name,
        detail: item.detail,
        spSportId: parseInt(selectedOption),
        componentId: parseInt(id!),
        status: '1',
        childrenResults: item.childrenResults,
      }
      console.log('CRIANDO PARTIDA HEEYY...', match)
      matchesToSave.push(match)
    })
    console.log('MatchesToSave', matchesToSave)
    dispatch(createMatchRequest(matchesToSave))

    handleClose()
  }
  console.log('dataMatchesFinalsBySport', matches.dataMatchesFinalsBySport)
  if (teams.loading || matches.loading) return <div>Loading</div>

  return (
    <>
      
      <Form validated={validated} onSubmit={handleSubmit}>
        {inputList.map((x, i) => {
          return (
            <div key={i}>
              <div className='row'>
                <div className='col-3'>
                  <Form.Group>
                    <Form.Label>Datetime</Form.Label>
                    <Form.Control
                      placeholder='DateTime'
                      name='datetime'
                      value={x.datetime}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </Form.Group>
                </div>
                {/* <div className='col-3'>
                                        <Form.Group>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control 
                                                placeholder='Description'
                                                name="description"
                                                value={x.description}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </Form.Group>
                                    </div> */}
                <div className='col-3'>
                  {/* <Form.Group>
                                            <Form.Label>Detail</Form.Label>
                                            <Form.Control 
                                                placeholder='detail'
                                                name="detail" 
                                                value={x.detail}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </Form.Group> */}
                  <Form.Label>Detail</Form.Label>
                  <select
                    className='selectpicker form-control'
                    name='detail'
                    onChange={(e) => handleInputChange(e, i)}
                  >
                    <option value='' selected disabled hidden>
                      --Selecione--
                    </option>
                    <option value='Fase de grupos' selected={'Fase de grupos' === x.detail}>
                      Fase de grupos
                    </option>
                    <option value='Pré oitavas' selected={'Pré oitavas' === x.detail}>
                      Pré oitavas
                    </option>
                    <option value='Oitavas de final' selected={'Oitavas de final' === x.detail}>
                      Oitavas de final
                    </option>
                    <option value='Pré quartas' selected={'Pré quartas' === x.detail}>
                      Pré quartas
                    </option>
                    <option value='Quartas de final' selected={'Quartas de final' === x.detail}>
                      Quartas de final
                    </option>
                    <option value='Pré semifinal' selected={'Pré semifinal' === x.detail}>
                      Pré semifinal
                    </option>
                    <option value='Semifinal' selected={'Semifinal' === x.detail}>
                      Semifinal
                    </option>
                    <option value='7/8' selected={'7/8' === x.detail}>
                      7/8
                    </option>
                    <option value='5/6' selected={'5/6' === x.detail}>
                      5/6
                    </option>
                    <option value='3/4' selected={'3/4' === x.detail}>
                      3/4
                    </option>
                    <option value='Pré final' selected={'Pré final' === x.detail}>
                      Pré final
                    </option>
                    <option value='Final' selected={'Final' === x.detail}>
                      Final
                    </option>
                  </select>
                </div>
                <div className='col-3'>
                  <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      placeholder='Location'
                      name='location'
                      value={x.location}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </Form.Group>
                </div>
                <div className='col-3'>
                  <Form.Group>
                    <Form.Label>Gamenumber</Form.Label>
                    <Form.Control
                      placeholder='Gamenumber'
                      name='gamenumber'
                      value={x.gamenumber}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </Form.Group>
                </div>
              </div>
              <br />
              <div className='row'>
                {/* <div className='col-3'>
                                        <Form.Group>
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control 
                                                placeholder='name'
                                                name="name" 
                                                value={x.name}
                                                onChange={e => handleInputChange(e, i)}
                                            />
                                        </Form.Group>
                                    </div> */}

                <div className='col-6'>
                  <Form.Label>Equipe 1</Form.Label>
                  <select
                    className='selectpicker form-control'
                    name='times'
                    onChange={(e) => handleInputChange(e, i, 0)}
                  >
                    <option
                      value=''
                      disabled
                      hidden
                      selected={null === x.childrenResults[0].spTeamId}
                    >
                      --Selecione--
                    </option>

                    {teams.data.map((team, index) => {
                      return (
                        <option
                          key={index}
                          value={team.id}
                          selected={+team.id! === +x.childrenResults[0].spTeamId!}
                        >
                          {team.parentDelegation?.name}
                        </option>
                      )
                    })}

                    {/* TODO: aqui */}
                    <option disabled>────────────────────</option>
                    {groups.data.map((group, index) => {
                      return (
                        <>
                          <option
                            value={'1º ' + group.name}
                            selected={'1º ' + group.name === x.childrenResults[0].ref!}
                          >
                            1º {group.name}
                          </option>
                          <option
                            value={'2º ' + group.name}
                            selected={'2º ' + group.name === x.childrenResults[0].ref!}
                          >
                            2º {group.name}
                          </option>
                          <option
                            value={'3º ' + group.name}
                            selected={'3º ' + group.name === x.childrenResults[0].ref!}
                          >
                            3º {group.name}
                          </option>
                        </>
                      )
                    })}
                    <option disabled>────────────────────</option>
                    <option
                      value={'1º MELHOR 2º'}
                      selected={'1º MELHOR 2º' === x.childrenResults[0].ref!}
                    >
                      1º MELHOR 2º
                    </option>
                    <option
                      value={'2º MELHOR 2º'}
                      selected={'2º MELHOR 2º' === x.childrenResults[0].ref!}
                    >
                      2º MELHOR 2º
                    </option>
                    <option
                      value={'3º MELHOR 2º'}
                      selected={'3º MELHOR 2º' === x.childrenResults[0].ref!}
                    >
                      3º MELHOR 2º
                    </option>
                    <option
                      value={'4º MELHOR 2º'}
                      selected={'4º MELHOR 2º' === x.childrenResults[0].ref!}
                    >
                      4º MELHOR 2º
                    </option>
                    <option disabled>────────────────────</option>
                    <option
                      value={'1º MELHOR 3º'}
                      selected={'1º MELHOR 3º' === x.childrenResults[0].ref!}
                    >
                      1º MELHOR 3º
                    </option>
                    <option
                      value={'2º MELHOR 3º'}
                      selected={'2º MELHOR 3º' === x.childrenResults[0].ref!}
                    >
                      2º MELHOR 3º
                    </option>
                    <option
                      value={'3º MELHOR 3º'}
                      selected={'3º MELHOR 3º' === x.childrenResults[0].ref!}
                    >
                      3º MELHOR 3º
                    </option>
                    <option
                      value={'4º MELHOR 3º'}
                      selected={'4º MELHOR 3º' === x.childrenResults[0].ref!}
                    >
                      4º MELHOR 3º
                    </option>
                    <option disabled>────────────────────</option>
                    <option
                      value={'1º MELHOR 4º'}
                      selected={'1º MELHOR 4º' === x.childrenResults[0].ref!}
                    >
                      1º MELHOR 4º
                    </option>
                    <option
                      value={'2º MELHOR 4º'}
                      selected={'2º MELHOR 4º' === x.childrenResults[0].ref!}
                    >
                      2º MELHOR 4º
                    </option>
                    <option
                      value={'3º MELHOR 4º'}
                      selected={'3º MELHOR 4º' === x.childrenResults[0].ref!}
                    >
                      3º MELHOR 4º
                    </option>
                    <option
                      value={'4º MELHOR 4º'}
                      selected={'4º MELHOR 4º' === x.childrenResults[0].ref!}
                    >
                      4º MELHOR 4º
                    </option>
                    <option disabled>────────────────────</option>
                    {matches.dataMatchesFinalsBySport.map((final, index) => {
                      return (
                        <>
                          <option value={'Vencedor Jogo ' + final.gamenumber}>
                            Vencedor Jogo {final.gamenumber}
                          </option>
                          <option value={'Perdedor Jogo ' + final.gamenumber}>
                            Perdedor Jogo {final.gamenumber}
                          </option>
                        </>
                      )
                    })}

                    {/* <option value={'MELHOR 4º'}>MELHOR 4º</option> */}
                  </select>
                </div>
                <div className='col-6'>
                  <Form.Label>Equipe 2</Form.Label>
                  <select
                    className='selectpicker form-control'
                    name='times'
                    onChange={(e) => handleInputChange(e, i, 1)}
                  >
                    <option
                      value=''
                      disabled
                      hidden
                      selected={null === x.childrenResults[1].spTeamId}
                    >
                      --Selecione--
                    </option>
                    {teams.data.map((team, index) => {
                      return (
                        <option
                          key={index}
                          value={team.id}
                          selected={+team.id! === +x.childrenResults[1].spTeamId!}
                        >
                          {team.parentDelegation?.name}
                        </option>
                      )
                    })}
                    {/* TODO: aqui */}
                    <option disabled>────────────────────</option>
                    {groups.data.map((group, index) => {
                      return (
                        <>
                          <option
                            value={'1º ' + group.name}
                            selected={'1º ' + group.name === x.childrenResults[1].ref!}
                          >
                            1º {group.name}
                          </option>
                          <option
                            value={'2º ' + group.name}
                            selected={'2º ' + group.name === x.childrenResults[1].ref!}
                          >
                            2º {group.name}
                          </option>
                          <option
                            value={'3º ' + group.name}
                            selected={'3º ' + group.name === x.childrenResults[1].ref!}
                          >
                            3º {group.name}
                          </option>
                        </>
                      )
                    })}
                    <option disabled>────────────────────</option>
                    <option
                      value={'1º MELHOR 2º'}
                      selected={'1º MELHOR 2º' === x.childrenResults[1].ref!}
                    >
                      1º MELHOR 2º
                    </option>
                    <option
                      value={'2º MELHOR 2º'}
                      selected={'2º MELHOR 2º' === x.childrenResults[1].ref!}
                    >
                      2º MELHOR 2º
                    </option>
                    <option
                      value={'3º MELHOR 2º'}
                      selected={'3º MELHOR 2º' === x.childrenResults[1].ref!}
                    >
                      3º MELHOR 2º
                    </option>
                    <option
                      value={'4º MELHOR 2º'}
                      selected={'4º MELHOR 2º' === x.childrenResults[1].ref!}
                    >
                      4º MELHOR 2º
                    </option>
                    <option disabled>────────────────────</option>
                    <option
                      value={'1º MELHOR 3º'}
                      selected={'1º MELHOR 3º' === x.childrenResults[1].ref!}
                    >
                      1º MELHOR 3º
                    </option>
                    <option
                      value={'2º MELHOR 3º'}
                      selected={'2º MELHOR 3º' === x.childrenResults[1].ref!}
                    >
                      2º MELHOR 3º
                    </option>
                    <option
                      value={'3º MELHOR 3º'}
                      selected={'3º MELHOR 3º' === x.childrenResults[1].ref!}
                    >
                      3º MELHOR 3º
                    </option>
                    <option
                      value={'4º MELHOR 3º'}
                      selected={'4º MELHOR 3º' === x.childrenResults[1].ref!}
                    >
                      4º MELHOR 3º
                    </option>
                    <option disabled>────────────────────</option>
                    <option
                      value={'1º MELHOR 4º'}
                      selected={'1º MELHOR 4º' === x.childrenResults[1].ref!}
                    >
                      1º MELHOR 4º
                    </option>
                    <option
                      value={'2º MELHOR 4º'}
                      selected={'2º MELHOR 4º' === x.childrenResults[1].ref!}
                    >
                      2º MELHOR 4º
                    </option>
                    <option
                      value={'3º MELHOR 4º'}
                      selected={'3º MELHOR 4º' === x.childrenResults[1].ref!}
                    >
                      3º MELHOR 4º
                    </option>
                    <option
                      value={'4º MELHOR 4º'}
                      selected={'4º MELHOR 4º' === x.childrenResults[1].ref!}
                    >
                      4º MELHOR 4º
                    </option>
                    <option disabled>────────────────────</option>
                    {matches.dataMatchesFinalsBySport.map((final, index) => {
                      return (
                        <>
                          <option value={'Vencedor Jogo ' + final.gamenumber}>
                            Vencedor Jogo {final.gamenumber}
                          </option>
                          <option value={'Perdedor Jogo ' + final.gamenumber}>
                            Perdedor Jogo {final.gamenumber}
                          </option>
                        </>
                      )
                    })}
                  </select>
                </div>
              </div>
              <br />
              {inputList.length !== 1 && (
                <Button
                  size='sm'
                  variant='primary'
                  className='float-right'
                  onClick={() => handleRemoveClick(i)}
                >
                  Remover essa partida
                </Button>
              )}
              <br />
              <br />
              {inputList.length - 1 === i && (
                <Button
                  size='sm'
                  variant='primary'
                  className='float-right'
                  onClick={handleAddClick}
                >
                  Adicionar outra partida
                </Button>
              )}
              <br />
              <br />
            </div>
          )
        })}
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}

        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
      {/* Deixar o button fora do form.. */}
    </>
  )
}
export default CreateColetiva
