/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {Component} from '../../../../../store/ducks/component/types'
import {useDispatch} from 'react-redux'
import Create from './create'
import Update from './update'
import {DivisionState} from '../../../../../store/ducks/spdivision/types'
import {deleteDivisionRequest} from '../../../../../store/ducks/spdivision/actions'
import {KTSVG} from '../../../../design/helpers'

type Props = {
  className: string
  divisions: DivisionState
}

const ManageDivisionWidget: React.FC<Props> = ({className, divisions}) => {
  const [show, setShow] = useState<boolean>(false)
  const [action, setAction] = useState<string>('')
  const [child, setChild] = useState<Component>({})
  const dispatch = useDispatch()

  const handleClose = () => {
    setShow(false)
  }

  const createComponent = () => {
    setAction('createComponent')
    setShow(true)
  }

  const updateComponent = (child: Component) => {
    setAction('updateComponent')
    setShow(true)
    setChild(child)
  }

  const deleteDivision = (component: Component) => {
    dispatch(deleteDivisionRequest(component.id!))
  }

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton placeholder={'Hey'}>
          <Modal.Title>
            {action === 'createComponent' ? 'Adicionar componente' : ''}
            {action === 'updateComponent' ? 'Editar componente' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {action === 'createComponent' ? <Create handleClose={handleClose} /> : ''}
          {action === 'updateComponent' ? <Update handleClose={handleClose} child={child} /> : ''}
        </Modal.Body>
      </Modal>

      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Divisões Esportivas</span>
            <span className='text-muted mt-1 fw-bold fs-7'>
              Gerenciador de divisões do campeonato
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <a href='#' className='btn btn-sm btn-light-primary' onClick={() => createComponent()}>
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              Adicionar divisão
            </a>
          </div>
        </div>
        <div className='card-body py-3'>
          
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='min-w-150px'>Divisão</th>
                  <th className='min-w-100px text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {divisions.data.map((child: any, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <div className='text-dark fw-bolder  fs-6'>{child.name}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            href='#'
                            onClick={() => updateComponent(child)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            onClick={() => {
                              if (window.confirm('Deseja realmente excluir: ' + child.name + '?'))
                                deleteDivision(child)
                            }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export {ManageDivisionWidget}
