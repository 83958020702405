import _ from 'lodash';
import { Partial } from '../../../../../../../store/ducks/sppartial/types';
import { Match, MatchState, Result } from '../../../../../../../store/ducks/spmatch/types';


const array = (
  spTeamId:number, 
  idGroupTeam:number, 
  jogos: number,
  vitorias:number, 
  derrotas:number, 
  empates:number, 
  vitoriascondicionais:number, 
  derrotascondicionais:number, 
  geralfeito:number, 
  geraltomado:number, 
  parcialfeito:number, 
  parcialtomado:number,
  pontuacao:number
) => {
  return {
      spTeamId,
      idGroupTeam,

      jogos,

      vitorias, 
      derrotas, 
      empates, 
      vitoriascondicionais, 
      derrotascondicionais, 

      geralfeito, 
      geraltomado, 
      parcialfeito, 
      parcialtomado,

      saldogeral: geralfeito - geraltomado,
      saldoparcial: parcialfeito - parcialtomado,

      pontuacao
  }
}

const calculaPontuacao = (
    vitorias:number, 
    derrotas:number, 
    empates:number, 
    vitoriascondicionais:number, 
    derrotascondicionais:number,
    pontuacaoV: number,
    pontuacaoD: number,
    pontuacaoE: number,
    pontuacaoVCond: number,
    pontuacaoDCond: number,
    ) => {
        
        let pontuation = (
            (vitorias * pontuacaoV) + 
            (derrotas * pontuacaoD) + 
            (empates * pontuacaoE) + 
            (vitoriascondicionais * pontuacaoVCond) + 
            (derrotascondicionais * pontuacaoDCond)
        )
        // console.log("PONTUACAO CALCULADA", pontuation)
        // console.log("VOU CALCULAR VITORIAS", {vitorias, derrotas, empates, vitoriascondicionais, derrotascondicionais})
        // console.log("VOU CALCULAR PONTUACAO", {pontuacaoV, pontuacaoD, pontuacaoE, pontuacaoVCond, pontuacaoDCond})
        return pontuation;
}

const createGroupPontuation = (matchSelected: Match, matches: MatchState, inputList: Result[], scoreConfig: any ) => {
 let { placarVCond, placarDCond, pontuacaoVCond, pontuacaoDCond, pontuacaoV, pontuacaoD, pontuacaoE } = scoreConfig
 let arrayGroupPontuation:any = [];
 let groupMatches = matches.dataMatchesByGroup
//   console.log("#####Matches", matches) 
//   console.log("#####Matches By Group", groupMatches)

 groupMatches.map((match,index) => {
     console.log("#####Match "+(index+1)+":", match)
     let idTeamA = match.childrenResults![0].spAthleteTeamId!
     let idTeamB = match.childrenResults![1].spAthleteTeamId!

     let resultTeamA;
     let resultTeamB;

     let parcialTeamA : Partial[];
     let parcialTeamB: Partial[];

     let idGroupTeamA = match.childrenResults![0].parentAthleteTeam?.childrenGroupteam![0].id!
     let idGroupTeamB = match.childrenResults![1].parentAthleteTeam?.childrenGroupteam![0].id!

     //Verifica se é a partida que estou atualizando, e pega os dados do formulario..
     //Senao pega os dados que está na redux:
     if(match.id === matchSelected.id) {

         resultTeamA = inputList[0].pontuation;
         resultTeamB = inputList[1].pontuation;

         parcialTeamA = inputList[0].childrenPartials!;
         parcialTeamB = inputList[1].childrenPartials!;

     }else {

         resultTeamA = match.childrenResults![0].pontuation;
         resultTeamB = match.childrenResults![1].pontuation;

         parcialTeamA = match.childrenResults![0].childrenPartials!;
         parcialTeamB = match.childrenResults![1].childrenPartials!;

     }

     // console.log("partialTeamA",partialTeamA)
     // console.log("partialTeamB",partialTeamB)
     
     let sumPartialA = _.sumBy(parcialTeamA, function(o:any) { if(o) return +o.pontuation; return 0; });
     let sumPartialB = _.sumBy(parcialTeamB, function(o:any) { if(o) return +o.pontuation; return 0; });

     // console.log("sumPartialA",sumPartialA)
     // console.log("sumPartialB",sumPartialB)

     // console.log("resultTeamA",resultTeamA)
     // console.log("resultTeamB",resultTeamB)
     
     if((resultTeamA != '' && resultTeamA != null) && (resultTeamB != '' && resultTeamB != null)){
         //Checa Empate / Vitoria ou Derrota
         if(resultTeamA == resultTeamB) {
             //Deu empate
             // console.log("###########Empatou", {idTeamA, idTeamB})
             arrayGroupPontuation.push(array(idTeamA,idGroupTeamA,1,0,0,1,0,0,+resultTeamA,+resultTeamB,sumPartialA,sumPartialB,calculaPontuacao(0,0,1,0,0,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))
             arrayGroupPontuation.push(array(idTeamB,idGroupTeamB,1,0,0,1,0,0,+resultTeamB,+resultTeamA,sumPartialB,sumPartialA,calculaPontuacao(0,0,1,0,0,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))

         }else{
             //Nao deu empate
             //Verifica se tem condicional:
             
             if(resultTeamA > resultTeamB) {
                 //Equipe A ganhou
                 if((placarVCond == resultTeamA) && (placarDCond == resultTeamB)) {
                     //Se for condicional:
                     //console.log("###########Equipe A VENCEU CONDICIONAL", { gamenumber: match.gamenumber, vencedor: idTeamA, game: match.childrenResults![0].parentTeam?.parentDelegation?.name + ' ' + idTeamA + " x " + idTeamB + ' ' + match.childrenResults![1].parentTeam?.parentDelegation?.name } )
                     arrayGroupPontuation.push(array(idTeamA,idGroupTeamA,1,0,0,0,1,0,+resultTeamA,+resultTeamB,sumPartialA,sumPartialB,calculaPontuacao(0,0,0,1,0,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))
                     arrayGroupPontuation.push(array(idTeamB,idGroupTeamB,1,0,0,0,0,1,+resultTeamB,+resultTeamA,sumPartialB,sumPartialA,calculaPontuacao(0,0,0,0,1,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))

                 }else{
                     //Se não for condicional:
                     //console.log("###########Equipe A VENCEU", { gamenumber: match.gamenumber, vencedor: idTeamA, game: match.childrenResults![0].parentTeam?.parentDelegation?.name + ' ' + idTeamA + " x " + idTeamB + ' ' + match.childrenResults![1].parentTeam?.parentDelegation?.name })
                     arrayGroupPontuation.push(array(idTeamA,idGroupTeamA,1,1,0,0,0,0,+resultTeamA,+resultTeamB,sumPartialA,sumPartialB,calculaPontuacao(1,0,0,0,0,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))
                     arrayGroupPontuation.push(array(idTeamB,idGroupTeamB,1,0,1,0,0,0,+resultTeamB,+resultTeamA,sumPartialB,sumPartialA,calculaPontuacao(0,1,0,0,0,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))
                 }
             }

             else{
                 //Equipe B ganhou
                 if((placarVCond == resultTeamB) && (placarDCond == resultTeamA)) {
                     //Se for condicional:
                     //console.log("###########Equipe B VENCEU CONDICIONAL", { gamenumber: match.gamenumber, vencedor: idTeamB, game: match.childrenResults![0].parentTeam?.parentDelegation?.name + ' ' + idTeamA + " x " + idTeamB + ' ' + match.childrenResults![1].parentTeam?.parentDelegation?.name })
                     arrayGroupPontuation.push(array(idTeamA,idGroupTeamA,1,0,0,0,0,1,+resultTeamA,+resultTeamB,sumPartialA,sumPartialB,calculaPontuacao(0,0,0,0,1,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))
                     arrayGroupPontuation.push(array(idTeamB,idGroupTeamB,1,0,0,0,1,0,+resultTeamB,+resultTeamA,sumPartialB,sumPartialA,calculaPontuacao(0,0,0,1,0,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))

                 }else{
                     //Se não for condicional:
                     //console.log("###########Equipe B VENCEU", { gamenumber: match.gamenumber, vencedor: idTeamB, game: match.childrenResults![0].parentTeam?.parentDelegation?.name + ' ' + idTeamA + " x " + idTeamB + ' ' + match.childrenResults![1].parentTeam?.parentDelegation?.name })
                     arrayGroupPontuation.push(array(idTeamA,idGroupTeamA,1,0,1,0,0,0,+resultTeamA,+resultTeamB,sumPartialA,sumPartialB,calculaPontuacao(0,1,0,0,0,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))
                     arrayGroupPontuation.push(array(idTeamB,idGroupTeamB,1,1,0,0,0,0,+resultTeamB,+resultTeamA,sumPartialB,sumPartialA,calculaPontuacao(1,0,0,0,0,pontuacaoV,pontuacaoD,pontuacaoE,pontuacaoVCond,pontuacaoDCond)))
                 }
             }
         }
     } else {
        arrayGroupPontuation.push(array(idTeamA,idGroupTeamA,0,0,0,0,0,0,0,0,0,0,0))
        arrayGroupPontuation.push(array(idTeamB,idGroupTeamB,0,0,0,0,0,0,0,0,0,0,0))
     }
 })
 // console.log("arrayGroupPontuationTOP",arrayGroupPontuation)
 //console.log("JOB createGroupPontuation OK!", arrayGroupPontuation)
    return arrayGroupPontuation

}

export default createGroupPontuation
