import { action } from 'typesafe-actions';
import { DivisionsTypes, Division } from './types'

//Load
export const loadAllDivisionsRequest = () => action(DivisionsTypes.LOAD_ALLDIVISIONS_REQUEST) 
export const loadAllDivisionsSuccess = (data: Division[]) => action(DivisionsTypes.LOAD_ALLDIVISIONS_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadAllDivisionsFailure = () => action(DivisionsTypes.LOAD_ALLDIVISIONS_FAILURE) 

//Load single
export const loadDivisionsRequest = (id: number) => action(DivisionsTypes.LOAD_DIVISION_REQUEST, id) 
export const loadDivisionsSuccess = (data: Division[]) => action(DivisionsTypes.LOAD_DIVISION_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadDivisionsFailure = () => action(DivisionsTypes.LOAD_DIVISION_FAILURE) 

//Create
export const createDivisionRequest = (data: Division) => action(DivisionsTypes.CREATE_DIVISION_REQUEST, data)
export const createDivisionSuccess = (data: Division) => action(DivisionsTypes.CREATE_DIVISION_SUCCESS, data)
export const createDivisionFailure = (error: {}) => action(DivisionsTypes.CREATE_DIVISION_FAILURE, error)

//Update
export const updateDivisionRequest = (data: Division) => action(DivisionsTypes.UPDATE_DIVISION_REQUEST, data) 
export const updateDivisionSuccess = (data: Division) => action(DivisionsTypes.UPDATE_DIVISION_SUCCESS, data)
export const updateDivisionFailure = () => action(DivisionsTypes.UPDATE_DIVISION_FAILURE) 

//Delete
export const deleteDivisionRequest = (id: number) => action(DivisionsTypes.DELETE_DIVISION_REQUEST, id) 
export const deleteDivisionSuccess = (data: Division) => action(DivisionsTypes.DELETE_DIVISION_SUCCESS, data)
export const deleteDivisionFailure = () => action(DivisionsTypes.DELETE_DIVISION_FAILURE) 

