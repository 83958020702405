import { action } from 'typesafe-actions';
import { SportsTypes, Sport } from './types'

//Load
export const loadAllSportsRequest = () => action(SportsTypes.LOAD_ALLSPORTS_REQUEST) 
export const loadAllSportsSuccess = (data: Sport[]) => action(SportsTypes.LOAD_ALLSPORTS_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadAllSportsFailure = () => action(SportsTypes.LOAD_ALLSPORTS_FAILURE) 

//Load single
export const loadSportsRequest = (id: number) => action(SportsTypes.LOAD_SPORT_REQUEST, id) 
export const loadSportsSuccess = (data: Sport[]) => action(SportsTypes.LOAD_SPORT_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadSportsFailure = () => action(SportsTypes.LOAD_SPORT_FAILURE) 

//Load Sports By Championship
export const loadSportsByChampionshipRequest = (id: number) => action(SportsTypes.LOAD_SPORTS_BY_CHAMPIONSHIP_REQUEST, id) 
export const loadSportsByChampionshipSuccess = (data: Sport[]) => action(SportsTypes.LOAD_SPORTS_BY_CHAMPIONSHIP_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadSportsByChampionshipFailure = () => action(SportsTypes.LOAD_SPORTS_BY_CHAMPIONSHIP_FAILURE) 

//Create
export const createSportRequest = (data: Sport) => action(SportsTypes.CREATE_SPORT_REQUEST, data)
export const createSportsuccess = (data: Sport) => action(SportsTypes.CREATE_SPORT_SUCCESS, data)
export const createSportFailure = (error: {}) => action(SportsTypes.CREATE_SPORT_FAILURE, error)

//Update
export const updateSportRequest = (data: Sport) => action(SportsTypes.UPDATE_SPORT_REQUEST, data) 
export const updateSportSuccess = (data: Sport) => action(SportsTypes.UPDATE_SPORT_SUCCESS, data)
export const updateSportFailure = () => action(SportsTypes.UPDATE_SPORT_FAILURE) 

//Delete
export const deleteSportRequest = (id: number) => action(SportsTypes.DELETE_SPORT_REQUEST, id) 
export const deleteSportSuccess = (data: Sport) => action(SportsTypes.DELETE_SPORT_SUCCESS, data)
export const deleteSportFailure = () => action(SportsTypes.DELETE_SPORT_FAILURE) 
