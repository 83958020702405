import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    //Load
    loadGroupsRequest,
    loadGroupsSuccess,
    loadGroupsFailure,

    //Create
    createGroupRequest,
    createGroupSuccess,
    createGroupFailure,

    //Update
    updateGroupRequest,
    updateGroupSuccess,
    updateGroupFailure,

    //Delete
    deleteGroupRequest,
    deleteGroupSuccess,
    deleteGroupFailure,
    

} from './actions';
import { Group } from './types';


//Load single
export function* loadGroups(payload:ReturnType<typeof loadGroupsRequest>) {
    console.log("LOAD SPORTS")
    try {
        const response : Group[] = yield call(api.get, 'group/'+payload.payload);
        yield put(loadGroupsSuccess(response));
    } catch (error) {
        yield put(loadGroupsFailure());
    }
}

//Create
export function* createGroup(payload:ReturnType<typeof createGroupRequest>) {
    try {
        put(createGroupRequest(payload.payload))
        const response : Group = yield call(api.post, 'group', payload.payload);
        yield put(createGroupSuccess(response));
    } catch (error:any) {
        yield put(createGroupFailure(error.response.message));
    }
}

//Update
export function* updateGroup(payload:ReturnType<typeof updateGroupRequest>) {
    try {
        put(updateGroupRequest(payload.payload))
        const response : Group = yield call(api.patch, 'group/'+payload.payload.id, payload.payload);
        yield put(updateGroupSuccess(response));
    } catch (error) {
        yield put(updateGroupFailure());
    }
}

//Delete
export function* deleteGroup(payload:ReturnType<typeof deleteGroupRequest>) {
    try {
        put(deleteGroupRequest(payload.payload))
        const response : Group = yield call(api.delete, 'group/'+payload.payload);
        yield put(deleteGroupSuccess(response));
    } catch (error) {
        yield put(deleteGroupFailure());
    }
}
