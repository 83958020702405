import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    //Load single
    loadAthleteTeamByTeamRequest,
    loadAthleteTeamByTeamFailure,
    loadAthleteTeamByTeamSuccess,

    loadAthleteTeamBySportRequest,
    loadAthleteTeamBySportFailure,
    loadAthleteTeamBySportSuccess,

    //Create
    createAthleteTeamRequest,
    createAthleteTeamSuccess,
    createAthleteTeamFailure,

    //Update
    updateAthleteTeamRequest,
    updateAthleteTeamSuccess,
    updateAthleteTeamFailure,

    //Delete
    deleteAthleteTeamRequest,
    deleteAthleteTeamSuccess,
    deleteAthleteTeamFailure,

} from './actions';
import { AthleteTeam } from './types';

//Load single
export function* loadAthleteTeamByTeam(payload:ReturnType<typeof loadAthleteTeamByTeamRequest>) {
    console.log("LOEAD ATHLETE TEAM")
    try {
        const response : AthleteTeam[] = yield call(api.get, 'athleteteam/team/'+payload.payload);
        yield put(loadAthleteTeamByTeamSuccess(response));
    } catch (error) {
        yield put(loadAthleteTeamByTeamFailure());
    }
}

export function* loadAthleteTeamBySport(payload:ReturnType<typeof loadAthleteTeamBySportRequest>) {
    console.log("LOEAD ATHLETE TEAM")
    try {
        const response : AthleteTeam[] = yield call(api.get, 'athleteteam/sport/'+payload.payload);
        yield put(loadAthleteTeamBySportSuccess(response));
    } catch (error) {
        yield put(loadAthleteTeamBySportFailure());
    }
}

//Create
export function* createAthleteTeam(payload:ReturnType<typeof createAthleteTeamRequest>) {
    try {
        put(createAthleteTeamRequest(payload.payload))
        const response : AthleteTeam = yield call(api.post, 'athleteteam', payload.payload);
        yield put(createAthleteTeamSuccess(response));
    } catch (error:any) {
        yield put(createAthleteTeamFailure(error.response.message));
    }
}

//Update
export function* updateAthleteTeam(payload:ReturnType<typeof updateAthleteTeamRequest>) {
    try {
        put(updateAthleteTeamRequest(payload.payload))
        console.log("PAYLOAD", payload.payload)
        const response : AthleteTeam = yield call(api.patch, 'athleteteam/'+payload.payload.id, payload.payload);
        yield put(updateAthleteTeamSuccess(response));
    } catch (error:any) {
        console.log("ERRXX", error.response.data)
        yield put(updateAthleteTeamFailure());
    }
}

//Delete
export function* deleteAthleteTeam(payload:ReturnType<typeof deleteAthleteTeamRequest>) {
    try {
        //put(deleteTeamRequest(payload.payload.delegation_id, payload.payload.sport_id))
        const response : AthleteTeam = yield call(api.delete, 'athleteteam/'+payload.payload);
        yield put(deleteAthleteTeamSuccess(response));
    } catch (error) {
        yield put(deleteAthleteTeamFailure());
    }
}
