import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    //Load
    // loadAllsupportsRequest,
    loadAllDivisionsSuccess,
    loadAllDivisionsFailure,
    
    //Load single
    loadDivisionsRequest,
    loadDivisionsSuccess,
    loadDivisionsFailure,

    //Create
    createDivisionRequest,
    createDivisionSuccess,
    createDivisionFailure,

    //Update
    updateDivisionRequest,
    updateDivisionSuccess,
    updateDivisionFailure,

    //Delete
    deleteDivisionRequest,
    deleteDivisionSuccess,
    deleteDivisionFailure
    

} from './actions';
import { Division } from './types';

//Load
export function* loadAlldivisions() {
    try {
        const response : Division[] = yield call(api.get, 'division');
        yield put(loadAllDivisionsSuccess(response));
    } catch (error:any) {
        yield put(loadAllDivisionsFailure());
    }
}

//Load single
export function* loadDivisions(payload:ReturnType<typeof loadDivisionsRequest>) {
    try {
        const response : Division[] = yield call(api.get, 'division/'+payload.payload);
        yield put(loadDivisionsSuccess(response));
    } catch (error) {
        yield put(loadDivisionsFailure());
    }
}

//Create
export function* createDivision(payload:ReturnType<typeof createDivisionRequest>) {
    try {
        put(createDivisionRequest(payload.payload))
        const response : Division = yield call(api.post, 'division', payload.payload);
        yield put(createDivisionSuccess(response));
    } catch (error:any) {
        yield put(createDivisionFailure(error.response.message));
    }
}

//Update
export function* updateDivision(payload:ReturnType<typeof updateDivisionRequest>) {
    try {
        put(updateDivisionRequest(payload.payload))
        const response : Division = yield call(api.patch, 'division/'+payload.payload.id, payload.payload);
        yield put(updateDivisionSuccess(response));
    } catch (error) {
        yield put(updateDivisionFailure());
    }
}

//Delete
export function* deleteDivision(payload:ReturnType<typeof deleteDivisionRequest>) {
    try {
        const response : Division = yield call(api.delete, 'division/'+payload.payload);
        yield put(deleteDivisionSuccess(response));
    } catch (error) {
        yield put(deleteDivisionFailure());
    }
}