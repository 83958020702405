import { Component } from "react"
import { AthleteTeam } from "../spathleteteam/types"
import { Delegation } from "../spdelegation/types"
import { Groupteam } from "../spgroupteam/types"
import { Sport } from "../spsport/types"

/** 
 * Action types
 */
export enum  TeamsTypes {
    //Load single
    LOAD_TEAMS_REQUEST = '@team/LOAD_TEAMS_REQUEST',
    LOAD_TEAMS_SUCCESS = '@team/LOAD_TEAMS_SUCCESS',
    LOAD_TEAMS_FAILURE = '@team/LOAD_TEAMS_FAILURE',

    LOAD_TEAMSBYDIVISION_REQUEST = '@team/LOAD_TEAMSBYDIVISION_REQUEST',
    LOAD_TEAMSBYDIVISION_SUCCESS = '@team/LOAD_TEAMSBYDIVISION_SUCCESS',
    LOAD_TEAMSBYDIVISION_FAILURE = '@team/LOAD_TEAMSBYDIVISION_FAILURE',

    //Create
    CREATE_TEAM_REQUEST = '@team/CREATE_TEAM_REQUEST',
    CREATE_TEAM_SUCCESS = '@team/CREATE_TEAM_SUCCESS',
    CREATE_TEAM_FAILURE = '@team/CREATE_TEAM_FAILURE',

    //Update
    UPDATE_TEAM_REQUEST = '@team/UPDATE_TEAM_REQUEST',
    UPDATE_TEAM_SUCCESS = '@team/UPDATE_TEAM_SUCCESS',
    UPDATE_TEAM_FAILURE = '@team/UPDATE_TEAM_FAILURE',

    //Delete
    DELETE_TEAM_REQUEST = '@team/DELETE_TEAM_REQUEST',
    DELETE_TEAM_SUCCESS = '@team/DELETE_TEAM_SUCCESS',
    DELETE_TEAM_FAILURE = '@team/DELETE_TEAM_FAILURE',
}

/**
 * Data types
 */
export interface Team {
    id?: number

    spSportId?: number
    parentSport?: Sport

    spDelegationId?: number
    parentDelegation?: Delegation

    
    createdAt?: string,
    status?: string,

    // childrenGroupteam?: Groupteam[]
    childrenAthleteTeams?: AthleteTeam[]
}

/**
 * State type
 */
export interface TeamState {
    readonly data: Team[]
    readonly dataByDivision: Team[]
    readonly loading: boolean
    readonly error: boolean
}