import _ from 'lodash';
import { Partial } from '../../../../../../../store/ducks/sppartial/types';
import { Match, MatchState, Result } from '../../../../../../../store/ducks/spmatch/types';


const setupNextGame = (match:Match, matches: MatchState, inputList: Result[]) => {
 
    let toUpdateMatches = [];
    console.log("Montando proximo jogo")

    let gamenumber = match.gamenumber
    let sport_id = match.parentSport?.id
    let gameFinals =  matches.dataMatchesFinalsBySport;

    // console.log('gamenumber', gamenumber)
    // console.log('id-sport', sport_id)
    // console.log("Matches fase final", gameFinals)

    //Pega ids e resultados da partida
    let idTeamA = match.childrenResults![0].spAthleteTeamId!
    let idTeamB = match.childrenResults![1].spAthleteTeamId!

    let idAthleteTeamA = match.childrenResults![0].spAthleteTeamId!
    let idAthleteTeamB = match.childrenResults![1].spAthleteTeamId!

    let resultTeamA = parseFloat( inputList[0].pontuation! );
    let resultTeamB = parseFloat( inputList[1].pontuation! );

    let parcialTeamA = inputList[0].childrenPartials;
    let parcialTeamB = inputList[1].childrenPartials;

    let sumPartialA = _.sumBy(parcialTeamA, function(o:any) { if(o) return +o.pontuation; return 0; });
    let sumPartialB = _.sumBy(parcialTeamB, function(o:any) { if(o) return +o.pontuation; return 0; });
    

    // console.log("sumpartials", { sumPartialA, sumPartialB})
    let idVencedor: any;
    let idPerdedor: any;

    let idAthTeamVencedor: any;
    let idAthTeamPerdedor: any;
    
    
    //Decide quem foi o vencedor
    if(resultTeamA == resultTeamB) {
        //Checa nas parciais:
        if (sumPartialA > sumPartialB) {

            idVencedor = idTeamA;
            idPerdedor = idTeamB;
            idAthTeamVencedor = idAthleteTeamA;
            idAthTeamPerdedor = idAthleteTeamB;

        } else {
            idVencedor = idTeamB;
            idPerdedor = idTeamA;
            idAthTeamVencedor = idAthleteTeamB;
            idAthTeamPerdedor = idAthleteTeamA;
        }
        
    }else{
        if(resultTeamA! > resultTeamB!) {
            idVencedor = idTeamA;
            idPerdedor = idTeamB;
            idAthTeamVencedor = idAthleteTeamA;
            idAthTeamPerdedor = idAthleteTeamB;
        } else {
            idVencedor = idTeamB;
            idPerdedor = idTeamA;
            idAthTeamVencedor = idAthleteTeamB;
            idAthTeamPerdedor = idAthleteTeamA;
        }
    }
    // console.log("Vencedor", idVencedor)
    // console.log("Perdedor", idPerdedor)

    //Atualiza jogo do vencedor
    let jogoReferenciaVencedor = gameFinals.filter((child) => child.childrenResults?.some((x)=> (x.ref === 'Vencedor Jogo '+gamenumber))  )
    console.log("jogo ref Vencedor", jogoReferenciaVencedor)

    if(jogoReferenciaVencedor.length){

        let childrenresultsVencedor:any = [];
        jogoReferenciaVencedor[0].childrenResults?.map((child,index)=> {
            childrenresultsVencedor[index] = {
                id: child.id,
                ref: child.ref,
                spAthleteTeamId: child.spAthleteTeamId,
            }
            
            if(child.ref === "Vencedor Jogo "+gamenumber){
                // jogoReferenciaVencedor[0].childrenResults![index].spAthleteTeamId = idVencedor
                childrenresultsVencedor[index].spAthleteTeamId = idVencedor;
                childrenresultsVencedor[index].spAthleteTeamId = idAthTeamVencedor;
            }
            
        })
        let toUpdate: Match = {
            id: jogoReferenciaVencedor[0].id,
            childrenResults: childrenresultsVencedor
        }

    
        //dispatch(updateMatchRequest(toUpdate))
        toUpdateMatches.push(toUpdate)
        console.log("A salvar Ref Vencedor ", toUpdate)
    } else {
        // console.log ("Nao tem ref Vencedor")
    }
    

    //Atualiza jogo do perdedor:
    let jogoReferenciaPerdedor = gameFinals.filter((child) => child.childrenResults?.some((x)=> (x.ref === 'Perdedor Jogo '+gamenumber))  )
    // console.log("jogo ref Perdedor", jogoReferenciaVencedor)
    
    if(jogoReferenciaPerdedor.length) {

        let childrenresultsPerdedor:any = [];
        jogoReferenciaPerdedor[0].childrenResults?.map((child,index)=> {
            childrenresultsPerdedor[index] = {
                id: child.id,
                ref: child.ref,
                spAthleteTeamId: child.spAthleteTeamId,
            }
            
            if(child.ref === "Perdedor Jogo "+gamenumber){
                // jogoReferenciaVencedor[0].childrenResults![index].spAthleteTeamId = idVencedor
                childrenresultsPerdedor[index].spAthleteTeamId = idPerdedor;
                childrenresultsPerdedor[index].spAthleteTeamId = idAthTeamPerdedor;
            }
           
        })
        let toUpdateLoser: Match = {
            id: jogoReferenciaPerdedor[0].id,
            childrenResults: childrenresultsPerdedor
        }
    
        //dispatch(updateMatchRequest(toUpdateLoser))
        toUpdateMatches.push(toUpdateLoser)
        console.log("A salvar Ref Perdedor", toUpdateLoser)
    } else {
        // console.log ("Nao tem ref Perdedor")
    }

    return toUpdateMatches

}

export default setupNextGame
