import { Reducer } from 'redux';
import { ClassificationState, ClassificationTypes } from './types';
const INITIAL_STATE: ClassificationState = {
    all: [],
    data: [],
    error: false,
    loading: false,
}

const reducer: Reducer<ClassificationState, any> = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //Load
        case ClassificationTypes.LOAD_ALLCLASSIFICATION_REQUEST:
            return { ...state, loading: true }
        case ClassificationTypes.LOAD_ALLCLASSIFICATION_SUCCESS:
            return { ...state, loading: false, error: false, all: action.payload.data }
        case ClassificationTypes.LOAD_ALLCLASSIFICATION_FAILURE:
            return { ...state, loading: false, error: true, all: [] }
        
        //Load single
        case ClassificationTypes.LOAD_CLASSIFICATION_REQUEST:
            return { ...state, loading: true }
        case ClassificationTypes.LOAD_CLASSIFICATION_SUCCESS:
            return { ...state, loading: false, error: false, data: action.payload.data } 
        case ClassificationTypes.LOAD_CLASSIFICATION_FAILURE:
            return { ...state, loading: false, error:true, data: [] }
        
        //Create
        case ClassificationTypes.CREATE_CLASSIFICATION_REQUEST:
            return { ...state }
        case ClassificationTypes.CREATE_CLASSIFICATION_SUCCESS:
            return { ...state, loading: false, error: false, data: [... state.data, action.payload.data]  }
        case ClassificationTypes.CREATE_CLASSIFICATION_FAILURE:
            return { ...state, loading: false, error:true, data: [] }

        //Update 
        case ClassificationTypes.UPDATE_CLASSIFICATION_REQUEST:
            return { ...state }
        case ClassificationTypes.UPDATE_CLASSIFICATION_SUCCESS:
            console.log("ACTION PAYLOAD VER", action.payload)
            return { 
                ...state,
                loading: false, 
                error: false, 
                all: state.all?.map(child=> child.id === action.payload.data.id ? action.payload.data : child ) 
            } //update data?
        case ClassificationTypes.UPDATE_CLASSIFICATION_FAILURE:
            return { ...state, loading: false, error:true }
        
        //Delete
        case ClassificationTypes.DELETE_CLASSIFICATION_REQUEST:
            return { ...state, loading: true }
        case ClassificationTypes.DELETE_CLASSIFICATION_SUCCESS:
            return { ...state, loading: false, error: false, data: action.payload.data } 
        case ClassificationTypes.DELETE_CLASSIFICATION_FAILURE:
            return { ...state, loading: false, error:true }
            
        default:
            return state;

    }
}

export default reducer;