import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    
    //Load single
    loadTeamsRequest,
    loadTeamsSuccess,
    loadTeamsFailure,
    
    //Load teams by division
    loadTeamsByDivisionRequest,
    loadTeamsByDivisionSuccess,
    loadTeamsByDivisionFailure,

    //Create
    createTeamRequest,
    createTeamSuccess,
    createTeamFailure,

    //Update
    updateTeamRequest,
    updateTeamSuccess,
    updateTeamFailure,

    //Delete
    deleteTeamRequest,
    deleteTeamSuccess,
    deleteTeamFailure,

} from './actions';
import { Team } from './types';

//Load single
export function* loadTeams(payload:ReturnType<typeof loadTeamsRequest>) {
    console.log("LOEAD TEAMS")
    try {
        const response : Team[] = yield call(api.get, 'team/'+payload.payload);
        yield put(loadTeamsSuccess(response));
    } catch (error) {
        yield put(loadTeamsFailure());
    }
}

//Load teams by division
export function* loadTeamsByDivision(payload:ReturnType<typeof loadTeamsByDivisionRequest>) {
    console.log("LOEAD TEAMS")
    try {
        const response : Team[] = yield call(api.get, 'team/bydivision/'+payload.payload);
        yield put(loadTeamsByDivisionSuccess(response));
    } catch (error) {
        yield put(loadTeamsByDivisionFailure());
    }
}

//Create
export function* createTeam(payload:ReturnType<typeof createTeamRequest>) {
    try {
        put(createTeamRequest(payload.payload.sport_id, payload.payload.data))
        const response : Team[] = yield call(api.post, 'team/'+payload.payload.sport_id, payload.payload.data);
        yield put(createTeamSuccess(response));
    } catch (error:any) {
        console.log("ERROR", error)
        yield put(createTeamFailure(error.response.message));
    }
}

//Update
export function* updateTeam(payload:ReturnType<typeof updateTeamRequest>) {
    try {
        put(updateTeamRequest(payload.payload))
        const response : Team = yield call(api.patch, 'team/'+payload.payload.id, payload.payload);
        yield put(updateTeamSuccess(response));
    } catch (error:any) {
        console.log("ERRXX",error)
        yield put(updateTeamFailure());
    }
}

//Delete
export function* deleteTeam(payload:ReturnType<typeof deleteTeamRequest>) {
    try {
        //put(deleteTeamRequest(payload.payload.delegation_id, payload.payload.sport_id))
        const response : Team = yield call(api.delete, 'team/'+payload.payload.delegation_id+'/'+payload.payload.sport_id);
        yield put(deleteTeamSuccess());
    } catch (error) {
        yield put(deleteTeamFailure());
    }
}
