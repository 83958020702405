import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap-v5'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { ApplicationState } from '../../../../../../store';

import { loadMatchesByGroupRequest, loadMatchesFinalsBySportRequest, updateMatchRequest, updateMatchResultRequest } from '../../../../../../store/ducks/spmatch/actions';

import { Match } from '../../../../../../store/ducks/spmatch/types';
import { Result } from '../../../../../../store/ducks/spmatch/types';
import { Partial } from '../../../../../../store/ducks/sppartial/types';
import _ from 'lodash';
import { updateGroupteamRequest } from '../../../../../../store/ducks/spgroupteam/actions';
import { Athlete } from '../../../../../../store/ducks/spathlete/types';
import jobGroup from './jobGroup';
import jobPlayoff from './jobPlayoff';

interface ParamTypes {
    id: string
}

interface handleCloseProps {
    handleClose: () => void
    matchSelected: Match
}

const ManageResult = ({handleClose, matchSelected}: handleCloseProps) => {
    console.log("********MatchSelected********", matchSelected)
    const {id} = useParams();
    const matches = useSelector((state: ApplicationState) => state.matches);

    const names = (athletes: Athlete[]) => {
        const commasep = athletes?.map(item => item.name).join(', ')
        return commasep
    }

    const completeWithNull = (children: Partial[]) => {
        let newPartials:any = []
        initPartials.map((iniP,index) => {
            let nset = index + 1;
            let partial = children.filter(child => +child.nset! === nset)
            
            // console.log("partial", partial)
            if(partial.length)
                newPartials.push({id: partial[0].id, pontuation: partial[0].pontuation, nset: partial[0].nset})
            else
                newPartials.push(null)
        })
        return newPartials
    }

    const setResultItems = (list: Result[]) => {
        // console.log('setConfigItems', list)
        let initialList:any= [];
        list.map(result => {
            
            let item = { 
                id: result.id!, 
                pontuation: result.pontuation!, 
                //parentTeam: result.parentTeam, 
                parentAthleteTeam: result.parentAthleteTeam,
                childrenPartials: completeWithNull(result.childrenPartials!)
            }
            initialList.push(item)
        })
        setInputList(initialList);
    }

    useEffect(() => {
        setResultItems(matchSelected.childrenResults!)
        let idChave:any;

        // Busca partidas do grupo: Ex Grupo A

        if (matchSelected.childrenResults![0].parentAthleteTeam?.childrenGroupteam?.length) {
            idChave = matchSelected.childrenResults![0].parentAthleteTeam?.childrenGroupteam![0].spGroupId!
            dispatch(loadMatchesByGroupRequest(idChave))
            //onsole.log("SOU CHAMADO??yy")
        }
        console.log("SOU CHAMADO??xx", matchSelected)
        //console.log("SOU CHAMADO??xx", matchSelected)

        let sport_id = matchSelected.parentSport?.id!
        dispatch(loadMatchesFinalsBySportRequest(sport_id))

        
    }, []);

    // const delegations = useSelector((state: ApplicationState) => state.delegations);
    // const teams = useSelector((state: ApplicationState) => state.teams);
    const [validated, setValidated] = useState(false);
    const [inputList, setInputList] = useState(
        [{
            pontuation: "",
            childrenPartials: [{ nset: "", pontuation: "" }],
        }]
    );

    const [initPartials, setInitPartials] = useState([
        { nset: "", pontuation: "" },
        { nset: "", pontuation: "" },
        { nset: "", pontuation: "" },
        { nset: "", pontuation: "" }, 
        { nset: "", pontuation: "" }
    ])

    console.log("INPUTLIST", inputList)

    // handle input change
    const handleInputChange = (e:any, index:number, partialIndex?: number) => {
        const { name, value } = e.target;
        const list:any = [...inputList];

        if(partialIndex === undefined) {
          list[index][name] = value;    
        } else {
            // console.log("PartialIndex", partialIndex)
            //Checa se Index existe:
            if (list[index]['childrenPartials'][partialIndex]) {
                list[index]['childrenPartials'][partialIndex]['pontuation'] = value;
                list[index]['childrenPartials'][partialIndex]['nset'] = "" + (partialIndex + 1);
            }
            else {
                list[index]['childrenPartials'][partialIndex] = { spResultId: list[index].id }
                list[index]['childrenPartials'][partialIndex]['pontuation'] = value;
                list[index]['childrenPartials'][partialIndex]['nset'] = "" + (partialIndex + 1); 
            }
        }
        setInputList(list);
    };
    
    // const history = useNavigate();
    const dispatch = useDispatch();
    // const component = useSelector((state: ApplicationState) => state.component);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);

        //Monta novos resultados
        // inputList.map((item:any, index: any) => {

        //     //Remover os nulls antes de enviar para a base:
        //     //let childrenPartialsNew = item.childrenPartials;
        //     // let filteredChildrenPartialsNew = childrenPartialsNew.filter(function(el:any){
        //     //     return el != null;
        //     // })

        //     // let result: Result = {
        //     //     id: +item.id,
        //     //     pontuation: item.pontuation,
        //     //     childrenPartials: filteredChildrenPartialsNew,
        //     //     // createdAt: (data.getTime()/1000).toString(), //updatedAt
        //     // }
        //     //dispatch(updateMatchResultRequest(matchSelected.id!, result))
        // })

        const matchToUpdate: Match = {
            id: matchSelected.id,
            childrenResults: inputList,
          }
          
        console.log("#######NEW MATCH RESULT#########", matchToUpdate)
        dispatch(updateMatchResultRequest(matchToUpdate))
        //console.log("#######inputList#########", inputList)

        if(matchSelected.detail === "Fase de grupos"){
            //console.log("jobGROUP",jobGroup(matchSelected, matches, inputList))
            let jobGroupArray = jobGroup(matchSelected, matches, inputList)
            jobGroupArray.map((item:any) => {
                console.log("ITEM", item)
                dispatch(updateGroupteamRequest(item))
            })
            
        }

        if(matchSelected.detail !== "Fase de grupos"){
            let jobPlayoffArray = jobPlayoff(matchSelected, matches, inputList)
            console.log("jobPlayoffArray: Para MONTAR", jobPlayoffArray)
            jobPlayoffArray.map(match => {
                dispatch(updateMatchRequest(match))
            })
        }

        handleClose();
    }


    return (
        <>
            <h2>{/* matchSelected.id */} {matchSelected.parentSport?.name}</h2>
            <br/>
            <Form validated={validated} onSubmit={handleSubmit}> 
                
                {inputList.map((x:any, i) => {
                    // console.log("XXXXXXXXXXXX", x)
                    return (
                        <div key={i}>

                            {x.parentAthleteTeam?.parentTeam?.parentDelegation?.name}
                            {' '}
                            {names(x.parentAthleteTeam?.childrenAthletes)}
                            <br/><br/>
                            {/* athleteteam.childrenAthletes! */}

                            <div className='row'>
                                <div className='col-2'>
                                    <Form.Group>
                                        <Form.Label>Geral</Form.Label>
                                        <Form.Control 
                                            placeholder='Pontuation'
                                            name="pontuation"
                                            value={x.pontuation}
                                            onChange={e => handleInputChange(e, i)}
                                        />
                                    </Form.Group>
                                </div>

                                {initPartials.map((iniP, index) => { //ignore iniP -> initialPartials vem sempre nulo la no começo
                                    //Só faz o map no initPartials pq quero 5 caixas de parciais: ver o value
                                    return (
                                        <div className='col-2' key={index}>
                                            <Form.Group>
                                                <Form.Label>Partial</Form.Label>
                                                <Form.Control 
                                                    placeholder='Pontuation'
                                                    name="pontuation"
                                                    value={x.childrenPartials[index]?.pontuation}
                                                    onChange={e => handleInputChange(e, i, index)}
                                                />
                                            </Form.Group>
                                        </div>
                                    )
                                })}
                            </div>
                            <br/><br/>
                        </div>
                    );
                })}
                {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                {/* GameNumber: {matchSelected.gamenumber} <br/>
                Fase: {matchSelected.detail} <br/>
                Penaltis: {matchSelected.parentSport?.penaltis} <br/>
                Sets: {matchSelected.parentSport?.sets} <br/> */}
                
                
                {/* <div style={{ marginTop: 20 }}>{JSON.stringify(matchSelected)}</div> */}

                <br/><br/>
                <Button size="sm" variant="primary" type="submit" className="float-right">
                    Salvar
                </Button> 
            </Form>
        </>
    );
}
export default ManageResult;
