import { Reducer } from 'redux';
import { SportState, SportsTypes } from './types';
const INITIAL_STATE: SportState = {
    all: [],
    data: [],
    error: false,
    loading: false,
}

const reducer: Reducer<SportState, any> = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //Load
        case SportsTypes.LOAD_ALLSPORTS_REQUEST:
            return { ...state, loading: true }
        case SportsTypes.LOAD_ALLSPORTS_SUCCESS:
            return { ...state, loading: false, error: false, all: action.payload.data }
        case SportsTypes.LOAD_ALLSPORTS_FAILURE:
            return { ...state, loading: false, error: true, all: [] }
        
        //Load single
        case SportsTypes.LOAD_SPORT_REQUEST:
            return { ...state, loading: true }
        case SportsTypes.LOAD_SPORT_SUCCESS:
            return { ...state, loading: false, error: false, data: action.payload.data } 
        case SportsTypes.LOAD_SPORT_FAILURE:
            return { ...state, loading: false, error:true, data: [] }
        

        //Load Sports by championship
        case SportsTypes.LOAD_SPORTS_BY_CHAMPIONSHIP_REQUEST:
            return { ...state, loading: true }
        case SportsTypes.LOAD_SPORTS_BY_CHAMPIONSHIP_SUCCESS:
            return { ...state, loading: false, error: false, data: action.payload.data }
        case SportsTypes.LOAD_SPORTS_BY_CHAMPIONSHIP_FAILURE:
            return { ...state, loading: false, error: true, data: [] }

        //Create
        case SportsTypes.CREATE_SPORT_REQUEST:
            return { ...state }
        case SportsTypes.CREATE_SPORT_SUCCESS:
            return { ...state, loading: false, error: false, data: [... state.data, action.payload.data]  }
        case SportsTypes.CREATE_SPORT_FAILURE:
            return { ...state, loading: false, error:true, data: [] }

        //Update 
        case SportsTypes.UPDATE_SPORT_REQUEST:
            return { ...state }
        case SportsTypes.UPDATE_SPORT_SUCCESS:
            console.log("ACTION PAYLOAD VER", action.payload)
            return { 
                ...state,
                loading: false, 
                error: false, 
                data: state.data?.map(child=> child.id === action.payload.data.id ? action.payload.data : child ) 
            } //update data?
        case SportsTypes.UPDATE_SPORT_FAILURE:
            return { ...state, loading: false, error:true }
        
        //Delete
        case SportsTypes.DELETE_SPORT_REQUEST:
            return { ...state, loading: true }
        case SportsTypes.DELETE_SPORT_SUCCESS:
            return { ...state, loading: false, error: false, data: state.data?.filter(item => item.id !== action.payload.data.id) } //só uma data: pq excluiu o user "Excluido com sucesso."
        case SportsTypes.DELETE_SPORT_FAILURE:
            return { ...state, loading: false, error:true }
            
        default:
            return state;

    }
}

export default reducer;