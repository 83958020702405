import { action } from 'typesafe-actions';
import { SportConfigTypes, SportConfig } from './types'

//Load single
export const loadSportConfigRequest = (id: number) => action(SportConfigTypes.LOAD_SPORTCONFIG_REQUEST, id) 
export const loadSportConfigSuccess = (data: SportConfig[]) => action(SportConfigTypes.LOAD_SPORTCONFIG_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadSportConfigFailure = () => action(SportConfigTypes.LOAD_SPORTCONFIG_FAILURE) 

//Create
export const createSportConfigRequest = (data: SportConfig) => action(SportConfigTypes.CREATE_SPORTCONFIG_REQUEST, data)
export const createSportConfigSuccess = (data: SportConfig) => action(SportConfigTypes.CREATE_SPORTCONFIG_SUCCESS, data)
export const createSportConfigFailure = (error: {}) => action(SportConfigTypes.CREATE_SPORTCONFIG_FAILURE, error)

//Update
export const updateSportConfigRequest = (data: SportConfig) => action(SportConfigTypes.UPDATE_SPORTCONFIG_REQUEST, data) 
export const updateSportConfigSuccess = (data: SportConfig) => action(SportConfigTypes.UPDATE_SPORTCONFIG_SUCCESS, data)
export const updateSportConfigFailure = () => action(SportConfigTypes.UPDATE_SPORTCONFIG_FAILURE) 

//Delete
export const deleteSportConfigRequest = (id: number) => action(SportConfigTypes.DELETE_SPORTCONFIG_REQUEST, id) 
export const deleteSportConfigSuccess = (data: SportConfig) => action(SportConfigTypes.DELETE_SPORTCONFIG_SUCCESS, data)
export const deleteSportConfigFailure = () => action(SportConfigTypes.DELETE_SPORTCONFIG_FAILURE) 
