import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { Result } from '../spmatch/types';

import { 
    
    //Load single
    loadMatchesRequest,
    loadMatchesSuccess,
    loadMatchesFailure,

    loadMatchesByGroupRequest,
    loadMatchesByGroupSuccess,
    loadMatchesByGroupFailure,

    loadMatchesFinalsBySportRequest,
    loadMatchesFinalsBySportSuccess,
    loadMatchesFinalsBySportFailure,

    //Create
    createMatchRequest,
    createMatchsuccess,
    createMatchFailure,

    //Update
    updateMatchRequest,
    updateMatchSuccess,
    updateMatchFailure,

    //UpdateMatchResult
    updateMatchResultRequest,
    updateMatchResultSuccess,
    updateMatchResultFailure,

    //Delete
    deleteMatchRequest,
    deleteMatchSuccess,
    deleteMatchFailure
    

} from './actions';
import { Match } from './types';


//Load single
export function* loadMatches(payload:ReturnType<typeof loadMatchesRequest>) {
    // console.log("LOAD MATCHES")
    try {
        const response : Match[] = yield call(api.get, 'match/sport/'+payload.payload);
        yield put(loadMatchesSuccess(response));
    } catch (error) {
        yield put(loadMatchesFailure());
    }
}

export function* loadMatchesByGroup(payload:ReturnType<typeof loadMatchesByGroupRequest>) {
    // console.log("LOAD MATCHES BY GROUP", payload)
    try {
        const response : Match[] = yield call(api.get, 'match/group/'+payload.payload);
        console.log("RESPONSE MATCH BY GROUP (PAYLOAD)", payload.payload)
        console.log("RESPONSE MATCH BY GROUP", response)
        yield put(loadMatchesByGroupSuccess(response));
    } catch (error) {
        yield put(loadMatchesByGroupFailure());
    }
}

export function* loadMatchesFinalsBySport(payload:ReturnType<typeof loadMatchesFinalsBySportRequest>) {
    // console.log("LOAD MATCHES BY GROUP", payload)
    try {
        const response : Match[] = yield call(api.get, 'match/playoffsport/'+payload.payload);
        // console.log("RESPONSE MATCH BY GROUP", response)
        yield put(loadMatchesFinalsBySportSuccess(response));
    } catch (error) {
        yield put(loadMatchesFinalsBySportFailure());
    }
}


//Create
export function* createMatch(payload:ReturnType<typeof createMatchRequest>) {
    try {
        console.log("Creating Match SAGA...", payload.payload)
        put(createMatchRequest(payload.payload))
        const response : Match = yield call(api.post, 'match', payload.payload);
        yield put(createMatchsuccess(response));
    } catch (error:any) {
        console.log("ERRX", error.response.data.message)
        yield put(createMatchFailure(error.response.data.message));
    }
}

//Update
export function* updateMatch(payload:ReturnType<typeof updateMatchRequest>) {
    try {
        put(updateMatchRequest(payload.payload))
        const response : Match = yield call(api.patch, 'match/'+payload.payload.id, payload.payload);
        console.log("**********RESPONSE VER AQUI", response)
        yield put(updateMatchSuccess(response));
    } catch (error) {
        console.log("ERRXY", error)
        yield put(updateMatchFailure());
    }
}

//Update
export function* updateMatchResult(payload:ReturnType<typeof updateMatchResultRequest>) {
    try {
        put(updateMatchResultRequest(payload.payload))
        const response : Match = yield call(api.patch, 'match/result/'+payload.payload.id, payload.payload);
        yield put(updateMatchResultSuccess(response));
    } catch (error:any) {
        console.log("ERR MATCH RESULT", error.response.data.message)
        yield put(updateMatchResultFailure());
    }
}

//Delete
export function* deleteMatch(payload:ReturnType<typeof deleteMatchRequest>) {
    try {
        put(deleteMatchRequest(payload.payload))
        const response : Match = yield call(api.delete, 'match/'+payload.payload);
        yield put(deleteMatchSuccess(response));
    } catch (error) {
        yield put(deleteMatchFailure());
    }
}
