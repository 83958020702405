import { action } from 'typesafe-actions';
import { PartialTypes, Partial } from './types'

//Load single
export const loadPartialRequest = (id: number) => action(PartialTypes.LOAD_PARTIAL_REQUEST, id) 
export const loadPartialSuccess = (data: Partial[]) => action(PartialTypes.LOAD_PARTIAL_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadPartialFailure = () => action(PartialTypes.LOAD_PARTIAL_FAILURE) 

//Create
export const createPartialRequest = (data: Partial) => action(PartialTypes.CREATE_PARTIAL_REQUEST, data)
export const createPartialSuccess = (data: Partial) => action(PartialTypes.CREATE_PARTIAL_SUCCESS, data)
export const createPartialFailure = (error: {}) => action(PartialTypes.CREATE_PARTIAL_FAILURE, error)

//Update
export const updatePartialRequest = (toUpdate: Partial) => action(PartialTypes.UPDATE_PARTIAL_REQUEST, toUpdate) 
export const updatePartialSuccess = (data: Partial) => action(PartialTypes.UPDATE_PARTIAL_SUCCESS, data)
export const updatePartialFailure = () => action(PartialTypes.UPDATE_PARTIAL_FAILURE) 

//Delete
export const deletePartialRequest = (id: number) => action(PartialTypes.DELETE_PARTIAL_REQUEST, id) 
export const deletePartialSuccess = (data: Partial) => action(PartialTypes.DELETE_PARTIAL_SUCCESS, data)
export const deletePartialFailure = () => action(PartialTypes.DELETE_PARTIAL_FAILURE) 
