import React, {FC, useEffect} from 'react'
import {PageTitle} from '../../../design/layout/core'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {ApplicationState} from '../../../../store'
import {loadComponentRequest} from '../../../../store/ducks/component/actions'
import {ComponentState} from '../../../../store/ducks/component/types'
import Loading from '../../../loading'

import {ManageItemWidget} from './ManageItemWidget'
import {ManageItemExtraWidget} from './ManageItemExtraWidget'
import MenuChampionshipWidget from './MenuChampionshipWidget'

interface ParamTypes {
  id: string
}

const MOMENT = require('moment')

type Props = {
  comp: ComponentState
  id: string
}

const ManagePage: React.FC<Props> = ({comp, id}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      <div className='col-xxl-2'>
        <MenuChampionshipWidget id={id!} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      <div className='col-xxl-10'>
        <ManageItemWidget comp={comp} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      {/* <div className='col-xxl-4'>
        <ManageItemExtraWidget comp={comp} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div> */}
    </div>
    {/* end::Row */}
  </>
)

const Championship: FC = () => {
  const intl = useIntl()

  const dispatch = useDispatch()
  const component = useSelector((state: ApplicationState) => state.component)
  let {id} = useParams()

  useEffect(() => {
    dispatch(loadComponentRequest(id!, 'asc')) //Puxa componentes com seus filhos primários
  }, [id])

  if (component.loading && component.data) return <Loading />

  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
      <PageTitle breadcrumbs={[]}>{component.data.name}</PageTitle>
      <ManagePage id={id!} comp={component} />
    </>
  )
}
export default Championship
