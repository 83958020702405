import { action } from 'typesafe-actions';
import { GrouppontuationTypes, Grouppontuation } from './types'

//Load single
export const loadGrouppontuationRequest = (id: number) => action(GrouppontuationTypes.LOAD_GROUPPONTUATION_REQUEST, id) 
export const loadGrouppontuationSuccess = (data: Grouppontuation[]) => action(GrouppontuationTypes.LOAD_GROUPPONTUATION_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadGrouppontuationFailure = () => action(GrouppontuationTypes.LOAD_GROUPPONTUATION_FAILURE) 

//Create
export const createGrouppontuationRequest = (data: Grouppontuation) => action(GrouppontuationTypes.CREATE_GROUPPONTUATION_REQUEST, data)
export const createGrouppontuationSuccess = (data: Grouppontuation) => action(GrouppontuationTypes.CREATE_GROUPPONTUATION_SUCCESS, data)
export const createGrouppontuationFailure = (error: {}) => action(GrouppontuationTypes.CREATE_GROUPPONTUATION_FAILURE, error)

//Update
export const updateGrouppontuationRequest = (toUpdate: Grouppontuation) => action(GrouppontuationTypes.UPDATE_GROUPPONTUATION_REQUEST, toUpdate) 
export const updateGrouppontuationSuccess = (data: Grouppontuation) => action(GrouppontuationTypes.UPDATE_GROUPPONTUATION_SUCCESS, data)
export const updateGrouppontuationFailure = () => action(GrouppontuationTypes.UPDATE_GROUPPONTUATION_FAILURE) 

//Delete
export const deleteGrouppontuationRequest = (id: number) => action(GrouppontuationTypes.DELETE_GROUPPONTUATION_REQUEST, id) 
export const deleteGrouppontuationSuccess = (data: Grouppontuation) => action(GrouppontuationTypes.DELETE_GROUPPONTUATION_SUCCESS, data)
export const deleteGrouppontuationFailure = () => action(GrouppontuationTypes.DELETE_GROUPPONTUATION_FAILURE) 
