import _ from 'lodash';
import { Partial } from '../../../../../../../store/ducks/sppartial/types';
import { Match, MatchState, Result } from '../../../../../../../store/ducks/spmatch/types';


const unsetupNextGame = (match:Match, matches: MatchState, inputList: Result[]) => {

    let toUpdateMatches = [];

    console.log("Desmontando proximo jogo")
    let gamenumber = match.gamenumber
    let sport_id = match.parentSport?.id
    let gameFinals =  matches.dataMatchesFinalsBySport;
    // console.log('gamenumber', gamenumber)
    // console.log('id-sport', sport_id)
    // console.log("Matches fase final", gameFinals)

    let jogoReferenciaVencedor = gameFinals.filter((child) => child.childrenResults?.some((x)=> (x.ref === 'Vencedor Jogo '+gamenumber))  )
    // console.log("jogo ref", jogoReferenciaVencedor)
    if(jogoReferenciaVencedor.length){

        let childrenresultsVencedor:any = [];
        jogoReferenciaVencedor[0].childrenResults?.map((child,index)=> {
            childrenresultsVencedor[index] = {
                id: child.id,
                ref: child.ref,
                spAthleteTeamId: child.spAthleteTeamId,
            }
            if(child.ref === "Vencedor Jogo "+gamenumber){
                // jogoReferenciaVencedor[0].childrenResults![index].spAthleteTeamId = idVencedor
                childrenresultsVencedor[index].spAthleteTeamId = null
            }
        })
        let toUpdate: Match = {
            id: jogoReferenciaVencedor[0].id,
            childrenResults: childrenresultsVencedor
        }

        toUpdateMatches.push(toUpdate)
        //dispatch(updateMatchRequest(toUpdate))
        // console.log("A salvar Ref Vencedor ", toUpdate)
    } else {
        // console.log ("Nao tem ref Vencedor")
    }

    //Atualiza jogo do perdedor:
    let jogoReferenciaPerdedor = gameFinals.filter((child) => child.childrenResults?.some((x)=> (x.ref === 'Perdedor Jogo '+gamenumber))  )
    // console.log("jogo ref Perdedor", jogoReferenciaVencedor)
    
    if(jogoReferenciaPerdedor.length) {

        let childrenresultsPerdedor:any = [];
        jogoReferenciaPerdedor[0].childrenResults?.map((child,index)=> {
            childrenresultsPerdedor[index] = {
                id: child.id,
                ref: child.ref,
                spAthleteTeamId: child.spAthleteTeamId,
            }
            if(child.ref === "Perdedor Jogo "+gamenumber){
                // jogoReferenciaVencedor[0].childrenResults![index].spAthleteTeamId = idVencedor
                childrenresultsPerdedor[index].spAthleteTeamId = null
            }
        })
        let toUpdateLoser: Match = {
            id: jogoReferenciaPerdedor[0].id,
            childrenResults: childrenresultsPerdedor
        }
        toUpdateMatches.push(toUpdateLoser)
        //dispatch(updateMatchRequest(toUpdateLoser))
        // console.log("A salvar Ref Perdedor", toUpdateLoser)
    } else {
        // console.log ("Nao tem ref Perdedor")
    }

    return toUpdateMatches;
}

export default unsetupNextGame
