import {all, takeEvery, takeLatest} from 'redux-saga/effects'

import {CartsTypes} from './carts/types'
import {loadCarts, createCart} from './carts/sagas'

import {UsersTypes} from './users/types'
import {
  loadUsers,
  // findUserId,
  searchUser,
  createUser,
  updateUser,
  deleteUser,
  selectUsersAdd,
  selectUsersRemove,
  filterUser,
} from './users/sagas'

import {MeTypes} from './me/types'
import {loginUser, createMe, updateMe, deleteMe, recoveryUser, loadMe} from './me/sagas'

import {ComponentTypes} from './component/types'
import {
  loadComponent,
  createComponent,
  updateComponent,
  deleteComponent,
  createExtra,
  updateExtra,
  deleteExtra,
  loadComponentByDescription,
  loadModules,
  loadClasses,
  createAulaConcluida,
  deleteAulaConcluida,
  loadLastClass,
  loadComponentWithAccess,
  createComponentAccess,
  updateComponentAccess,
  createLaunch,
  // uploadExtra
} from './component/sagas'

import {LeadTypes} from './lead/types'
import {loadLead, createLead, confirmLead, notDisturbLead} from './lead/sagas'

import {LeadsTypes} from './leads/types'
import {loadLeads, searchLeads} from './leads/sagas'

import {CourseTypes} from './course/types'
import {loadCourse} from './course/sagas'

import {ListsTypes} from './lists/types'
import {loadLists} from './lists/sagas'

import {EmailToListTypes} from './email/types'
import {createEmailToList, loadEmailToList} from './email/sagas'

import {AnnotationTypes} from './annotation/types'
import {loadAnnotationsSingle, createAnnotation} from './annotation/sagas'
import {AnnotationsTypes} from './annotations/types'
import {loadMyAnnotations} from './annotations/sagas'

import {SupportsTypes} from './support/types'
import {createSupport, loadAllsupports, loadSupports, updateSupport} from './support/sagas'

import {
  createWppcamp,
  deleteWppcamp,
  loadAllwppcamps,
  loadWppcamps,
  loadWppgroupavailable,
  updateWppcamp,
} from './wppcamp/sagas'
import {WppcampTypes} from './wppcamp/types'
import {WppgroupTypes} from './wppgroup/types'
import {createWppgroup, deleteWppgroup, loadWppgroups, updateWppgroup} from './wppgroup/sagas'
import { createcontact, deletecontact, loadAllcontacts, loadcontacts, updatecontact } from './contact/sagas'
import { ContactsTypes } from './contact/types'
import { StateTypes } from './state/types'
import { CityTypes } from './city/types'
import { loadState } from './state/sagas'
import { loadCity } from './city/sagas'


import { DivisionsTypes } from './spdivision/types';
import { createDivision, deleteDivision, loadAlldivisions, loadDivisions, updateDivision } from './spdivision/sagas';
import { DelegationsTypes } from './spdelegation/types';
import { createDelegation, deleteDelegation, loadAllDelegations, loadDelegations, updateDelegation, uploadImage } from './spdelegation/sagas';
import { SportsTypes } from './spsport/types';
import { createSport, deleteSport, loadAllSports, loadSports, loadSportsByChampionship, updateSport } from './spsport/sagas';
import { MatchesTypes } from './spmatch/types';
import { createMatch, deleteMatch, loadMatches, loadMatchesByGroup, loadMatchesFinalsBySport, updateMatch, updateMatchResult } from './spmatch/sagas';
import { ClassificationTypes } from './spclassification/types';
import { createClassification, deleteClassification, loadAllClassifications, loadClassifications, updateClassification } from './spclassification/sagas';
import { TeamsTypes } from './spteam/types';
import { createTeam, deleteTeam, loadTeams, loadTeamsByDivision, updateTeam } from './spteam/sagas';
import { SportConfigTypes } from './spsportconfig/types';
import { createSportConfig, deleteSportConfig, loadSportConfig, updateSportConfig } from './spsportconfig/sagas';
import { GroupsTypes } from './spgroup/types';
import { createGroup, deleteGroup, loadGroups, updateGroup } from './spgroup/sagas';
import { GroupteamTypes } from './spgroupteam/types';
import { createGroupteam, deleteGroupteam, loadGroupteams, updateGroupteam } from './spgroupteam/sagas';
// import { ResultsTypes } from './spresultOLD/types';
import { PartialTypes } from './sppartial/types';
// import { createResult, deleteResult, loadResult, updateResult } from './spresultOLD/sagas';
import { createPartial, deletePartial, loadPartial, updatePartial } from './sppartial/sagas';
import { GrouppontuationTypes } from './spgrouppontuation/types';
import { createGrouppontuation, deleteGrouppontuation, loadGrouppontuation, updateGrouppontuation } from './spgrouppontuation/sagas';
import { AthleteTeamTypes } from './spathleteteam/types';
import { AthleteTypes } from './spathlete/types';
import { createAthleteTeam, deleteAthleteTeam, loadAthleteTeamBySport, loadAthleteTeamByTeam, updateAthleteTeam } from './spathleteteam/sagas';
import { createAthlete, deleteAthlete, loadAthlete, updateAthlete } from './spathlete/sagas';


export default function* rootSaga() {
  yield all([
    takeLatest(CartsTypes.LOAD_CART_REQUEST, loadCarts),
    takeLatest(CartsTypes.CREATE_CART_REQUEST, createCart),
    //takeLatest(UsersTypes.CREATE_USER_REQUEST, createUser),

    //Me
    takeLatest(MeTypes.LOGIN_USER_REQUEST, loginUser),
    takeLatest(MeTypes.UPDATE_USER_REQUEST, updateMe),
    takeLatest(MeTypes.CREATE_USER_REQUEST, createMe),
    takeLatest(MeTypes.DELETE_USER_REQUEST, deleteMe),
    takeLatest(MeTypes.RECOVERY_USER_REQUEST, recoveryUser),
    takeLatest(MeTypes.LOAD_ME_REQUEST, loadMe),

    //Users
    takeLatest(UsersTypes.LOAD_USERS_REQUEST, loadUsers),
    takeLatest(UsersTypes.UPDATE_USER_REQUEST, updateUser),
    takeLatest(UsersTypes.CREATE_USER_REQUEST, createUser),
    takeLatest(UsersTypes.DELETE_USER_REQUEST, deleteUser),
    takeLatest(UsersTypes.SEARCH_USERS_REQUEST, searchUser),
    takeLatest(UsersTypes.FILTER_USERS_REQUEST, filterUser),
    takeLatest(UsersTypes.SELECTED_USER_ADD, selectUsersAdd),
    takeLatest(UsersTypes.SELECTED_USER_REMOVE, selectUsersRemove),
    

    //Components
    takeLatest(ComponentTypes.LOAD_COMPONENT_REQUEST, loadComponent),
    takeLatest(ComponentTypes.LOAD_COMPONENT_WITH_ACCESS_REQUEST, loadComponentWithAccess),
    takeLatest(ComponentTypes.LOAD_COMPONENT_BY_DESC_REQUEST, loadComponentByDescription),
    takeLatest(ComponentTypes.CREATE_COMPONENT_REQUEST, createComponent),
    takeLatest(ComponentTypes.CREATE_LAUNCH_REQUEST, createLaunch),
    takeLatest(ComponentTypes.CREATE_COMPONENTACCESS_REQUEST, createComponentAccess),
    takeLatest(ComponentTypes.UPDATE_COMPONENT_REQUEST, updateComponent),
    takeLatest(ComponentTypes.UPDATE_COMPONENTACCESS_REQUEST, updateComponentAccess),
    takeLatest(ComponentTypes.DELETE_COMPONENT_REQUEST, deleteComponent),
    takeLatest(ComponentTypes.LOAD_MODULES_REQUEST, loadModules),
    takeLatest(ComponentTypes.LOAD_CLASSES_REQUEST, loadClasses),
    takeLatest(ComponentTypes.LOAD_LASTCLASS_REQUEST, loadLastClass),

    //Course
    takeLatest(CourseTypes.LOAD_COURSE_REQUEST, loadCourse),

    //Extras
    takeLatest(ComponentTypes.CREATE_EXTRA_REQUEST, createExtra),
    takeLatest(ComponentTypes.UPDATE_EXTRA_REQUEST, updateExtra),
    takeLatest(ComponentTypes.DELETE_EXTRA_REQUEST, deleteExtra),
    // takeLatest(ComponentTypes.UPLOAD_EXTRA_REQUEST,uploadExtra),

    //Lead
    takeLatest(LeadTypes.LOAD_LEAD_REQUEST, loadLead),
    takeLatest(LeadTypes.CREATE_LEAD_REQUEST, createLead),
    takeLatest(LeadTypes.CONFIRM_LEAD_REQUEST, confirmLead),
    takeLatest(LeadTypes.NOTDISTURB_LEAD_REQUEST, notDisturbLead),

    //EmailToList
    takeLatest(EmailToListTypes.LOAD_EMAIL_TO_LIST_REQUEST, loadEmailToList),
    takeLatest(EmailToListTypes.CREATE_EMAIL_TO_LIST_REQUEST, createEmailToList),

    //All leads
    takeLatest(LeadsTypes.LOAD_LEAD_REQUEST, loadLeads),
    takeLatest(LeadsTypes.SEARCH_LEADS_REQUEST, searchLeads),

    //All lists
    takeLatest(ListsTypes.LOAD_LISTS_REQUEST, loadLists),

    //Annotations
    takeLatest(AnnotationTypes.LOAD_ANNOTATION_SINGLE_REQUEST, loadAnnotationsSingle),
    takeLatest(AnnotationTypes.CREATE_ANNOTATION_REQUEST, createAnnotation),

    takeLatest(AnnotationsTypes.LOAD_MY_ANNOTATIONS_REQUEST, loadMyAnnotations),

    //AulaConcluida
    takeLatest(ComponentTypes.CREATE_AULACONCLUIDA_REQUEST, createAulaConcluida),
    takeLatest(ComponentTypes.DELETE_AULACONCLUIDA_REQUEST, deleteAulaConcluida),

    //Support
    takeLatest(SupportsTypes.LOAD_ALLSUPPORT_REQUEST, loadAllsupports),
    takeLatest(SupportsTypes.LOAD_SUPPORT_REQUEST, loadSupports),
    takeLatest(SupportsTypes.CREATE_SUPPORT_REQUEST, createSupport),
    takeLatest(SupportsTypes.UPDATE_SUPPORT_REQUEST, updateSupport),

    //Wppcamp
    takeLatest(WppcampTypes.LOAD_ALLCAMP_REQUEST, loadAllwppcamps),
    takeLatest(WppcampTypes.LOAD_CAMP_REQUEST, loadWppcamps),
    takeLatest(WppcampTypes.CREATE_CAMP_REQUEST, createWppcamp),
    takeLatest(WppcampTypes.UPDATE_CAMP_REQUEST, updateWppcamp),
    takeLatest(WppcampTypes.DELETE_CAMP_REQUEST, deleteWppcamp),
    takeLatest(WppcampTypes.LOAD_WPPGROUPAVAILABLE_REQUEST, loadWppgroupavailable),

    //Wppgroup
    takeLatest(WppgroupTypes.LOAD_WPPGROUPS_REQUEST, loadWppgroups),
    takeLatest(WppgroupTypes.CREATE_WPPGROUP_REQUEST, createWppgroup),
    takeLatest(WppgroupTypes.UPDATE_WPPGROUP_REQUEST, updateWppgroup),
    takeLatest(WppgroupTypes.DELETE_WPPGROUP_REQUEST, deleteWppgroup),

    //Contact
    takeLatest(ContactsTypes.LOAD_ALLCONTACT_REQUEST, loadAllcontacts),
    takeLatest(ContactsTypes.LOAD_CONTACT_REQUEST, loadcontacts),
    takeLatest(ContactsTypes.CREATE_CONTACT_REQUEST, createcontact),
    takeLatest(ContactsTypes.UPDATE_CONTACT_REQUEST, updatecontact),
    takeLatest(ContactsTypes.DELETE_CONTACT_REQUEST, deletecontact),

    takeLatest(StateTypes.LOAD_STATES_REQUEST, loadState),
    takeLatest(CityTypes.LOAD_CITIES_REQUEST, loadCity),



     //Divisions
     takeLatest(DivisionsTypes.LOAD_ALLDIVISIONS_REQUEST, loadAlldivisions),
     takeLatest(DivisionsTypes.LOAD_DIVISION_REQUEST, loadDivisions),
     takeLatest(DivisionsTypes.CREATE_DIVISION_REQUEST, createDivision),
     takeLatest(DivisionsTypes.UPDATE_DIVISION_REQUEST, updateDivision),
     takeLatest(DivisionsTypes.DELETE_DIVISION_REQUEST, deleteDivision),

     //Delegations
     takeLatest(DelegationsTypes.LOAD_ALLDELEGATIONS_REQUEST, loadAllDelegations),
     takeLatest(DelegationsTypes.LOAD_DELEGATION_REQUEST, loadDelegations),
     takeLatest(DelegationsTypes.CREATE_DELEGATION_REQUEST, createDelegation),
     takeLatest(DelegationsTypes.UPDATE_DELEGATION_REQUEST, updateDelegation),
     takeLatest(DelegationsTypes.DELETE_DELEGATION_REQUEST, deleteDelegation),
     takeLatest(DelegationsTypes.UPLOAD_IMAGE_REQUEST, uploadImage),

     //Sports
     takeLatest(SportsTypes.LOAD_ALLSPORTS_REQUEST, loadAllSports),
     takeLatest(SportsTypes.LOAD_SPORT_REQUEST, loadSports),
     takeLatest(SportsTypes.LOAD_SPORTS_BY_CHAMPIONSHIP_REQUEST, loadSportsByChampionship),
     takeLatest(SportsTypes.CREATE_SPORT_REQUEST, createSport),
     takeLatest(SportsTypes.UPDATE_SPORT_REQUEST, updateSport),
     takeLatest(SportsTypes.DELETE_SPORT_REQUEST, deleteSport),

     //Sportconfig
     takeLatest(SportConfigTypes.LOAD_SPORTCONFIG_REQUEST, loadSportConfig),
     takeLatest(SportConfigTypes.CREATE_SPORTCONFIG_REQUEST, createSportConfig),
     takeLatest(SportConfigTypes.UPDATE_SPORTCONFIG_REQUEST, updateSportConfig),
     takeLatest(SportConfigTypes.DELETE_SPORTCONFIG_REQUEST, deleteSportConfig),

     //Group
     takeLatest(GroupsTypes.LOAD_GROUP_REQUEST, loadGroups),
     takeLatest(GroupsTypes.CREATE_GROUP_REQUEST, createGroup),
     takeLatest(GroupsTypes.UPDATE_GROUP_REQUEST, updateGroup),
     takeLatest(GroupsTypes.DELETE_GROUP_REQUEST, deleteGroup),

     //Groupteam
     takeLatest(GroupteamTypes.LOAD_GROUPTEAM_REQUEST, loadGroupteams),
     takeLatest(GroupteamTypes.CREATE_GROUPTEAM_REQUEST, createGroupteam),
     takeLatest(GroupteamTypes.UPDATE_GROUPTEAM_REQUEST, updateGroupteam),
     takeLatest(GroupteamTypes.DELETE_GROUPTEAM_REQUEST, deleteGroupteam),

     //Matches
     takeLatest(MatchesTypes.LOAD_MATCHES_REQUEST, loadMatches),
     takeLatest(MatchesTypes.LOAD_MATCHESBYGROUP_REQUEST, loadMatchesByGroup),
     takeLatest(MatchesTypes.LOAD_MATCHESFINALSBYSPORT_REQUEST, loadMatchesFinalsBySport),
     
     takeLatest(MatchesTypes.CREATE_MATCH_REQUEST, createMatch),
     
     takeEvery(MatchesTypes.UPDATE_MATCH_REQUEST, updateMatch),
     
     takeEvery(MatchesTypes.UPDATE_MATCHRESULT_REQUEST, updateMatchResult),

     takeLatest(MatchesTypes.DELETE_MATCH_REQUEST, deleteMatch),

     //Classification
     takeLatest(ClassificationTypes.LOAD_ALLCLASSIFICATION_REQUEST, loadAllClassifications),
     takeLatest(ClassificationTypes.LOAD_CLASSIFICATION_REQUEST, loadClassifications),
     takeLatest(ClassificationTypes.CREATE_CLASSIFICATION_REQUEST, createClassification),
     takeLatest(ClassificationTypes.UPDATE_CLASSIFICATION_REQUEST, updateClassification),
     takeLatest(ClassificationTypes.DELETE_CLASSIFICATION_REQUEST, deleteClassification),

     //Teams
     takeLatest(TeamsTypes.LOAD_TEAMS_REQUEST, loadTeams),
     takeLatest(TeamsTypes.LOAD_TEAMSBYDIVISION_REQUEST, loadTeamsByDivision),
     takeLatest(TeamsTypes.CREATE_TEAM_REQUEST, createTeam),
     takeLatest(TeamsTypes.UPDATE_TEAM_REQUEST, updateTeam),
     takeLatest(TeamsTypes.DELETE_TEAM_REQUEST, deleteTeam),

     // takeEvery(ResultsTypes.LOAD_RESULT_REQUEST, loadResult),
     // takeEvery(ResultsTypes.CREATE_RESULT_REQUEST, createResult),
     // takeEvery(ResultsTypes.UPDATE_RESULT_REQUEST, updateResult),
     // takeEvery(ResultsTypes.DELETE_RESULT_REQUEST, deleteResult),

     //Partials
     takeEvery(PartialTypes.LOAD_PARTIAL_REQUEST, loadPartial),
     takeEvery(PartialTypes.CREATE_PARTIAL_REQUEST, createPartial),
     takeEvery(PartialTypes.UPDATE_PARTIAL_REQUEST, updatePartial),
     takeEvery(PartialTypes.DELETE_PARTIAL_REQUEST, deletePartial),

     //Group pontuation
     takeEvery(GrouppontuationTypes.LOAD_GROUPPONTUATION_REQUEST, loadGrouppontuation),
     takeEvery(GrouppontuationTypes.CREATE_GROUPPONTUATION_REQUEST, createGrouppontuation),
     takeEvery(GrouppontuationTypes.UPDATE_GROUPPONTUATION_REQUEST, updateGrouppontuation),
     takeEvery(GrouppontuationTypes.DELETE_GROUPPONTUATION_REQUEST, deleteGrouppontuation),


     //AthleteTeam
     takeLatest(AthleteTeamTypes.LOAD_ATHLETETEAMBYSPORT_REQUEST, loadAthleteTeamBySport),
     takeLatest(AthleteTeamTypes.LOAD_ATHLETETEAMBYTEAM_REQUEST, loadAthleteTeamByTeam),
     takeLatest(AthleteTeamTypes.CREATE_ATHLETETEAM_REQUEST, createAthleteTeam),
     takeLatest(AthleteTeamTypes.UPDATE_ATHLETETEAM_REQUEST, updateAthleteTeam),
     takeLatest(AthleteTeamTypes.DELETE_ATHLETETEAM_REQUEST, deleteAthleteTeam),

     //Athlete
     takeLatest(AthleteTypes.LOAD_ATHLETE_REQUEST, loadAthlete),
     takeLatest(AthleteTypes.CREATE_ATHLETE_REQUEST, createAthlete),
     takeLatest(AthleteTypes.UPDATE_ATHLETE_REQUEST, updateAthlete),
     takeLatest(AthleteTypes.DELETE_ATHLETE_REQUEST, deleteAthlete),


    // takeLatest(ExtraTypes.LOAD_EXTRAS_REQUEST,loadExtras),
    // takeLatest(ExtraTypes.CREATE_EXTRA_REQUEST,createExtra),
    // takeLatest(ExtraTypes.UPDATE_EXTRA_REQUEST,updateExtra),
    // takeLatest(ExtraTypes.DELETE_EXTRA_REQUEST,deleteExtra),
  ])
  // console.log('mounting saga...')
}
