import { action } from 'typesafe-actions';
import { AthleteTeamTypes, AthleteTeam } from './types'

//Load single
export const loadAthleteTeamByTeamRequest = (id: number) => action(AthleteTeamTypes.LOAD_ATHLETETEAMBYTEAM_REQUEST, id) 
export const loadAthleteTeamByTeamSuccess = (data: AthleteTeam[]) => action(AthleteTeamTypes.LOAD_ATHLETETEAMBYTEAM_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadAthleteTeamByTeamFailure = () => action(AthleteTeamTypes.LOAD_ATHLETETEAMBYTEAM_FAILURE) 

export const loadAthleteTeamBySportRequest = (id: number) => action(AthleteTeamTypes.LOAD_ATHLETETEAMBYSPORT_REQUEST, id) 
export const loadAthleteTeamBySportSuccess = (data: AthleteTeam[]) => action(AthleteTeamTypes.LOAD_ATHLETETEAMBYSPORT_SUCCESS, data) //payload dps de LOAD_REQUEST
export const loadAthleteTeamBySportFailure = () => action(AthleteTeamTypes.LOAD_ATHLETETEAMBYSPORT_FAILURE) 

//Create
export const createAthleteTeamRequest = (data: AthleteTeam) => action(AthleteTeamTypes.CREATE_ATHLETETEAM_REQUEST, data)
export const createAthleteTeamSuccess = (data: AthleteTeam) => action(AthleteTeamTypes.CREATE_ATHLETETEAM_SUCCESS, data)
export const createAthleteTeamFailure = (error: {}) => action(AthleteTeamTypes.CREATE_ATHLETETEAM_FAILURE, error)

//Update
export const updateAthleteTeamRequest = (teamToUpdate: AthleteTeam) => action(AthleteTeamTypes.UPDATE_ATHLETETEAM_REQUEST, teamToUpdate) 
export const updateAthleteTeamSuccess = (data: AthleteTeam) => action(AthleteTeamTypes.UPDATE_ATHLETETEAM_SUCCESS, data)
export const updateAthleteTeamFailure = () => action(AthleteTeamTypes.UPDATE_ATHLETETEAM_FAILURE) 

//Delete
export const deleteAthleteTeamRequest = (id: number) => action(AthleteTeamTypes.DELETE_ATHLETETEAM_REQUEST, id) 
export const deleteAthleteTeamSuccess = (data: AthleteTeam) => action(AthleteTeamTypes.DELETE_ATHLETETEAM_SUCCESS, data)
export const deleteAthleteTeamFailure = () => action(AthleteTeamTypes.DELETE_ATHLETETEAM_FAILURE) 
