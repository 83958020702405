import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { 
    
    //Load single
    loadGrouppontuationRequest,
    loadGrouppontuationSuccess,
    loadGrouppontuationFailure,

    //Create
    createGrouppontuationRequest,
    createGrouppontuationSuccess,
    createGrouppontuationFailure,

    //Update
    updateGrouppontuationRequest,
    updateGrouppontuationSuccess,
    updateGrouppontuationFailure,

    //Delete
    deleteGrouppontuationRequest,
    deleteGrouppontuationSuccess,
    deleteGrouppontuationFailure

} from './actions';
import { Grouppontuation } from './types';

//Load single
export function* loadGrouppontuation(payload:ReturnType<typeof loadGrouppontuationRequest>) {
    console.log("LOEAD TEAMS")
    try {
        const response : Grouppontuation[] = yield call(api.get, 'grouppontuation/'+payload.payload);
        yield put(loadGrouppontuationSuccess(response));
    } catch (error) {
        yield put(loadGrouppontuationFailure());
    }
}

//Create
export function* createGrouppontuation(payload:ReturnType<typeof createGrouppontuationRequest>) {
    try {
        put(createGrouppontuationRequest(payload.payload))
        const response : Grouppontuation = yield call(api.post, 'grouppontuation', payload.payload);
        yield put(createGrouppontuationSuccess(response));
    } catch (error:any) {
        yield put(createGrouppontuationFailure(error.response.message));
    }
}

//Update
export function* updateGrouppontuation(payload:ReturnType<typeof updateGrouppontuationRequest>) {
    try {
        put(updateGrouppontuationRequest(payload.payload))
        const response : Grouppontuation = yield call(api.post, 'grouppontuation', payload.payload);
        yield put(updateGrouppontuationSuccess(response));
    } catch (error) {
        yield put(updateGrouppontuationFailure());
    }
}

//Delete
export function* deleteGrouppontuation(payload:ReturnType<typeof deleteGrouppontuationRequest>) {
    try {
        const response : Grouppontuation = yield call(api.delete, 'grouppontuation/'+payload.payload);
        yield put(deleteGrouppontuationSuccess(response));
    } catch (error) {
        yield put(deleteGrouppontuationFailure());
    }
}