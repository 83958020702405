/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { DivisionState } from '../../../../../store/ducks/spdivision/types'
import { SportState } from '../../../../../store/ducks/spsport/types'

interface ParamTypes {
  id: string
}

type Props = {
  className: string
  divisions: DivisionState
  sports: SportState
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
  selectedOption: string
}

const ManageAthletesWidget: React.FC<Props> = ({className, sports, divisions, setSelectedOption, selectedOption}) => {

  let { id } = useParams();

  return (
   
    <div className={`card ${className}`}>  
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Atletas em times</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Gerenciador atletas e sub-times</span>
        </h3>
      </div>

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}

        <select className="selectpicker form-control" onChange={(e)=>setSelectedOption(e.target.value)}>
          <option value="" selected disabled hidden>Selecione uma divisão</option>
          {divisions.data.map((division, index) => {
            return <option key={index} selected={(division.id === +selectedOption)?true:false} value={division.id}>{division.name}</option>
          })}
        </select>

        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='min-w-150px'>Modalidade</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {sports.data.map((child: any, index) => {
                return(
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link to={'/selectteam/' + id + "/" + child.id } style={{display:'flex' }} className='text-dark fw-bolder text-hover-primary fs-6'>
                            {child.name}
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                  )
                }
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>

  )
}

export {ManageAthletesWidget}