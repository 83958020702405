import React, {FC, useEffect, useState} from "react";

import {useIntl} from 'react-intl'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { loadComponentRequest } from '../../../../../store/ducks/component/actions';
import { ComponentState } from '../../../../../store/ducks/component/types'
import Loading from '../../../../loading'

import {
    ManageItemWidget,
} from '../ManageItemWidget'
import { ManageItemExtraWidget } from '../ManageItemExtraWidget' 
import MenuChampionshipWidget from "../MenuChampionshipWidget";
import { loadDivisionsRequest } from "../../../../../store/ducks/spdivision/actions";
import { DivisionState } from "../../../../../store/ducks/spdivision/types";
import { loadClassificationRequest } from "../../../../../store/ducks/spclassification/actions";
import { ClassificationState } from "../../../../../store/ducks/spclassification/types";
import { DelegationState } from "../../../../../store/ducks/spdelegation/types";
import { loadDelegationRequest } from "../../../../../store/ducks/spdelegation/actions";
import { ManageMedalWidget } from "./ManageMedalWidget";
import { PageTitle } from "../../../../design/layout/core";

interface ParamTypes {
  id: string
}

const MOMENT= require( 'moment' );

type Props = {
  classification: ClassificationState
  id: string

  divisions: DivisionState
  delegations: DelegationState
  selectedOption: string
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>
}

const ManagePage: React.FC<Props> = ({classification, id, divisions, delegations, selectedOption, setSelectedOption}) => (
  <>
    {/* begin::Row */}
    <div className='row g-5 gx-xxl-12'>
      
      <div className='col-xxl-2'>
        <MenuChampionshipWidget id={id!} className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      <div className='col-xxl-10'>
        <ManageMedalWidget
          classification={classification} 
          delegations={delegations} 
          divisions={divisions}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const Medal: FC = () => {
    const intl = useIntl()
    const [selectedOption, setSelectedOption] = useState('');

    const dispatch = useDispatch();
    const component = useSelector((state: ApplicationState) => state.component);
    const classification = useSelector((state: ApplicationState) => state.classification);

    const divisions = useSelector((state: ApplicationState) => state.divisions);
    const delegations = useSelector((state: ApplicationState) => state.delegations);
    
    console.log('classification', classification)
    console.log('divisions', divisions)
    console.log('delegations', delegations)
    console.log('selectedOption', selectedOption)
    let { id } = useParams();

    useEffect(() => {
        dispatch(loadComponentRequest(id!, 'asc')); //Puxa componentes com seus filhos primários
        dispatch(loadDelegationRequest(+id!))
        dispatch(loadDivisionsRequest(+id!))
        if(selectedOption)
          dispatch(loadClassificationRequest(+id!,+selectedOption)); //Puxa componentes com seus filhos primários
    }, [id, selectedOption]);

    if(component.loading && component.data)
        return <Loading/>

    if(classification.loading)
      return <Loading/>

    return ( 
      <>
        {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.MODULES'})} </PageTitle> */}
        <PageTitle breadcrumbs={[]}>{component.data.name}</PageTitle>
        <ManagePage 
          id={id!} 
          divisions={divisions}
          delegations={delegations}
          classification={classification} 
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
        />
      </> 
    )
}   
export default Medal

