import {Component} from 'react'
import {Athlete} from '../spathlete/types'
import {Team} from '../spteam/types'
import {Groupteam} from '../spgroupteam/types'

/**
 * Action types
 */
export enum AthleteTeamTypes {
  //Load single
  LOAD_ATHLETETEAMBYTEAM_REQUEST = '@athleteteam/LOAD_ATHLETETEAMBYTEAM_REQUEST',
  LOAD_ATHLETETEAMBYTEAM_SUCCESS = '@athleteteam/LOAD_ATHLETETEAMBYTEAM_SUCCESS',
  LOAD_ATHLETETEAMBYTEAM_FAILURE = '@athleteteam/LOAD_ATHLETETEAMBYTEAM_FAILURE',

  LOAD_ATHLETETEAMBYSPORT_REQUEST = '@athleteteam/LOAD_ATHLETETEAMBYSPORT_REQUEST',
  LOAD_ATHLETETEAMBYSPORT_SUCCESS = '@athleteteam/LOAD_ATHLETETEAMBYSPORT_SUCCESS',
  LOAD_ATHLETETEAMBYSPORT_FAILURE = '@athleteteam/LOAD_ATHLETETEAMBYSPORT_FAILURE',

  //Create
  CREATE_ATHLETETEAM_REQUEST = '@athleteteam/CREATE_ATHLETETEAM_REQUEST',
  CREATE_ATHLETETEAM_SUCCESS = '@athleteteam/CREATE_ATHLETETEAM_SUCCESS',
  CREATE_ATHLETETEAM_FAILURE = '@athleteteam/CREATE_ATHLETETEAM_FAILURE',

  //Update
  UPDATE_ATHLETETEAM_REQUEST = '@athleteteam/UPDATE_ATHLETETEAM_REQUEST',
  UPDATE_ATHLETETEAM_SUCCESS = '@athleteteam/UPDATE_ATHLETETEAM_SUCCESS',
  UPDATE_ATHLETETEAM_FAILURE = '@athleteteam/UPDATE_ATHLETETEAM_FAILURE',

  //Delete
  DELETE_ATHLETETEAM_REQUEST = '@athleteteam/DELETE_ATHLETETEAM_REQUEST',
  DELETE_ATHLETETEAM_SUCCESS = '@athleteteam/DELETE_ATHLETETEAM_SUCCESS',
  DELETE_ATHLETETEAM_FAILURE = '@athleteteam/DELETE_ATHLETETEAM_FAILURE',
}

/**
 * Data types
 */
export interface AthleteTeam {
  id?: number

  spTeamId?: number
  parentTeam?: Team

  name?: string
  createdAt?: string
  status?: string
  childrenAthletes?: Athlete[]
  childrenGroupteam?: Groupteam[]
  classification?: number
}

/**
 * State type
 */
export interface AthleteTeamState {
  readonly data: AthleteTeam[]
  readonly loading: boolean
  readonly error: boolean
}
