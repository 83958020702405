import React, {useEffect, useState} from 'react'
import {Form, Button} from 'react-bootstrap-v5'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import {ApplicationState} from '../../../../../../../store'
import {loadDelegationRequest} from '../../../../../../../store/ducks/spdelegation/actions'
import {Group} from '../../../../../../../store/ducks/spgroup/types'
import {
  createGroupteamRequest,
  deleteGroupteamRequest,
  loadGroupteamRequest,
} from '../../../../../../../store/ducks/spgroupteam/actions'
import {Groupteam} from '../../../../../../../store/ducks/spgroupteam/types'

import {Sport} from '../../../../../../../store/ducks/spsport/types'
import {
  createTeamRequest,
  deleteTeamRequest,
  loadTeamsRequest,
} from '../../../../../../../store/ducks/spteam/actions'
import {Team} from '../../../../../../../store/ducks/spteam/types'

interface ParamTypes {
  id: string
  sport_id: string
}

interface handleCloseProps {
  handleClose: () => void
  groupSelected: Group
}

const GroupTeam = ({handleClose, groupSelected}: handleCloseProps) => {
  const {id, sport_id} = useParams()

  useEffect(() => {
    // dispatch(loadDelegationRequest(+id)); //Puxa componentes com seus filhos primários
    dispatch(loadTeamsRequest(+sport_id!)) //Puxa componentes com seus filhos primários
    dispatch(loadGroupteamRequest(+groupSelected.id!))
    // console.log("SPORT SELECTED", sportSelected.id)
  }, [id, groupSelected])

  // const delegations = useSelector((state: ApplicationState) => state.delegations);
  const teams = useSelector((state: ApplicationState) => state.teams)
  const groupteams = useSelector((state: ApplicationState) => state.groupteams)

  const [validated, setValidated] = useState(false)
  const [selectedGroupTeam, setSelectedGroupTeam] = useState<Groupteam[]>([]) //TODO: PEGUE A LISTA DO PARENTDELEGATION...

  // const history = useNavigate();
  const dispatch = useDispatch()
  // const component = useSelector((state: ApplicationState) => state.component);

  //Reduzir a lista Selected team para ter só os Ids já selecionados.
  let selectedGroupTeamsReduce: any = []
  //let selectedAthleteTeamsReduce:any = []
  {
    groupteams.data.map((groupteam, index) => {
      selectedGroupTeamsReduce.push(groupteam.spAthleteTeamId)
    })
  }

  console.log('GroupTeams', groupteams.data)
  console.log('selectedGroupTeam:', selectedGroupTeam)
  // console.log("selectedGroupTeamsReduce", selectedGroupTeamsReduce)
  //console.log('TEAMS>DATA:',teams.data)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, groupTeam: Groupteam) => {
    const {checked} = event.target
    console.log('event', event)
    if (checked) {
      setSelectedGroupTeam([...selectedGroupTeam, groupTeam])
    } else {
      setSelectedGroupTeam(selectedGroupTeam!.filter((item) => item!.id !== groupTeam.id))
    }
  }

  const groupTeamsToInsert = (list: Groupteam[]) => {
    let groupTeamsToInsert: Team[] = []

    list.map(async (item) => {
      const team: Groupteam = {
        //classification: '',
        spGroupId: +groupSelected.id!,
        spAthleteTeamId: item!.id,
        status: '1',
      }
      // console.log("Vou inserir team", team)

      groupTeamsToInsert.push(team)
    })
    return groupTeamsToInsert
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
    }
    setValidated(true)

    let groupTeamsToInsertA = groupTeamsToInsert(selectedGroupTeam)
    console.log('selectedGroupTeam:', groupTeamsToInsertA)
    dispatch(createGroupteamRequest(groupSelected.id!, groupTeamsToInsertA))

    // //Pega todos os itens selecionados:
    // let checkboxes = document.getElementsByName("check[]");
    // let selectedCboxes = Array.prototype.slice.call(checkboxes);

    // //Para cada item da lista, vamos verificar:
    // selectedCboxes.map((item) => {

    //     //Item já estava na lista?
    //     let itemIsAlreadyInside = selectedGroupTeamsReduce.some((itemCheck:any) => itemCheck === +item.id)
    //     console.log("Item", item.id + " - " + item.checked + " " + itemIsAlreadyInside)
    //     //Se o item estiver já na lista e estiver selecionado, não faz nada!
    //     if(itemIsAlreadyInside && item.checked){
    //         console.log("Não vou fazer nada")
    //     }
    //     //Se o item estiver na lista inicial porém foi deschecado, então remove ele:
    //     if(itemIsAlreadyInside && !item.checked){
    //         // console.log("Vou excluir", item.id)
    //         console.log("Vou excluir", item.id + " " + groupSelected.id)
    //         //delegation + sport
    //         dispatch(deleteGroupteamRequest(+item.id, +groupSelected.id!))
    //     }
    //     //Se o item não estava na lista inicial e foi checado, então adiciona o item:
    //     if(!itemIsAlreadyInside && item.checked){

    //         // var data = new Date();
    //         const groupTeam: Groupteam = {
    //             spGroupId: groupSelected.id,
    //             spAthleteTeamId: parseInt(item.id),
    //             manualorder: 0,
    //             // createdAt: (data.getTime()/1000).toString(),
    //             status: "1",
    //         };
    //         console.log("Vou inserir", groupTeam)
    //         // // console.log('component to save:', team)
    //         dispatch(createGroupteamRequest(groupTeam))
    //     }
    // })
    //console.log("Itens selecionados no começo", selectedTeamsReduce)
    handleClose()
  }
  // console.log("TEAMS", teams)
  // console.log("DELEGATIONS", delegations)
  // console.log("COMPONENT", component)

  if (groupteams.loading || teams.loading) return <div>Loading</div>

  return (
    <>
      <h2>
        {groupSelected.name} - {groupSelected.parentSport?.name}
      </h2>
      {/* <h2>{groupSelected.name} - {groupSelected.parentSport?.name} - {groupSelected.id} </h2> */}
      <Form validated={validated} onSubmit={handleSubmit}>
        Selecione as equipes que participarão desse grupo:
        <br />
        <br />
        <Form.Group className='mb-3' controlId='formBasicCheckbox'>
          {teams.data.map((team, index) => {
            //Comparar com a lista dos selecionados para vir marcado por default:

            //let selectedAthleteTeams = selectedAthleteTeamsReduce.some((item:any) => item === team.id)
            // let selected = false
            // console.log("TEAM", team)

            //if (team.childrenAthleteTeams?.length! > 0) {
            return team.childrenAthleteTeams?.map((athleteteam, index) => {
              let selected = selectedGroupTeamsReduce.some((item: any) => item === athleteteam.id)
              return (
                <div key={index}>
                  <Form.Check
                    name={'check[]'}
                    id={'' + athleteteam.id}
                    defaultChecked={selected}
                    type='checkbox'
                    label={team.parentDelegation?.name + ' - ' + athleteteam.name}
                    style={{paddingBottom: 5}}
                    onChange={(e) => handleOnChange(e, athleteteam)}
                  />
                </div>
              )
            })

            //}

            // else {
            // return (
            //     <div key={index}>
            //         <Form.Check name={'check[]'} id={""+team.id} defaultChecked={selected} type="checkbox" label={team.parentDelegation?.name} style={{paddingBottom: 5}} />
            //     </div>
            //     )
            // }
          })}
        </Form.Group>
        <br />
        <br />
        <Button size='sm' variant='primary' type='submit' className='float-right'>
          Salvar
        </Button>
      </Form>
    </>
  )
}
export default GroupTeam
